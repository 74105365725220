import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Spinner, Alert } from "reactstrap";
import Cookies from "js-cookie";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import LogoLight2x from "../../images/logo2x.png";
import LogoDark2x from "../../images/logo-dark2x.png";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";

import { SetPasswordDetails } from "../../api/types/auth/authTypes";
// import { setPassword } from "../../api/auth/authService";
import { useNavigate } from "react-router-dom";
import { AnyAaaaRecord } from "dns";
import CryptoJS from "crypto-js";
import authService from "../../api/auth/authService";

const SetPassword: React.FC = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const data: any = params.get("data");
  // let { data } = useParams();
  const [jsonData, setJsonData]: any = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [errorVal, setError] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<SetPasswordDetails>();

  function decryptParams(encryptedData: any) {
    try {
      const secretKey = CryptoJS.enc.Utf8.parse("t+I11W[9*8fm");
      const iv = CryptoJS.enc.Utf8.parse("t+I11W[9*8fm"); // 16 bytes IV
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey, { iv: iv });
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      console.log("Decrypted Data:", decryptedData); // Log the decrypted string

      return JSON.parse(decryptedData);
    } catch (error) {
      console.error("Error decrypting or parsing JSON:", error);
      return null; // Handle the error appropriately in your application
    }
  }

  const handleFormSubmit = async (formdata: any) => {
    setLoading(true);
    try {
      // const data = params.get('data'); // Declare 'data' here
      let parsedData = JSON.parse(params.get("data") || "");
      console.log("parsed", parsedData);
      let formData = {
        id: parsedData.userId,
        password: formdata.password,
      };
      Cookies.set("access-token", JSON.stringify(parsedData.token), {
        expires: 7,
        secure: true,
        sameSite: "Strict",
      });
      const resData = await authService.setPassword(
        formData,
        JSON.stringify(parsedData.token)
      );
      console.log("resData", resData);
      if (resData.status) {
        setTimeout(() => {
          navigate("/success", {
            state: {
              title: "Your password has been successfully set.",
              description:
                "You can now continue using the ProcureX platform, please login",
            },
          });
        }, 1000);
        setLoading(false);
      } else {
        setError(data.message);
      }
    } catch (err: any) {
      console.log("Login failed:", err.message);
      setError(err.message);
      setLoading(false);
    }
  };

  const paramDataExtract = () => {
    const encryptedData = "7728Peyir"; // Ensure this is your actual encrypted data
    const decryptedParams = decryptParams(encryptedData);

    if (decryptedParams) {
      // Process the decrypted and parsed data
      console.log("Decrypted Params:", decryptedParams);
    } else {
      console.error("Failed to decrypt and parse params");
    }
  };

  useEffect(() => {
    console.log("data111", data);
    // paramDataExtract()
  }, []);

  return (
    <>
      <Head title="Login" />
      <Block className="nk-block-middle nk-auth-body wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="" src={LogoDark2x} alt="logo" />
            {/* <img className="" src={LogoDark} alt="logo-dark" /> */}
          </Link>
        </div>

        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Welcome</BlockTitle>
              <BlockDes>
                <p>Set Your Password to Access Procurex.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          {errorVal && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                <Icon name="alert-circle" /> Unable to login with credentials
              </Alert>
            </div>
          )}
          <Form className="is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPasswordVisible(!passwordVisible);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${
                    passwordVisible ? "is-hidden" : "is-shown"
                  }`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>
                  <Icon
                    name="eye-off"
                    className="passcode-icon icon-hide"
                  ></Icon>
                </a>
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  {...register("password", {
                    required: "This field is required",
                    pattern: {
                      value: /^(?=.*[A-Z])(?=.*[@$#^_-])[A-Za-z\d@$#^_-]{8,}$/,
                      message: "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character. Only the following special characters are allowed: @$!%*?&#^_-",
                    },
                  })}
                  placeholder="Enter your password"
                  className="form-control-lg form-control"
                />
                {errors.password && (
                  <span className="invalid">{errors.password.message}</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="confirmpassword">
                  Confirm Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="confirmpassword"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setConfirmPasswordVisible(!confirmPasswordVisible);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${
                    confirmPasswordVisible ? "is-hidden" : "is-shown"
                  }`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>
                  <Icon
                    name="eye-off"
                    className="passcode-icon icon-hide"
                  ></Icon>
                </a>
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  id="confirmpassword"
                  {...register("confirmpassword", {
                    required: "This field is required",
                    validate: (value) =>
                      value === getValues("password") || "Passwords must match",
                  })}
                  placeholder="Confirm your password"
                  className="form-control-lg form-control"
                />
                {errors.confirmpassword && (
                  <span className="invalid">
                    {errors.confirmpassword.message}
                  </span>
                )}
              </div>
            </div>
            <div className="form-group">
              <Button
                size="lg"
                className="btn-block"
                type="submit"
                color="primary"
              >
                {loading ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
            </div>
          </Form>
        </PreviewCard>
      </Block>
      <AuthFooter />
    </>
  );
};

export default SetPassword;
