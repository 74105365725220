import React, { useEffect, FC } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Col,
  RSelect,
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
} from "../../../components/Component";
import { Steps, Step } from "react-step-builder";
import { useForm, FormProvider } from "react-hook-form";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import GeneralInformation from "./GeneralInformation";
import SiteStatus from "./SiteStatus";
import BlocksInformation from "./BlocksInformation";

const config = {
  before: Header as React.ComponentType<{}> as () => React.ReactElement,
};

const AddSites: React.FC = (props: any) => {
  const navigate = useNavigate();
  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      district: "",
      plannedStartDate: null,
      plannedEndDate: null,
      currentStatus: "",
      Blocks: [
        {
          blockName: "",
          squareFeet: "",
          startDate: null,
          endDate: null,
          currentStatus: "",
        },
      ],
    },
  });

  useEffect(() => {}, []);

  const backList = () => {
    navigate(`/sites`);
  };

  return (
    <React.Fragment>
      <Head title="Regular Page v-1" />
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Sites</BlockTitle>
              <BlockDes>
                Onboard Sites - Collecting essential information to create Sites
                profiles.
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="primary"
                className="btn-dim"
                onClick={() => backList()}
              >
                <Icon name="menu-alt-r"></Icon>
                <span>List of Sites</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"cardBox"} bodyClass="card-inner-xl">
            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
              <FormProvider {...formMethods}>
                <Steps config={config}>
                  <Step component={GeneralInformation} />
                  <Step component={SiteStatus} />
                  <Step component={BlocksInformation} />
                </Steps>
              </FormProvider>
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default AddSites;
