import React, { useEffect, useState, useCallback } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import { Icon, Col, Row, Button, RSelect } from "../../../components/Component";
const ConsultantInformation: React.FC = (props: any) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const submitForm = (data: any) => {
    props.next();
  };

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row className="gy-4">
        <div className="col-md-12" style={{ marginBottom: 15 }}>
          <h6>Consultant Information</h6>
        </div>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="companyName"
            label="Company Name"
            required={true}
            type="text"
            maxLength={50}
            placeholder="Enter Company Name"
            // disabled={action === "view"}
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="contactPerson"
            // disabled={action === "view"}
            label="Contact Person"
            required={true}
            type="text"
            maxLength={50}
            placeholder="Enter Contact Person"
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="emailAddress"
            // disabled={action === "view"}
            required={true}
            label="Email Address"
            type="email"
            placeholder="Enter Email Address"
            maxLength={30}
            pattern={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,24}$/}
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="phoneNumber"
            label="Phone Number"
            // disabled={action === "view"}
            required={true}
            type="text"
            pattern={/^\d+$/}
            placeholder="Enter Phone Number"
            // pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="mailingAddress"
            label="Mailing Address"
            // disabled={action === "view"}
            required={true}
            type="text"
            placeholder="Enter Mailing Address"
          />
        </Col>
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <h6>Consultant Profile</h6>
        </div>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="businessDescription"
            // disabled={action === "view"}
            label="Business Description"
            type="text"
            placeholder="Enter Business Description"
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="yearsInBusiness"
            // disabled={action === "view"}
            label="Years in Business"
            type="text"
            placeholder="Enter Years in Business"
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="otherReferences"
            label="References"
            type="text"
            // disabled={action === "view"}
            placeholder="Enter References"
          />
        </Col>
      </Row>
      <Row className="gy-4 mt-1">
        <Col
          sm={undefined}
          lg={undefined}
          md={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <Button color="primary" type="submit">
            Next
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default React.memo(ConsultantInformation);
