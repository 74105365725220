import React, { useCallback, useEffect, useState } from "react";
import reportApis from "../../../api/reports";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Button, Card } from "reactstrap";

import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import {
  DatePicker,
  Empty,
  Flex,
  Select,
  Spin,
  Table,
  TableColumnsType,
} from "antd";
import FormSelect from "../../form-components/SelectComponent";
import ReportTemplate2 from "../reportTemplate2";
const { RangePicker } = DatePicker;

function InventoryAgingReport() {
  const navigate = useNavigate();

  const columns: any = [
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      fixed: "left",
      width: 200,
    },

    {
      title: "UOM",
      dataIndex: "uomName",
      key: "uomName",
      width: 200
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Inventory Aging Report"
      moduleKey="InventoryAgingReport"
      columns={columns}
      filtersOption={{
        site: true,
        date: true,
        aging: true,
        material: true,
      }}
      previousPath="/view-inventory-reports"
      extra={{
        tableScrollX: 1700,
      }}
    />
  );
}

export default InventoryAgingReport;
