import React, { FC, useState, useEffect } from "react";
import { Col, Row, Card, Button } from "reactstrap";
import {
  Table,
  Input,
  Popover,
  Space,
  Tooltip,
  message,
  Checkbox,
  Form,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  TwitchOutlined,
  EditOutlined,
  BarChartOutlined,
  MessageOutlined,
  TrophyOutlined,
  SaveOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import StatusIndicatorDrawer from "./QuoteComparison/StatusIndicatorDrawer";
import ScenarioBuilderDrawer from "./QuoteComparison/ScenarioBuilderDrawer";
import AllocationDrawer from "./QuoteComparison/AllocationDrawer";
import ShortlistedSuppliersDrawer from "./QuoteComparison/ShortlistedSuppliersDrawer";
import quotationApis from "../../../api/master/quotations";
import SupplierDetailsDrawer from "./QuoteComparison/SupplierDetailsDrawer";
import Swal from "sweetalert2";
import AddSupplierQuoteDrawer from "./QuoteComparison/AddSupplierQuoteDrawer";
import dayjs from "dayjs";
import { json } from "d3";
import { on } from "process";

//import { QuotationComparisonProps, SupplierQuotation} from './QuotationTypes'

interface QuotationComparisonProps {
  rfq: any;
  initialMaterialsData: any;
  supplierQuotations: any;
  countsData: any;
  quotationHeader: any;
  onUpdate: () => void; // Accept the onUpdate callback
}

type Material = {
  key: string;
  materialName: string;
  materialID: string;
  qty: number;
  uomName: string;
  awardedQty: any;
  deliveryDate: any;
  remainingQty: any;
  uom: string; // Added UOM (Unit of Measure) field
  targetPrice: number;
  quotationLineId: number;
};

type SupplierQuotation = {
  supplierID: string;
  supplierName: string;
  currency: string;
  quotation: {
    quotationLineId: number;
    materialID: string;
    unitPrice: number;
    supplyQty: number;
    minOrderQty: number;
    cgst?: number; // Central GST rate (optional)
    sgst?: number; // State GST rate (optional)
    igst?: number; // Integrated GST rate (optional)
    cgstAmount?: number; // Calculated CGST amount
    sgstAmount?: number; // Calculated SGST amount
    igstAmount?: number; // Calculated IGST amount
    taxes?: number; // Total taxes (CGST + SGST or IGST)
    initialQuotation?: number; // Initial Quotation Price (optional)
    finalQuotation?: number; // Final Quotation Price (optional)
    needByDate: string; // New field for need by date
    eta: string; // New field for ETA
  }[];
  subTotal: number;
  totalTaxes: number;
  paymentTerms: string;
  leadTime: string;
  grandTotal: number;
  score?: {
    // Optional score object
    materialScore: number;
    quotationScore: number;
    onTimeDeliveryScore: number;
    volumeScore: number;
    creditScore: number;
    score: number;
    rank: number;
  };
};
interface ShortlistedSupplier {
  supplierID: string;
  supplierName: string;
  materialID: string;
  materialName: string;
  askedQty: number;
  awardedQty: number;
  awardeQtyPercentage: string;
  initialQuotation: string | number;
  bestQuotation: string | number;
  taxes: number;
  cgst: number;
  sgst: number;
  igst: number;
  lineTotal: number;
}

//Allocation / Award Data
type SubmittedDataItem = {
  quotationLineId: number;
  materialID: string;
  materialName: string;
  supplierID: string;
  awardQty: string;
  reason: string;
};
const QuotationComparison: FC<QuotationComparisonProps> = ({
  rfq,
  initialMaterialsData,
  supplierQuotations,
  quotationHeader,
  countsData,
  onUpdate,
}) => {
  const [countValues, setCountsData] = useState(countsData?.shortListCount);
  // const [initialMaterials, setInitialMaterials] = useState<Material[]>([initialMaterialsData]);
  const [materials, setMaterials] = useState<Material[]>(initialMaterialsData);
  const [inputValues, setInputValues] = useState<Record<string, string>>(
    initialMaterialsData.reduce(
      (acc: any, material: any) => ({
        ...acc,
        [material.key]: String(material.targetPrice),
      }),
      {}
    )
  );
  const [statusIndicatorDrawerVisible, setStatusIndicatorDrawerVisible] =
    useState(false);
  const [statusIndicatorRanges, setStatusIndicatorRanges] = useState<any>({});

  const [selectedMaterials, setSelectedMaterials] = useState<string[]>([]); // State to track selected materials
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false); // State for Select All checkbox
  const [allocationDrawerVisible, setAllocationDrawerVisible] = useState(false);
  const [submittedData, setSubmittedData]: any = useState<SubmittedDataItem[]>(
    []
  ); // This for Awared data from AllocationDrawer
  const [supplierDetailsDrawerVisible, setSupplierDetailsDrawerVisible] =
    useState(false);

  const [supplierDetailsData, setSupplierDetailsData] = useState<any>({});
  // const [supplierQuotations, setSupplierQuotations]: any = useState<SupplierQuotation[]>([]);

  const [showSupplierQuotationsDrawer, setShowSupplierQuotationsDrawer] =
    useState(false);

  const [form] = Form.useForm();
  const getColor = (unitPrice: number, targetPrice: number) => {
    if (targetPrice === 0) {
      return "#fff"; // White for undefined target price
    }

    const difference = unitPrice - targetPrice;
    const percentageDifference = (difference / targetPrice) * 100;

    if (
      percentageDifference > statusIndicatorRanges?.slightlyHighRange?.from &&
      percentageDifference <= statusIndicatorRanges?.slightlyHighRange?.to
    ) {
      return "#f2d1ae"; // Orange - Slightly High
    } else if (
      percentageDifference > statusIndicatorRanges?.highRange?.from &&
      percentageDifference <= statusIndicatorRanges?.highRange?.to
    ) {
      return "#f4e065"; // Yellow - High
    } else if (
      percentageDifference > statusIndicatorRanges?.veryHighRange?.from &&
      percentageDifference <= statusIndicatorRanges?.veryHighRange?.to
    ) {
      return "#faad14"; // Very High
    } else if (
      percentageDifference >= statusIndicatorRanges?.outlierRange?.from
    ) {
      return "#ee545b"; // Red - Outlier
    } else {
      return "#fff"; // Default Green for very high
    }
  };

  const updateTargetPrice = (key: string, value: string) => {
    console.log("dddd", key, value);
    setInputValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleBlur = (key: string) => {
    const value = inputValues[key] == "" ? "0" : inputValues[key];
    setMaterials((prevMaterials) =>
      prevMaterials.map((material) =>
        material.key == key
          ? { ...material, targetPrice: Number(value) }
          : material
      )
    );
  };

  const generateColumns = (
    updateTargetPrice: (key: string, value: string) => void,
    inputValues: Record<string, string>,
    handleBlur: (key: string) => void
  ): ColumnsType<any> => {
    const columns: ColumnsType<any> = [
      {
        title: (
          <Checkbox
            checked={isAllSelected}
            onChange={(e) => handleSelectAll(e.target.checked)}
          />
        ), // Select All
        dataIndex: "selection",
        key: "selection",
        fixed: "left",
        width: 50,
        render: (text: any, record: any) => {
          console.log(
            "Rendering checkbox for record:",
            record,
            record.key,
            "Selected materials:",
            selectedMaterials
          );
          const isDisabled = record.remainingQty === 0;
          return (
            <Tooltip title={isDisabled ? "Remaining quantity is 0" : ""}>
              <Checkbox
                checked={selectedMaterials.includes(record.key)}
                disabled={isDisabled}
                onChange={(e) =>
                  handleMaterialSelection(record.key, e.target.checked)
                }
              />
            </Tooltip>
          );
        },
      },
      {
        title: "S.No",
        dataIndex: "key",
        key: "key",
        fixed: "left",
        width: 50,
      },
      {
        title: "Material Name",
        dataIndex: "materialName",
        key: "materialName",
        fixed: "left",
        width: 250,
      },
      {
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
        fixed: "left",
        width: 110,
        render: (text: number, record: any) => (
          <>
            <span>
              {text} {record.uomName}
            </span>
            <Popover
              content={
                <div>
                  <p>Asked Quantity: {record.qty}</p>
                  <p>Reserved Quantity: {record.awardedQty}</p>
                  <p>Remaining Quantity: {record.remainingQty}</p>
                </div>
              }
              title="Tax Breakdown"
            >
              <InfoCircleOutlined style={{ marginLeft: 8 }} />
            </Popover>
          </>
        ),
      },
      {
        title: "Need By Date",
        dataIndex: "deliveryDate",
        key: "deliveryDate",
        fixed: "left",
        width: 100,
        render: (text: number, record: any) => (
          <>
            <span>{dayjs(text).format("DD-MMM-YYYY")}</span>
          </>
        ),
      },
      {
        title: "Target Price (TP)",
        dataIndex: "targetPrice",
        key: "targetPrice",
        fixed: "left",
        width: 120,
        render: (text: number, record: any) => (
          console.log("record.key", inputValues[record.key]),
          (
            <Input
              value={inputValues[record.key] || ""}
              onChange={(e) => updateTargetPrice(record.key, e.target.value)}
              onBlur={() => handleBlur(record.key)}
            />
          )
        ),
      },
    ];
    const getSupplierInitials = (name: string) => {
      const words = name.split(" ");
      if (words.length > 1) {
        return `${words[0][0]}${words[1][0]}`.toUpperCase();
      }
      return name.substring(0, 2).toUpperCase();
    };

    const colors = [
      "#173F5F",
      "#20639B",
      "#3CAEA3",
      "#F6D55C",
      "#ED553B",
      "#e81e63",
      "#03a9f4",
      "#cddc39",
      "#9c27b0",
      "#ffeb3b",
    ];

    supplierQuotations.forEach((supplier: any, index: any) => {
      const colorIndex = index % colors.length;
      columns.push({
        title: (
          <div className="supplier-name-wrapper">
            <div className="">
              <div
                onClick={() =>
                  handleSupplierClick(
                    supplier.supplierID,
                    supplier.supplierName
                  )
                }
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Popover content={supplier.supplierName} placement="top">
                  <div
                    className="supplier-icon"
                    style={{ backgroundColor: colors[1], marginRight: 10 }}
                  >
                    {getSupplierInitials(supplier.supplierName)}
                  </div>
                </Popover>
                {supplier.score && supplier.score.rank ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "green",
                      marginLeft: 10,
                    }}
                  >
                    <TrophyOutlined style={{ marginRight: 5 }} />
                    <span>{supplier.score.rank}</span>
                  </div>
                ) : null}
              </div>
            </div>
            {/* <div>Price</div> */}
          </div>
        ),
        key: supplier.supplierID,
        dataIndex: `totalPrice_${supplier.supplierID}`,
        width: 150,
        render: (value: any, record: any) => {
          // console.log("value", record)
          const { unitPrice, totalPrice, supplyQty } = value || {
            unitPrice: 0,
            totalPrice: 0,
            supplyQty: 0,
          };
          return (
            <div style={{ padding: 4, margin: 0, textAlign: "right" }}>
              <div>Supply Qty: {supplyQty}</div>
              <div>
                Unit Price:{" "}
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 0,
                }).format(unitPrice)}
              </div>
              <div
                style={{
                  backgroundColor: getColor(unitPrice, record.targetPrice),
                  padding: 4,
                  margin: 0,
                }}
              >
                Total Price:{" "}
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 0,
                }).format(totalPrice)}
              </div>
            </div>
          );
        },
      });
    });
    return columns;
  };

  const handleSupplierClick = async (
    supplierID: string,
    supplierName: string
  ) => {
    try {
      const { response, status } =
        await quotationApis.latestBySupplierQuotationId(
          rfq.quotationId,
          supplierID
        );
      if (status === true) {
        setSupplierDetailsData({ ...response.data, supplierName });
        setSupplierDetailsDrawerVisible(true);
      } else {
        console.log("response");
      }
    } catch {
      console.log("error");
    }
  };

  const generateDataSource = (materials: Material[]): Record<any, any>[] => {
    return materials.map((material) => {
      const data: Record<string, any> = {
        key: material.key,
        materialName: material.materialName,
        qty: material.qty,
        uom: material.uom,
        uomName: material.uomName,
        deliveryDate: material.deliveryDate,
        awardedQty: material.awardedQty,
        remainingQty: material.remainingQty,
        targetPrice: material.targetPrice,
      };
      supplierQuotations.forEach((supplier: any) => {
        const quotation = supplier.quotation.find(
          (q: any) => q.quotationLineId === material.quotationLineId
        );
        if (quotation) {
          data[`totalPrice_${supplier.supplierID}`] = {
            quotationLineId: material.quotationLineId,
            supplyQty: quotation.supplyQty,
            materialID: material.materialID,
            unitPrice: quotation.unitPrice,
            totalPrice: quotation.unitPrice * quotation.supplyQty,
          };
        }
      });
      return data;
    });
  };

  const columns: any = generateColumns(
    updateTargetPrice,
    inputValues,
    handleBlur
  );
  const dataSource = generateDataSource(materials);

  const showStatusIndicatorDrawer = () => {
    setStatusIndicatorDrawerVisible(true);
  };

  const closeStatusIndicatorDrawer = () => {
    setStatusIndicatorDrawerVisible(false);
  };

  const closeSupplierDrawer = () => {
    setSupplierDetailsDrawerVisible(false);
  };

  const handleStatusIndicatorSubmit = (values: any) => {
    console.log("Submitted Values: ", values);
    setStatusIndicatorRanges(values);
    message.success("Status indicators have been set successfully.");
  };
  // State to control the ScenarioBuilderDrawer visibility
  const [scenarioBuilderVisible, setScenarioBuilderVisible] = useState(false);
  const [supplierQuoteVisible, setSupplierQuoteVisible] = useState(false);
  // Handler to open the ScenarioBuilderDrawer
  const openScenarioBuilder = (e: any) => {
    e.preventDefault();
    getScenarioBuilder();
    setScenarioBuilderVisible(true);
  };

  // Handler to close the ScenarioBuilderDrawer
  const closeScenarioBuilder = () => {
    setScenarioBuilderVisible(false);
  };

  const closeSupplierQuoteDrawer = () => {
    setSupplierQuoteVisible(false);
  };

  // Handler to handle the form submission from ScenarioBuilderDrawer
  const handleScenarioSubmit = (values: any) => {
    console.log("Scenario Values: ", values, rfq.quotationId);
    const formatData = {
      quotationId: rfq.quotationId,
      materialCoverage: values.materialCoverage,
      volumeCoverage: values.volumeCoverage,
      creditCoverage: values.longCreditDays,
      quotationValueCoverage: values.quotationValue,
      onTimeDeliveryCoverage: values.onTimeDelivery,
      delayPercentage: values.delayPercentage,
      credit30Days: values.credit30Days,
    };
    console.log("formatData", formatData);
    addScenarioBuilder(formatData);
  };

  const handleSupplierQuoteSubmit = (values: any) => {
    console.log("vlaue", values);
    onUpdate();
    closeSupplierQuoteDrawer();
  };

  const addScenarioBuilder = async (data: any) => {
    try {
      const { response, status } = await quotationApis.addScenarioBuilder(data);
      if (status) {
        message.success("Scenario has been added successfully.");
        onUpdate();
        closeScenarioBuilder();
      }
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleMaterialSelection = (key: string, isSelected: boolean) => {
    console.log("Selected material key:", key, "isSelected:", isSelected);
    if (isSelected) {
      setSelectedMaterials((prev) => {
        console.log("Prev selected materials:", prev);
        return [...prev, key];
      });
    } else {
      setSelectedMaterials((prev) => {
        console.log("Prev selected materials:", prev);
        return prev.filter((item) => item !== key);
      });
    }
  };

  const handleSelectAll = (isSelected: boolean) => {
    console.log("Select all isSelected:", isSelected);
    if (isSelected) {
      // Only select materials where remainingQty is not 0
      const selectableMaterials = materials
        .filter((material) => material.remainingQty !== 0) // Exclude materials with remainingQty === 0
        .map((material) => material.key);
      setSelectedMaterials(selectableMaterials);
    } else {
      setSelectedMaterials([]); // Clear selection if not selected
    }
    setIsAllSelected(isSelected);
  };

  const openAllocationDrawer = (e: any) => {
    e.preventDefault();
    if (selectedMaterials.length > 0) {
      setAllocationDrawerVisible(true);
    } else {
      message.warning("Please select at least one material to allocate.");
    }
  };

  const closeAllocationDrawer = () => {
    setAllocationDrawerVisible(false);
    // updat
  };
  const [allocationStatusColor, setAllocationStatusColor] = useState("red");
  const [allocationStatus, setAllocationStatus] = useState("Not Awarded");

  const handleAllocationSubmit = (values: SubmittedDataItem[]) => {
    setSubmittedData((prev: any) => {
      const updatedData = [...prev, ...values];
      console.log("updatedData ", JSON.stringify(updatedData));
      const totalRequired = materials.reduce(
        (acc, material) => acc + material.qty,
        0
      );
      const totalAwarded = materials.reduce((acc, material) => {
        // Filter the updatedData for the current material
        const total = updatedData
          .filter((item) => item.materialID === material.materialID)
          .reduce((sum, item) => sum + parseFloat(item.awardQty), 0);

        // Add the total awarded quantity for this material to the accumulator
        return acc + total;
      }, 0);

      console.log("totalAwarded ", totalAwarded);
      console.log("materials.length ", totalRequired);

      const allocationStatus =
        totalAwarded === 0
          ? "Not Awarded"
          : totalAwarded === totalRequired
          ? "Fully Awarded"
          : "Partially Awarded";

      const allocationStatusColor =
        allocationStatus === "Fully Awarded"
          ? "green"
          : allocationStatus === "Partially Awarded"
          ? "orange"
          : "red";
      setAllocationStatus(allocationStatus);
      setAllocationStatusColor(allocationStatusColor);

      return updatedData;
    });
    setSelectedMaterials([]);
    form.resetFields();

    // message.success("Successfully awarded!");
    createAllocationSubmit(values);
  };

  const createAllocationSubmit = async (values: any) => {
    console.log("values", values);
    try {
      const { response, status } = await quotationApis.shortListSuppliers(
        rfq.quotationId,
        { list: values }
      );
      if (status) {
        setAllocationDrawerVisible(false);
        message.success("Successfully Allocated!");
        onUpdate();
      } else {
        Swal.fire("Failed!", response.message, "error");
      }
    } catch (error: any) {
      console.log("error", error);
      message.error(error.message);
    }
  };

  useEffect(() => {
    // Update local state with the new props when they change
    setCountsData(countsData?.shortListCount);
    setMaterials(initialMaterialsData);
    setInputValues(
      initialMaterialsData.reduce(
        (acc: any, material: any) => ({
          ...acc,
          [material.key]: String(material.targetPrice),
        }),
        {}
      )
    );
  }, [initialMaterialsData, countsData]);

  useEffect(() => {
    console.log("Updated supplier quotations: ", supplierQuotations);
  }, [supplierQuotations]);

  useEffect(() => {
    console.log("submittedData updated:", submittedData);
  }, [submittedData, allocationStatus, allocationStatusColor]);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [shortlistedData, setShortlistedData] = useState([]);

  const showDrawer = async () => {
    if (countsData?.shortListCount === 0) {
      message.warning("There are no shortlisted suppliers.");
      return;
    }
    setDrawerVisible(true);
    // alert("Hi");
    // const { response, status } = await quotationApis.getShortListed(rfq.quotationId);
    // if (status) {
    //   console.log("log:::LIST", response)
    //   // console.log("map list ", submittedData, supplierQuotations)
    //   const data: ShortlistedSupplier[] = response.data.suppliers;
    //   console.log(data);
    //   setShortlistedData(data as any);
    //   setDrawerVisible(true);
    // }
  };

  const [scenarioBuilderData, setScenarioBuilderData] = useState([]);

  const [supplierQuoteData, setSupplierQuoteData]: any = useState<any>();

  const getScenarioBuilder = async () => {
    try {
      const { response, status } = await quotationApis.getScenarioBuilder(
        rfq.quotationId
      );
      if (status) {
        setScenarioBuilderData(response.data);
        console.log("setScenarioBuilderData", response.data);
      }
    } catch (error) {}
  };

  const saveTargetPriceValue = async () => {
    const targets = materials.map((key) => {
      return {
        quotationLineId: key.quotationLineId,
        targetPrice: key.targetPrice,
      };
    });

    console.log("target", targets);
    if (targets.length !== 0) {
      saveAPICall(targets);
    } else if (targets.length === 0) {
      message.warning("Target Not Entred.");
    }
  };

  const openSupplierQuoteDrawer = () => {
    const data: any = {
      materials: materials,
      quotationHeader: quotationHeader,
    };
    setSupplierQuoteData(data);
    setSupplierQuoteVisible(true);
  };

  const saveAPICall = async (targets: any) => {
    console.log("ddd");
    try {
      const { response, status } = await quotationApis.addTargetPrice({
        targets: targets,
      });
      if (status) {
        Swal.fire("Saved", "Target Price is Set", "success");
        onUpdate();
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    onUpdate();
  };
  return (
    <div className="table-container">
      <Row>
        <Col lg="12">
          <Card
            className="card-bordered"
            style={{ minHeight: "500px", overflowY: "auto" }}
          >
            <div className="card-inner">
              <div className="status-indicator-wrapper">
                <Space>
                  <div className="scenarioBuilderButton">
                    <a
                      href="#"
                      className="btn btn-dim btn-outline-light"
                      onClick={openScenarioBuilder}
                    >
                      <BarChartOutlined /> &nbsp; Scenario Builder
                    </a>
                  </div>
                </Space>
                <Space>
                  <div className="status-indicator slightly-high">
                    <span
                      className="color-box"
                      style={{ backgroundColor: "#f2d1ae" }}
                    ></span>
                    Slightly High
                  </div>
                  <div className="status-indicator high">
                    <span
                      className="color-box"
                      style={{ backgroundColor: "#f4e065" }}
                    ></span>
                    High
                  </div>
                  <div className="status-indicator very-high">
                    <span
                      className="color-box"
                      style={{ backgroundColor: "#faad14" }}
                    ></span>
                    Very High
                  </div>
                  <div className="status-indicator outlier">
                    <span
                      className="color-box"
                      style={{ backgroundColor: "#ee545b" }}
                    ></span>
                    Outlier
                  </div>
                  <Tooltip title="Edit">
                    <EditOutlined
                      className="edit-icon"
                      onClick={showStatusIndicatorDrawer}
                    />
                  </Tooltip>
                </Space>
                <div>
                  <a onClick={saveTargetPriceValue}>
                    <span>
                      <Tooltip title="Save Target Price">
                        <div style={{ marginLeft: "10px", marginTop: "-2px" }}>
                          <a>Save Target Price </a>
                        </div>
                      </Tooltip>
                    </span>
                  </a>
                </div>
                <div>
                  <a onClick={openSupplierQuoteDrawer}>
                    {/* Save{" "} */}
                    <span>
                      <Tooltip title="Supplier Quote">
                        <div style={{ marginLeft: "10px", marginTop: "-2px" }}>
                          Set Supplier Quote{" "}
                        </div>
                      </Tooltip>
                    </span>
                  </a>
                </div>
                <div>
                  <a onClick={openAllocationDrawer}>
                    Allocate / Shortlist{" "}
                    {/* <span style={{ color: allocationStatusColor }}>
                      {allocationStatus}
                    </span> */}
                  </a>
                </div>
                <div>
                  <Space>
                    <Tooltip title="Click to view shortlisted suppliers">
                      <a onClick={showDrawer}>
                        Shortlisted Suppliers: {countValues}
                        {/* {
                          new Set(submittedData.map((item: any) => item.supplierID))
                            .size
                        } */}
                      </a>
                    </Tooltip>
                  </Space>
                </div>
              </div>
              <div id="comparison-table">
                <Form form={form} component={false}>
                  <Table
                    dataSource={dataSource}
                    columns={columns.map((column: any) => {
                      let head = quotationHeader?.deliveryDateType;
                      // console.log("colll", head, column.dataIndex)
                      // if (head !== "ORDER_LEVEL" && column.dataIndex == "deliveryDate") {
                      //   // console.log("111111")
                      //   return {
                      //     ...column,
                      //     hidden: true,
                      //   };
                      // }
                      return column;
                    })}
                    pagination={false}
                    scroll={{ x: "max-content" }}
                    size="small"
                    bordered
                    style={{ paddingTop: 10 }}
                    summary={() => (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={0}
                            colSpan={6}
                            align="right"
                          >
                            <span className="summary-header">Sub Total</span>
                          </Table.Summary.Cell>
                          {supplierQuotations.map(
                            (supplier: any, index: any) => (
                              <Table.Summary.Cell
                                key={supplier.supplierID}
                                index={index + 5}
                                align="right"
                              >
                                {new Intl.NumberFormat("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                }).format(supplier.subTotal)}
                              </Table.Summary.Cell>
                            )
                          )}
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={0}
                            colSpan={6}
                            align="right"
                          >
                            <span className="summary-header">Total Taxes</span>
                          </Table.Summary.Cell>
                          {supplierQuotations.map(
                            (supplier: any, index: any) => (
                              <Table.Summary.Cell
                                key={supplier.supplierID}
                                index={index + 5}
                                align="right"
                              >
                                {new Intl.NumberFormat("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                }).format(supplier.totalTaxes)}
                              </Table.Summary.Cell>
                            )
                          )}
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={0}
                            colSpan={6}
                            align="right"
                          >
                            <span className="summary-header">
                              Payment Terms
                            </span>
                          </Table.Summary.Cell>
                          {supplierQuotations.map(
                            (supplier: any, index: any) => (
                              <Table.Summary.Cell
                                key={supplier.supplierID}
                                index={index + 5}
                                align="right"
                              >
                                {supplier.paymentTerms} &nbsp;
                                {supplier.paymentTerms === "Credit" && (
                                  <>( {supplier.paymentDays} days )</>
                                )}
                                {supplier.paymentTerms === "Advance" && (
                                  <>( {supplier.paymentPercentage} % )</>
                                )}
                              </Table.Summary.Cell>
                            )
                          )}
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={0}
                            colSpan={6}
                            align="right"
                          >
                            <span className="summary-header">
                              Delivery Lead Days
                            </span>
                          </Table.Summary.Cell>
                          {supplierQuotations.map(
                            (supplier: any, index: any) => (
                              <Table.Summary.Cell
                                key={supplier.supplierID}
                                index={index + 5}
                                align="right"
                              >
                                {supplier.leadTime}{" "}
                                {supplier.leadTime && "days"}
                              </Table.Summary.Cell>
                            )
                          )}
                        </Table.Summary.Row>

                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={0}
                            colSpan={6}
                            align="right"
                          >
                            <span className="summary-header">
                              Additional Charges
                            </span>
                          </Table.Summary.Cell>
                          {supplierQuotations.map(
                            (supplier: any, index: any) => (
                              <Table.Summary.Cell
                                key={supplier.supplierID}
                                index={index + 5}
                                align="right"
                              >
                                {new Intl.NumberFormat("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                }).format(supplier.totalAdditionalCharges)}
                              </Table.Summary.Cell>
                            )
                          )}
                        </Table.Summary.Row>

                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={0}
                            colSpan={6}
                            align="right"
                          >
                            <span className="summary-header">Grand Total</span>
                          </Table.Summary.Cell>
                          {supplierQuotations.map(
                            (supplier: any, index: any) => (
                              <Table.Summary.Cell
                                key={supplier.supplierID}
                                index={index + 5}
                                align="right"
                              >
                                {new Intl.NumberFormat("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                }).format(supplier.grandTotal)}
                              </Table.Summary.Cell>
                            )
                          )}
                        </Table.Summary.Row>
                      </>
                    )}
                  />
                </Form>
              </div>
            </div>
            <StatusIndicatorDrawer
              visible={statusIndicatorDrawerVisible}
              onClose={closeStatusIndicatorDrawer}
              onSubmit={handleStatusIndicatorSubmit} // Handle submission from the child component
            />
            <ScenarioBuilderDrawer
              scenarioBuilderData={scenarioBuilderData}
              visible={scenarioBuilderVisible}
              onClose={closeScenarioBuilder}
              onSubmit={handleScenarioSubmit}
            />
            <AddSupplierQuoteDrawer
              supplierQuoteData={supplierQuoteData}
              quotationId={rfq.quotationId}
              rfqId={rfq.rfqId}
              visible={supplierQuoteVisible}
              onClose={closeSupplierQuoteDrawer}
              onSubmit={handleSupplierQuoteSubmit}
            />
            {allocationDrawerVisible && (
              <AllocationDrawer
                visible={allocationDrawerVisible}
                onClose={closeAllocationDrawer}
                materials={materials.filter((material) =>
                  selectedMaterials.includes(material.key)
                )}
                supplierQuotations={supplierQuotations}
                submittedData={submittedData}
                onSubmit={handleAllocationSubmit} // Ensure this is correctly passed
              />
            )}
            {drawerVisible && (
              <ShortlistedSuppliersDrawer
                visible={drawerVisible}
                onClose={closeDrawer}
                rfqId={rfq.quotationId}
                siteName={rfq.siteName}
                quotation={rfq.rfqId}
              />
            )}
            {supplierDetailsDrawerVisible && (
              <SupplierDetailsDrawer
                visible={supplierDetailsDrawerVisible}
                onClose={closeSupplierDrawer}
                rfqId={rfq.quotationId}
                siteName={rfq.siteName}
                dataDrawer={supplierDetailsData}
              />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default QuotationComparison;
