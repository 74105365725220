import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";
import { formatCurrency } from "../../../utils/Utils";

function PurchaseByCategoryReport() {
  const navigate = useNavigate();

  const columns: any = [
    // {
    //   title: "Category Id",
    //   dataIndex: "categoryId",
    //   key: "categoryId",
    //   render: (text: any) => <span>{text}</span>,
    // },
    {
      title: "Category",
      dataIndex: "materialCategoryName",
      key: "materialCategoryName",
      fixed: "left",
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "Suppliers",
      dataIndex: "uniqueSuppliers",
      key: "uniqueSuppliers",
      
    },
    {
      title: "PO Count",
      dataIndex: "poCount",
      key: "poCount",
      
    },
    {
      title: "Invoice Count",
      dataIndex: "invoiceCount",
      key: "invoiceCount",
    },
    {
      title: "Value Without Tax",
      dataIndex: "amountWithOutTax",
      key: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(
                `/order-details-by-category-report/${record?.categoryId}`,
                {
                  state: {
                    name: record?.materialCategoryName,
                  },
                }

              );
            }}
          >
            {" "}
            {formatCurrency(text?.toString(),record?.currencyCode)}{" "}
          </span>
        </div>
      ),
    },
    {
      title: "Value With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(
                `/order-details-by-category-report/${record?.categoryId}`,
                {
                  state:{
                    name: record?.materialCategoryName,
                  } 
                }
              );
            }}
          >
            {" "}
            {formatCurrency(text?.toString(),record?.currencyCode)}{" "}
          </span>
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Purchase By Category"
      moduleKey="purchaseByCategoryReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
      }}
      previousPath="/view-purchase-reports"
      extra={{
        tableScrollX: 1000,
      }}
    />
  );
}

export default PurchaseByCategoryReport;
