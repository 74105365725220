import React, { useEffect } from "react";
import { Row, Col, Card, Tooltip } from "antd";
import {
  InfoCircleOutlined,
  ShoppingCartOutlined,
  DollarOutlined,
  ExclamationCircleOutlined,
  TruckOutlined,
} from "@ant-design/icons";

import { formatCurrency, formatCurrency2 } from "../../utils/Utils";

interface PaymentWidgetProps {
  totalPurchases: number;
  purchasesMonthValue: number;
  purchasesWeekValue: number;
  accountsPayables: number;
  payablesMonthValue: number;
  payablesWeekValue: number;
  overdue: number;
  overdueMonthValue: number;
  overdueWeekValue: number;
  currency: string;
}

const InventoryWidgets: React.FC<any> = ({ widgetResponse }) => {
  useEffect(() => {
    console.log("widgetData", widgetResponse);
  }, []);
  return (
    <Row gutter={[16, 16]}>
      <Col span={24} md={6}>
        <Card className="card-full">
          <div className="card-title-group align-start mb-0">
            <div className="card-title">
              <h6 className="subtitle">Inventory Purchased</h6>
            </div>
            <div className="card-tools">
              <Tooltip title="Inventory Purchased">
                <InfoCircleOutlined style={{ fontSize: 16 }} />
              </Tooltip>
            </div>
          </div>
          <div className="card-amount">
            <div className="g-2">
            <span className="amount">
              {widgetResponse[0]&&formatCurrency2(widgetResponse[0]?.inwardPrice, "INR")}
              <Tooltip
                title={formatCurrency(widgetResponse[0]?.inwardPrice, "INR")}
              >
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
              </Tooltip>
            </span>
            </div>
            <div className="invest-data-icon" style={{marginLeft: "63%",marginTop: "10%"}}>
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  backgroundColor: "#5470C6",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DollarOutlined style={{ color: "#fff", fontSize: 24 }} />
              </div>
            </div>
          </div>
          {/* <div className="invest-data">
            <div className="invest-data-amount g-2"></div>
           
          </div> */}
        </Card>
      </Col>

      <Col span={24} md={6}>
        <Card className="card-full">
          <div className="card-title-group align-start mb-0">
            <div className="card-title">
              <h6 className="subtitle">Inventory Utilized</h6>
            </div>
            <div className="card-tools">
              <Tooltip title="Inventory Utilized">
                <InfoCircleOutlined style={{ fontSize: 16 }} />
              </Tooltip>
            </div>
          </div>
          <div className="card-amount">
          <div className="g-2">
            <span className="amount">
              {widgetResponse[0]&&formatCurrency2(widgetResponse[0]?.handoverPrice, "INR")}
              <Tooltip
                title={formatCurrency(widgetResponse[0]?.handoverPrice, "INR")}
              >
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
              </Tooltip>
            </span>
            </div>
            <div className="invest-data-icon" style={{marginLeft: "63%",marginTop: "10%"}}>
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  backgroundColor: "#5470C6",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DollarOutlined style={{ color: "#fff", fontSize: 24 }} />
              </div>
            </div>
          </div>
        </Card>
      </Col>

      <Col span={24} md={6}>
        <Card className="card-full">
          <div className="card-title-group align-start mb-0">
            <div className="card-title">
              <h6 className="subtitle">OnHand Inventory</h6>
            </div>
            <div className="card-tools">
              <Tooltip title="OnHand Inventory">
                <InfoCircleOutlined style={{ fontSize: 16 }} />
              </Tooltip>
            </div>
          </div>
          <div className="card-amount">
          <div className="g-2">
            <span className="amount">
              {widgetResponse[0]&&formatCurrency2(widgetResponse[0]?.outstandingPrice, "INR")}
              <Tooltip
                title={formatCurrency(
                  widgetResponse[0]?.outstandingPrice,
                  "INR"
                )}
              >
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
              </Tooltip>
            </span>
            </div>
            <div className="invest-data-icon" style={{marginLeft: "63%",marginTop: "10%"}}>
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  backgroundColor: "#5470C6",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ShoppingCartOutlined style={{ color: "#fff", fontSize: 24 }} />
              </div>
            </div>
          </div>
        </Card>
      </Col>

      <Col span={24} md={6}>
        <Card className="card-full">
          <div className="card-title-group align-start mb-0">
            <div className="card-title">
              <h6 className="subtitle">To Be Delivered </h6>
            </div>
            <div className="card-tools">
              <Tooltip title="To Be Delivered">
                <InfoCircleOutlined style={{ fontSize: 16 }} />
              </Tooltip>
            </div>
          </div>
          <div className="card-amount">
          <div className="g-2">
            <span className="amount">
              {widgetResponse[0]&&formatCurrency2(widgetResponse[0]?.upcomingPrice, "INR")}
              <Tooltip
                title={formatCurrency(widgetResponse[0]?.upcomingPrice, "INR")}
              >
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
              </Tooltip>
            </span>
            </div>
            <div className="invest-data-icon" style={{marginLeft: "63%",marginTop: "10%"}}>
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  backgroundColor: "#5470C6",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TruckOutlined style={{ color: "#fff", fontSize: 24 }} />
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default InventoryWidgets;
