import React, { useState, useEffect, useMemo } from 'react';
import { Select, Empty, Drawer, Table } from 'antd';
import * as echarts from 'echarts';
import { Col, Row } from 'reactstrap';
import UserLabel from '../../../utils/UserLabel';
import { colors } from '../../../utils/Utils';
import { render } from '@testing-library/react';

const MaterialWiseSavings: React.FC<any> = ({ materialWiseSavingsData }) => {
    console.log("materialWiseSavingsData", materialWiseSavingsData);
    const supplierData = materialWiseSavingsData?.supplierData || [];
    const materialWiseAvg = materialWiseSavingsData?.materialWiseAvg;
    const materials = materialWiseSavingsData?.materialWiseSavings;
    const [visible, setVisible] = useState(false);
    const flattenedArray = useMemo(() => {
        return materials ? Object.values(materials).flat() : [];
    }, [materials]); // Only recalculate when materials change

    const [currentData, setCurrentData] = useState<any>([]);

    useEffect(() => {
        console.log("flattenedArray", flattenedArray);
        setCurrentData(flattenedArray);
    }, [flattenedArray]);

    useEffect(() => {
        if (currentData.length > 0) {
            const chartDom = document.getElementById('material-savings-chart') as HTMLElement;
            if (chartDom) {
                const myChart = echarts.init(chartDom);

                const option = {
                    title: {
                        text: `Avg. Savings - ${materialWiseAvg} %`,
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { type: 'shadow' },
                    },
                    xAxis: {
                        type: 'category',
                        data: currentData.map((item: any) => item.material),
                        name: 'Material',
                        nameLocation: 'middle',
                        nameTextStyle: {
                            fontWeight: 'bold',
                            fontSize: 14,
                            padding: 40,
                        },
                        axisLabel: {
                            interval: 0,
                            formatter: (value: string) => {
                                const maxWidth = 10;
                                if (value.length > maxWidth) {
                                    return value.split(' ').reduce((acc, word) => {
                                        if (acc.length === 0) {
                                            acc.push(word);
                                        } else {
                                            const lastLine = acc[acc.length - 1];
                                            if ((lastLine + ' ' + word).length <= maxWidth) {
                                                acc[acc.length - 1] = lastLine + ' ' + word;
                                            } else {
                                                acc.push(word);
                                            }
                                        }
                                        return acc;
                                    }, [] as string[]).join('\n');
                                } else {
                                    return value;
                                }
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: { formatter: '{value}%' },
                        name: 'Avg. Savings (%)',
                        nameLocation: 'middle',
                        nameTextStyle: {
                            fontWeight: 'bold',
                            fontSize: 14,
                            padding: 40,
                        },
                    },
                    series: [
                        {
                            name: 'Savings',
                            type: 'bar',
                            data: currentData.map((item: any) => item.savings),
                            itemStyle: {
                                color: '#6675FF',
                            },
                            barWidth: '20%',
                            barCategoryGap: '100%',
                        },
                    ],
                };

                myChart.setOption(option);

                return () => {
                    myChart.dispose();
                };
            }
        }
    }, [currentData, materialWiseAvg]);

    const openDrawer = () => {
        console.log("currentData", materialWiseSavingsData.supplierData);
        setVisible(true);
    }

    const supplierColorMap = useMemo(() => {
        const map: { [key: string]: string } = {};
        let colorIndex = 0;

        supplierData.forEach((record: any) => {
            if (!map[record.supplierName]) {
                map[record.supplierName] = colors[colorIndex % colors.length];
                colorIndex++;
            }
        });

        return map;
    }, [supplierData]);

    const columns = [
        {
            title: "Supplier Name",
            dataIndex: "supplierName",
            key: "supplierName",
            render: (text: string, record: any) => (
                <UserLabel
                    supplierName={record.supplierName}
                    color={supplierColorMap[record.supplierName]}
                    text={text}
                />
            ),
        },
        {
            title: "Savings",
            dataIndex: "savings",
            key: "savings",
            render: (text: any) => {
                const numberText = Number(text); // Convert text to a number
                return !isNaN(numberText) ? `${numberText.toFixed(2)}%` : 'N/A'; // Check if it's a valid number
            },
        }
    ];


    const onClose = () => {
        setVisible(false);
    }

    return (
        <>
            <div>
                <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="card-title">
                        <h6 className="title">Material Wise Savings</h6>
                        <p>Insights on savings achieved across different materials.</p>
                    </div>
                    {/* {supplierData.length} */}
                    {supplierData.length > 0 && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <a className="title" style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    openDrawer()
                                }}
                            >View supplier wise savings</a>
                        </div>
                    )}

                </div>
                {currentData.length > 0 ? (
                    <div id="material-savings-chart" style={{ width: '100%', height: '300px' }} />
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                        <Empty description="No Data found for the selected category" />
                    </div>
                )}
            </div>

            <Drawer
                title={"Supplier savings"}
                width={"35vw"}
                onClose={onClose}
                open={visible}
                bodyStyle={{ paddingBottom: 80 }}
            >

                <Table
                    columns={columns}
                    dataSource={supplierData}
                    rowKey="id"
                    size="small"
                    pagination={false}
                />
            </Drawer>

        </>
    );
};

export default MaterialWiseSavings;
