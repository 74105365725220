import React from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    PreviewCard,
    BlockBetween,
    Button,
    Icon
} from "../../../components/Component";
import { Label, Input, Row, Col } from "reactstrap";
const Header: React.FC = (props: any) => {
    return (
        <>
           
           <div className="steps clearfix">
                <ul>
                    <li className={props.current >= 1 ? "first done" : "first"}>
                        <a href="#wizard-01-h-0" onClick={(ev) => ev.preventDefault()}>
                            <span className="number">01</span> <h5>Personal Information</h5>
                        </a>
                    </li>
                    <li className={props.current >= 2 ? "done" : ""}>
                        <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
                            <span className="current-info audible">current step: </span>
                            <span className="number">02</span> <h5> Hierarchy Information</h5>
                        </a>
                    </li>
                    <li className={props.current === 3 ? "last done" : "last"}>
                        <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                            <span className="current-info audible">current step: </span>
                            <span className="number">03</span> <h5>Accessibility</h5>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};
export default Header;
