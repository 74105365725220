import React, { useState, useEffect, FC, useCallback } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Content from "../../layout/content/Content";
import {
  Card,
  Badge,
  Row,
  Collapse,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  TooltipComponent,
} from "../../components/Component";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  PaperClipOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Form,
  TableColumnsType,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Select,
  Divider,
  Space,
  Timeline,
  Collapse as AntCollapse,
  Button as AntButton,
  Descriptions,
  List,
  Modal as AntModal,
  Empty,
  Flex,
  Spin,
  DatePicker,
  Popover,
} from "antd";
import Swal from "sweetalert2";
import { columnData } from "../app/kanban/KanbanData";
import inventoryCreation from "../../api/inventory/inventoryCreation";
import dayjs from "dayjs";
import { set } from "date-fns";
import CropperComponent from "../components/CropperComponent";
import SignatureModal from "../purchase-orders/SignatureModal";
import rolesApi from "../../api/master/roles";
const { Panel } = AntCollapse;

const MaterialDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [sm, setSm] = useState(false);
  const [tab, setTab] = useState("1");
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSubmitBtm, setShowSubmitBtm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [data, setData] = useState([]);
  const [signature, setSignature] = useState<any>({});
  const [detailsData, setDetailsData] = useState<any>();
  const [showSignatureModel, setShowSignatureModel] = useState<boolean>(false);
  const [showCropModel, setShowCropModel] = useState<boolean>(false);
  const location = useLocation();
  const screenNavigationStatus = location.state?.screenStatus;
  const [roles, setRoles] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const columnData = [
    {
      title: "Material Category",
      dataIndex: "categoryName",
      key: "categoryName",
      sorter: true,
      width: 200,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      sorter: true,
      width: 200,
    },
    {
      title: "UOM",
      dataIndex: "uomName",
      key: "uomName",
      sorter: true,
      width: 200,
    },
    {
      title: "Qty Available For Utilization",
      dataIndex: "quanitityAvailable",
      key: "quanitityAvailable",
      sorter: true,
      width: 200,
      render: (text: number, record: any) => (
        <div>
          <span>{text.toLocaleString('en-IN')}</span>
        </div>
      )
    },
    {
      title: "QTY TO ISSUE",
      dataIndex: "quantityIssued",
      key: "quantityIssued",
      sorter: true,
      width: 200,
      render: (text: number, record: any) => (
        <div>
          <span>{text.toLocaleString('en-IN')}</span>
        </div>
      )
    },
  ];

  const fetchApprovalHierarchyUsersByModuleId = async (moduleID: number) => {
    // console.log("formData.site", formData.budgetHeader.site)
    try {
      const res = await rolesApi.fetchHierarchyUsersByModuleId(
        moduleID,
        Number(detailsData?.header?.siteId)
      );
      if (!res) return;
      const { data, status } = res;
      if (status) {
        setRoles(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchModuleConfig();
  }, []);

  const fetchModuleConfig = async () => {
    try {
      const res = await rolesApi.getModuleConfig();
      const moduleID = res?.data?.find(
        (x: any) => x.moduleName == "Budget"
      )?.moduleID;
      console.log(moduleID);
      await fetchApprovalHierarchyUsersByModuleId(moduleID);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (id) {
      fetchMaterialDetails(id);
    }
  }, []);

  const fetchMaterialDetails = async (id: any) => {
    console.log("id", id);
    const response = await inventoryCreation.fetchViewMaterialDetails(id);
    const { response: _response, status } = response;
    if (status) {
      setDetailsData(_response.data);
    }
  };

  const ApprovalTimelineContent = () => {
    // const approvalsData = detailsData?.approvals && JSON.parse(detailsData?.approvals);
    // console.log("approvalsData", approvalsData)
    if (
      !detailsData?.approvals ||
      detailsData?.approvals?.length === 0 ||
      detailsData?.header?.approvalRequired == "N"
    )
      return <Badge color="outline-danger">No</Badge>;
    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {detailsData?.approvals?.map((approval: any, index: number) => {
              const Role = roles.find(
                (role: any) => role?.roleID == approval?.roleId
              );
              return (
                <Timeline.Item key={index}>
                  {
                    Role?.employees?.find((e: any) => e?.id == approval?.userId)
                      ?.name
                  }
                  <div className="designation">{Role?.roleName}</div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Yes
        </span>
      </Popover>
    );
  };

  const submitSave = () => {};

  const approveIssue = async() => {
    try {
        const res = await inventoryCreation.approve(detailsData?.header?.issueNumber);
        const { response, status, message } = res;
        console.log("data", res);
        if (status) {
          Swal.fire("Approved!", "Approved", "success");
          navigate(-1);
        } else {
          Swal.fire("Failed!", message, "error");
        }
      } catch (error) {
        Swal.fire("Failed!", "Something went wrong", "error");
      }
  };

  const rejectIssue = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      // Assuming `record.headerId` is used to identify the purchase order
      const payload = {
        materialIssueId: detailsData?.header?.issueNumber,
        reason: values.reason,
      };

      // Call the API to submit the rejection
      await apiCallToRejectBudget(detailsData?.header?.issueNumber, values.reason);

      // Handle success (e.g., show a success message, refresh the table)

      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      // Handle validation errors or API errors
    }
  };

  const apiCallToRejectBudget = async (issueNumber:any, reason: string) => {
    // Replace this with your actual API call logic
    try {
      const res = await inventoryCreation.reject(issueNumber, { reason: reason });
      const { response, status, message } = res;
      console.log("data", res);
      if (status) {
        Swal.fire("Rejected!", "Rejected", "success");
        navigate(-1);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };


  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <>
      <Head title="Material Issue Details" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">
                        <span>
                          Material Issue # - {detailsData?.header?.issueNumber}{" "}
                          | {detailsData?.header?.issueStatus}
                          {/* {(statusMapper[rfqStatus] &&
                              statusMapper[rfqStatus]?.buyer?.title) ||
                              rfqStatus}{" "} */}
                        </span>
                      </BlockTitle>
                    </BlockHeadContent>
                    <div className="d-flex">
                      {showSubmitBtm && (
                        <div className="actions clearfix">
                          <ul
                            className="m-auto d-flex align-items-center justify-content-center p-0"
                            style={{
                              flexDirection: "row-reverse",
                            }}
                          >
                            <li className="pt-0 pb-0">
                              {disableSubmit ? (
                                <Button color="primary" type="button">
                                  <Spinner size="sm" color="light" />
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  type="button"
                                  onClick={() => {
                                    setDisableSubmit(true);
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "Do you want to submit the changes?",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, submit it!",
                                      cancelButtonText: "No, cancel",
                                    }).then(async (result) => {
                                      if (result.isConfirmed) {
                                        await submitSave();
                                      } else {
                                        setDisableSubmit(false);
                                      }
                                    });
                                  }}
                                >
                                  Submit
                                </Button>
                              )}
                            </li>
                          </ul>
                        </div>
                      )}

                      {screenNavigationStatus ===
                        "Pending Material Issue Approvals" && (
                        <>
                          <Button
                            onClick={() => {
                              //   setDisableAccept(true);
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, Confirm it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  approveIssue();
                                  //   setViewDrawer(false);
                                  //   approveCN(drawerData?.noteNumber);
                                } else {
                                  //   setDisableAccept(false);
                                }
                              });
                            }}
                            color="success"
                            className="m-3 mt-0 mb-0"
                          >
                            Approve
                          </Button>
                          <Button
                            onClick={() => {
                              //   setDisableReject(true);
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, Reject it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  rejectIssue();
                                  //   setViewDrawer(false);
                                  //   setShowRejectModal(true);
                                } else {
                                  //   setDisableReject(false);
                                }
                              });
                            }}
                            color="light"
                            className="m-3 mt-0 mb-0"
                          >
                            Reject
                          </Button>
                        </>
                      )}

                      {!showSubmitBtm && (
                        <div className="paddingLeft10">
                          <Button
                            className="toggle"
                            color="primary"
                            onClick={async () => {
                              navigate("/material-issue");
                            }}
                          >
                            <Icon name="arrow-left" />
                            <span>Back</span>
                          </Button>
                        </div>
                      )}
                    </div>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${
                          sm ? "active" : ""
                        }`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>
                <div className="content clearfix">
                  <AntCollapse defaultActiveKey={["1", "2"]}>
                    <Panel header="General Information" key="1">
                      <div>
                        <Col className="justify-start w-100  flex-wrap  p-3">
                          <div className="w-50 border-end ">
                            <div className="data-col align-center pt-1 ">
                              <div className="data-label w-30">Site</div>
                              <div className="data-value w-70 ">
                                {detailsData?.header?.siteName}
                              </div>
                            </div>
                            <div className="data-col align-center pt-1 ">
                              <div className="data-label w-30">
                                Block / Tower
                              </div>
                              <div className="data-value w-70 ">
                                {detailsData?.header?.blockName}
                              </div>
                            </div>
                            <div className="data-col align-center pt-1 ">
                              <div className="data-label w-30">Approvals</div>
                              <div className="data-value w-70 ">
                                {detailsData?.approvals && (
                                  <ApprovalTimelineContent />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="w-50 paddingLeft10">
                            <div className="data-col align-center pt-1 ">
                              <div className="data-label w-30">
                                Creation Date
                              </div>
                              <div className="data-value w-70 ">
                                {dayjs(detailsData?.header?.createdAt).format(
                                  "DD-MMM-YYYY"
                                )}
                              </div>
                            </div>
                            <div className="data-col align-center pt-1 ">
                              <div className="data-label w-30">Created By</div>
                              <div className="data-value w-70 ">
                                {detailsData?.header?.createdUserName}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </Panel>
                    <Panel header="Material Information" key="2">
                      <div className="table-responsive poTable">
                        <div
                          style={{
                            width: screenSize - 350 + "px",
                            margin: "0px auto 15px auto",
                          }}
                        >
                          <Table
                            className="customTable"
                            columns={columnData}
                            dataSource={detailsData?.lines?.map(
                              (item: any) => ({
                                ...item,
                                key: item?.issueLineId,
                              })
                            )}
                            scroll={{ x: 1000 }}
                            bordered
                            size="middle"
                            pagination={false}
                          />
                        </div>
                      </div>
                      {screenNavigationStatus ===
                        "Pending Material Issue Approvals" && (
                        <Row className="m-4 justify-content-end ">
                          {signature?.isActive ? (
                            <div className="signatureBtnWrapper">
                              <div className="d-flex justify-content-between align-items-center">
                                <strong>Signature</strong>
                                <a
                                  style={{
                                    fontSize: "14px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSignature((prev: any) => ({
                                      ...prev,
                                      isActive: false,
                                      image: null,
                                      label: "Authorised Signatory",
                                    }));
                                    setValue("signatureObj", {
                                      isActive: false,
                                      image: null,
                                      label: "Authorised Signatory",
                                    });
                                  }}
                                >
                                  <Icon
                                    name="cross"
                                    className="pointer"
                                    onClick={() => {
                                      setSignature((prev: any) => ({
                                        ...prev,
                                        isActive: false,
                                        image: null,
                                        label: "Authorised Signatory",
                                      }));
                                      setValue("signatureObj", {
                                        isActive: false,
                                        image: null,
                                        label: "Authorised Signatory",
                                      });
                                    }}
                                  />
                                </a>
                              </div>
                              <div
                                style={{
                                  minHeight: signature?.image ? "0px" : "120px",
                                  display: signature?.image ? "block" : "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {!signature?.image && (
                                  <Button
                                    color="btn-dim btn"
                                    onClick={() => {
                                      setShowCropModel(true);
                                    }}
                                  >
                                    <Icon
                                      name="upload-cloud"
                                      className="pointer"
                                      onClick={() => {
                                        setShowCropModel(true);
                                      }}
                                    />
                                    <span>Upload</span>
                                  </Button>
                                )}
                                {signature?.image && (
                                  <>
                                    <div className="signatueImgWrapper border">
                                      <img
                                        src={signature?.image}
                                        alt="Signed"
                                        style={{ width: "160px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button
                                        color="btn-dim btn"
                                        onClick={() => {
                                          setSignature((pre: any) => {
                                            return { ...pre, image: null };
                                          });
                                        }}
                                      >
                                        <span>Reset</span>
                                        <Icon
                                          name="redo"
                                          className="pointer"
                                          onClick={() => {
                                            setSignature((pre: any) => {
                                              return {
                                                ...pre,
                                                image: null,
                                              };
                                            });
                                          }}
                                        />
                                      </Button>
                                    </div>
                                    <div>
                                      <div className="form-group">
                                        <label
                                          className="form-label"
                                          htmlFor="signatureLabel"
                                        >
                                          Signature Label
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="signatureLabel"
                                          onChange={(e) => {
                                            setSignature((pre: any) => {
                                              return {
                                                ...pre,
                                                label: e.target.value,
                                              };
                                            });
                                          }}
                                          defaultValue={
                                            signature?.label ||
                                            "Authorised Signatory"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>

                              {!signature?.image && (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Button
                                      color="btn-dim btn"
                                      onClick={() =>
                                        setShowSignatureModel(true)
                                      }
                                    >
                                      <Icon name="pen" />
                                      <span>Use Signature Pad</span>
                                    </Button>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              style={{
                                border: "1px solid #e5ecf7",
                                backgroundColor: "#f7faff",
                                padding: "10px",
                                marginLeft: "10px",
                                width: "22%",
                                borderRadius: "5px",
                                textAlign: "center",
                              }}
                            >
                              <Button
                                color="btn-dim btn"
                                onClick={() => {
                                  setSignature((pre: any) => {
                                    return { ...pre, isActive: true };
                                  });
                                }}
                              >
                                <Icon
                                  name="edit"
                                  className="pointer"
                                  onClick={() => {
                                    setSignature((pre: any) => {
                                      return { ...pre, isActive: true };
                                    });
                                  }}
                                />
                                <span>Add Signature</span>
                              </Button>
                            </div>
                          )}
                        </Row>
                      )}
                    </Panel>
                  </AntCollapse>
                  {showCropModel && (
                    <CropperComponent
                      modal={showCropModel}
                      toggle={() => setShowCropModel(!showCropModel)}
                      onSave={(data: string) => {
                        setSignature((pre: any) => {
                          return { ...pre, image: data };
                        });
                        setShowCropModel(false);
                      }}
                    />
                  )}
                  {showSignatureModel && (
                    <SignatureModal
                      modal={showSignatureModel}
                      toggle={() => setShowSignatureModel(!showSignatureModel)}
                      onSave={(data: string) => {
                        setSignature((pre: any) => {
                          return { ...pre, image: data };
                        });
                        setShowSignatureModel(false);
                      }}
                    />
                  )}
                  <AntModal
                    title="Reject Budget"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText="Submit"
                    cancelText="Cancel"
                  >
                    <Form form={form} layout="vertical" name="rejectForm">
                      <Form.Item
                        name="reason"
                        label="Reason for Rejection"
                        rules={[
                          {
                            required: true,
                            message: "Please input your reason!",
                          },
                        ]}
                      >
                        <Input.TextArea placeholder="Type your reason here..." />
                      </Form.Item>
                    </Form>
                  </AntModal>
                </div>
              </div>
            )}
          </div>
        </Card>
      </Content>
    </>
  );
};

export default MaterialDetails;
