import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useLocation, useNavigate, useParams } from "react-router";
import FormatDate from "../../masters/DateFormater";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";

function PurchaseBySupplierDetailReport() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const name = location.state?.name;
  const columns: any = [
    {
      key: "rfqId",
      title: "RFQ #",
      dataIndex: "rfqId",
    },
    {
      key: "orderId",
      title: "Order #",
      dataIndex: "orderId",
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/purchase-order-report-preview/${record.orderNumber}`, {
                state: {
                  previousPath: `/purchase-by-supplier-detail-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      key: "orderDate",
      title: "Order Date",
      dataIndex: "orderDate",
      width: 100,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: {
                  previousPath: `/purchase-by-supplier-detail-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 100,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    // {
    //   key: "materialName",
    //   title: "Material Name",
    //   dataIndex: "materialName",
    // },
    // {
    //   key: "unitPrice",
    //   title: "Unit Price",
    //   dataIndex: "unitPrice",
    //   render: (text: any, record: any) => (
    //     <div style={{ textAlign: "right" }}>
    //       <span>{text}</span>
    //     </div>
    //   ),
    // },
    {
      key: "amountWithOutTax",
      title: "Invoice Value Without Tax",
      dataIndex: "amountWithoutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      key: "amountWithTax",
      title: "Invoice Value With Tax",
      dataIndex: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle={`${name} - Purchases, Detail Report`}
      moduleKey="purchaseBySupplierDetailReport"
      columns={columns}
      filtersOption={{
        site: false,
        contractor: false,
        dateRange: false,
      }}
      previousPath="/purchase-by-supplier-reports-list"
      extra={{
        id: id,
        tableScrollX: 1000,
      }}
    />
  );
}

export default PurchaseBySupplierDetailReport;
