import React, { useState, useEffect } from "react";
import { Table, Select, Empty } from "antd";
import { formatCurrency } from "../../utils/Utils"; // Ensure the import path is correct
import supplierApis from "../../api/master/supplier";
import mainDashboardApis from "../../api/dashboard/MainDashboard";

const { Option } = Select;

interface MaterialData {
  materialID: string;
  materialName: string;
  allocatedBudget: number;
  spent: number;
  overrun_percentage: number;
  category: number;
}

interface BudgetOverRunData {
  siteName: string;
  siteID: string;
  currency: string;
  data: MaterialData[];
}

const BudgetOverRunWidget: React.FC<any> = ({ dates, sites }) => {
  const [data, setTopSupplierSavings] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const allCategory: any = [];
        const _categoryOptions: any = [];
        data?.forEach((item: any) => {
          console.log("data", item);
          allCategory.push(item.id);
          _categoryOptions.push({ label: item.category, value: item.id });
        });
        setCategory(allCategory.join(","));
        setCategoryOptions([
          {
            label: "All Categories",
            value: allCategory?.join(","),
          },
          ..._categoryOptions,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryChange = (value: string) => {
    setCategory(value.toString());
    setFilteredData(data?.filter((item: any) => item.categoryID == category));
  };

  useEffect(() => {
    if (sites && category) {
      fetchTopSupplierSavings();
    }
  }, [sites, category, dates]);

  const fetchTopSupplierSavings = async () => {
    try {
      const { from, to } = dates;
      const formData = {
        siteId: sites,
        categoryId: category,
        startDate: from,
        endDate: to,
      };
      const res = await mainDashboardApis.budgetOverRunByMaterial(formData);
      const { response, status } = res;
      console.log("budgetOverRunByMaterial", response);
      if (status) {
        setTopSupplierSavings(response);
        setFilteredData(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Filter data based on the selected category

  const columns = [
    {
      title: "Material",
      dataIndex: "materialName",
      key: "materialName",
    },
    {
      title: "Allocated Budget",
      dataIndex: "allocatedBudget",
      key: "allocatedBudget",
      render: (value: number) => formatCurrency(value, "INR"),
    },
    {
      title: "Spent Value",
      dataIndex: "spent",
      key: "spent",
      render: (value: number) => formatCurrency(value, "INR"),
    },
    {
      title: "Budget Overrun (%)",
      dataIndex: "overrun_percentage",
      key: "overrun_percentage",
      render: (value: number) => {
        let icon =
          Number(value) > 0 ? (
            <span className="change up">
              <em className="icon ni ni-arrow-long-up"></em>
            </span>
          ) : (
            <span className="change down">
              <em className="icon ni ni-arrow-long-down"></em>
            </span>
          );
        return (
          <>
            {Number(value).toFixed(2)}% 
            {/* {icon} */}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div
        className="card-title-group mb-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="card-title">
          <h6 className="title">Budget Overrun by Material</h6>
          <p>
            Insights on materials exceeding their budget allocation.{" "}
            <a href="#" onClick={(ev) => ev.preventDefault()}>View All</a>
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 8 }}>Filter by Category:</span>
          <Select
            defaultValue="ALL"
            style={{ width: 150 }}
            onChange={handleCategoryChange}
          >
            {categoryOptions?.map((item: any) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </div>
      </div>
      {filteredData.length > 0  ? (
        <div className="table_large">
          <Table
            columns={columns}
            dataSource={filteredData}
            rowKey="materialID"
            pagination={false}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "striped-row"
            }
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Empty description="No data available to generate or display the graph." />
        </div>
      )}
    </div>
  );
};

export default BudgetOverRunWidget;
