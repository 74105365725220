import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import {
  TableColumnsType,
  Drawer,
  Tabs,
  Table,
  Tooltip,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
} from "antd";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import mainDashboardApis from "../../api/dashboard/MainDashboard";
import supplierApis from "../../api/master/supplier";
import misc from "../../api/misc/dropDowns";
import { formatCurrency } from "../../utils/Utils";
import contractorsApis from "../../api/master/contractors";
const { Option } = Select;

const { TabPane } = Tabs;

const TopPurchaseCategoriesDetailsDrawer: React.FC<any> = ({
  selectedSite,
  selectedContractor,
  contractorOptions,
  visible,
  siteOptions,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [screenSize, setScreenSize] = useState(0);
  const [originalData, setOriginalData] = useState([]);
  const [priceComparisionData, setpriceComparisionData] = useState([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [site, setSite] = useState(selectedSite);
  const [category, setCategory] = useState("");
  const [materialsOptions, setMaterialsOptions] = useState<any>([]);
  const [priceComparisionCategoryOptions, setPriceComparisionCategoryOptions] = useState<any>([]);
  const [material, setMaterial] = useState("");
  const [priceCompCategory, setPriceComparisionCategory] = useState("");
  const [contractor, setContractor] = useState(selectedContractor);
  const [monthsData, setMonthsData] = useState<any>();
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const handleSiteChange = (value: any) => {
    setSite(value.toString());
  };
  const handleCategoryChange = (value: any) => {
    setCategory(value.toString());
  };
  const handlePCCategoryChange = (value: any) => {
    setPriceComparisionCategory(value.toString());
  };
  const handleContractorChange = (value: any) => {
    setContractor(value.toString());
  };
  const handleMaterialChange = (value: any) => {
    setMaterial(value.toString());
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const { data, status } = await misc.fetchSupplierCategoryFiltered();
      if (status) {
        const allCategory: any = [];
        const _categoryOptions: any = [];
        data?.forEach((item: any) => {
          console.log("data", item);
          allCategory.push(item.id);
          _categoryOptions.push({ label: item.category, value: item.id });
        });
        setCategory(allCategory.join(","));
        // setPriceComparisionCategory(allCategory[0]);
        setPriceComparisionCategoryOptions([
          ..._categoryOptions,
        ]);
        setCategoryOptions([
          {
            label: "All Categories",
            value: allCategory?.join(","),
          },
          ..._categoryOptions,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (category) {
      getPurchaseBySupplierReport();
    }
  }, [category]);

  useEffect(() => {
    if (contractor) {
      getPurchaseBySupplierReport();
    }
  }, [contractor]);

  useEffect(() => {
      getpriceComparisionReport();
  }, [material]);

  useEffect(() => {
    if (priceCompCategory) {
      getProductFiltered();
    }
  }, [priceCompCategory]);

  const getpriceComparisionReport = async () => {
    console.log("selectedContractor", selectedContractor);
    let data = {
      // siteId: selectedSite,
      categoryId: parseInt(priceCompCategory),
      // contractorId: selectedContractor,
      materialId: parseInt(material),
    };
    const res = await mainDashboardApis.priceComparisionReport(data);
    const { response, status, message } = res;
    if (status) {
      setpriceComparisionData(response.supplierHeaders);
      const formattedMonthsData = response.monthWiseData.map(
        (suppliers: any) => {
          const supplierData = suppliers.supplierData.find((item: any) => {
            return response?.supplierHeaders?.some(
              (header: any) => header.supplierID == item.supplierID
            );
          });
          console.log("supplierData", supplierData);
          if (supplierData) {
            console.log("))))))", { ...suppliers, ...supplierData });
            return { ...suppliers, ...supplierData }; // Merging supplier data if matched
          }
          return suppliers;
        }
      );
      setMonthsData(response.monthWiseData);
      // ));
    }
  };


  const getProductbyIdFiltered = async () => {
    const res = await misc.fetchProductByCategoryId(priceCompCategory);
    const { data, status, message } = res;
    console.log("data1111", data);
    if (status) {
      let allMaterials: any = [];
      let _materialsOptions: any = [];
      data.forEach((item: any) => {
        allMaterials.push(item?.materialID?.toString());
        _materialsOptions.push({
          label: item?.productName,
          value: item?.materialID?.toString(),
        });
      });

      // setContractor(allMaterials?.join(","));
      // if (_materialsOptions.length > 1) {
      //   _materialsOptions = [
      //     {
      //       label: "ALL",
      //       value: allMaterials?.join(","),
      //     },
      //     ..._materialsOptions,
      //   ];
      // }
      setMaterialsOptions(_materialsOptions);
      // setMaterial(data[0].materialID);
    }
  };

  const getProductFiltered = async () => {
    const res = await misc.fetchProductByCategoryId(priceCompCategory);
    const { data, status, message } = res;
    console.log("data1111", data);
    if (status) {
      let allMaterials: any = [];
      let _materialsOptions: any = [];
      data.forEach((item: any) => {
        allMaterials.push(item?.materialID?.toString());
        _materialsOptions.push({
          label: item?.productName,
          value: item?.materialID?.toString(),
        });
      });

      // setContractor(allMaterials?.join(","));
      // if (_materialsOptions.length > 1) {
      //   _materialsOptions = [
      //     {
      //       label: "ALL",
      //       value: allMaterials?.join(","),
      //     },
      //     ..._materialsOptions,
      //   ];
      // }
      setMaterialsOptions(_materialsOptions);
      // setMaterial(data[0].materialID);
    }
  };

  const getPurchaseBySupplierReport = async () => {
    let data = {
      siteId: selectedSite,
      categoryId: category,
    };
    const res = await mainDashboardApis.purchaseBySupplierReport(data);
    const { response, status, message } = res;
    if (status) {
      setOriginalData(response);
      setFilteredData(response);
      setIsLoading(false);
    }
  };

  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "Supplier Name",
      key: "supplierName",
      dataIndex: "supplierName",
      width: 200,
      fixed: "left",
    },
    {
      title: "category",
      key: "category",
      dataIndex: "category",
      width: 150,
      fixed: "left",
    },
    {
      title: "Purchase Value",
      dataIndex: "poValue",
      key: "poValue",
      width: 170,
      render: (text: any) => formatCurrency(text, "INR"),
      // render: (text) => (
      //   <div className="align-left">
      //     <span>{text}</span>
      //   </div>
      // ),
    },
    {
      title: "% Of Purchase",
      dataIndex: "popercentage",
      key: "popercentage",
      width: 100,
      render: (text: any) => `${text && Number(text).toFixed(2)}%`,
    },
    {
      title: "Total Orders",
      dataIndex: "totalOrders",
      key: "totalOrders",
      width: 100,
    },
    {
      title: "Avg Quote Time",
      dataIndex: "avgresTime",
      key: "avgresTime",
      width: 100,
    },
    {
      title: "On Time Delivery %",
      dataIndex: "ontimeDelivery",
      key: "ontimeDelivery",
      width: 100,
      render: (text: any) => `${text && Number(text).toFixed(2)}%`,
    },
  ]);

  const generateColumns = (): TableColumnsType<any> => {
    const columns: TableColumnsType<any> = [
      {
        title: "Supplier Name",
        key: "supplierName",
        dataIndex: "supplierName",
        width: 250,
        fixed: "left",
      },
    ];
    console.log("monthsData", monthsData);
    monthsData?.forEach((monthEntry: any) => {
      columns.push({
        title: `${monthEntry.month} ${monthEntry.year}`,
        dataIndex: `${monthEntry.month}-${monthEntry.year}`,
        key: `${monthEntry.month}-${monthEntry.year}`,
        width: 170,
        // render: (text: any) => formatCurrency(text, "INR"),
        render: (value: any, record: any) => {
          const matchedSupplier = monthEntry.supplierData.find(
            (supplier: any) => supplier.supplierID === record.supplierID
          );
          return (
            <div style={{ padding: 4, margin: 0, textAlign: "right" }}>
              <div>
                {matchedSupplier
                  ? matchedSupplier.price +
                    ` (${matchedSupplier.costPercentage})`
                  : ""}
              </div>{" "}
              {/* Show price if matched, otherwise "-" */}
            </div>
          );
        },
      });
    });
    return columns;
  };

  const PCcolumns = generateColumns();

  return (
    <>
      <Drawer
        title="Purchase Details"
        placement="right"
        onClose={onClose}
        open={visible}
        width={"85%"}
      >
        <Tabs defaultActiveKey="plot">
          <TabPane tab={<span>Purchases By Supplier</span>} key="plot">
            {/* <Card className="card-bordered"> */}
            {/* <div className="card-aside-wrap"> */}
            <Block>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <div style={{ marginRight: 16 }}>
                  <label style={{ fontWeight: "bold", marginRight: 8 }}>
                    Filter by Site:
                  </label>
                  <Select
                    defaultValue="ALL"
                    style={{ width: 150 }}
                    onChange={handleSiteChange}
                  >
                    {siteOptions?.map((item: any, idx: any) => (
                      <Option key={"siteOption_" + idx} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div style={{ marginRight: 16 }}>
                  <label style={{ fontWeight: "bold", marginRight: 8 }}>
                    Filter by Category:
                  </label>
                  <Select
                    defaultValue="ALL"
                    style={{ width: 150 }}
                    onChange={handleCategoryChange}
                  >
                    {categoryOptions?.map((item: any, idx: any) => (
                      <Option key={"categoryOption_" + idx} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div style={{ marginRight: 16 }}>
                  <label style={{ fontWeight: "bold", marginRight: 8 }}>
                    Filter by Contractor:
                  </label>
                  <Select
                    // defaultValue="ALL"
                    style={{ width: 150 }}
                    onChange={handleContractorChange}
                  >
                    {contractorOptions?.map((item: any, idx: any) => (
                      <Option
                        key={"contractorOptions_" + idx}
                        value={item.value}
                      >
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </Block>
            <div>
              {isLoading == true ? (
                <>
                  <Flex align="center" gap="middle" className="pageLoader">
                    <Spin size="large" className="m-auto" />
                  </Flex>
                </>
              ) : originalData?.length == 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <Empty description="No Purchases Available" />
                </div>
              ) : (
                <div>
                  {filteredData?.length == 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <Empty description="No Data for selected filters" />
                    </div>
                  ) : (
                    <div>
                      <Table
                        className="customTable"
                        columns={columns.map((item: any, index) => ({
                          ...item,
                          index,
                        }))}
                        dataSource={filteredData}
                        rowKey={(record) => record.key}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: ["10", "20", "30", "50"],
                        }}
                        bordered
                        size="middle"
                        scroll={{ x: 1400 }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* </div> */}
            {/* </Card> */}
          </TabPane>
          <TabPane tab={<span>Price Comparision</span>} key="table">
            <Row>
              <Col lg-4>
              <div style={{ marginRight: 16 }}>
                  <label style={{ fontWeight: "bold", marginRight: 8 }}>
                    Filter by Category:
                  </label>
                  <Select
                    // defaultValue={categoryOptions[0]?.value}
                    style={{ width: 150 }}
                    onChange={handlePCCategoryChange}
                  >
                    {priceComparisionCategoryOptions?.map((item: any, idx: any) => (
                      <Option key={"categoryOption_" + idx} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
              <Col lg-4>
                <div style={{ marginRight: 16 }}>
                  <label style={{ fontWeight: "bold", marginRight: 8 }}>
                    Filter by Material:
                  </label>
                  <Select
                    // defaultValue={materialsOptions[0]?.value}
                    style={{ width: 150 }}
                    onChange={handleMaterialChange}
                  >
                    {materialsOptions?.map((item: any, idx: any) => (
                      <Option
                        key={"materialsOptions_" + idx}
                        value={item.value}
                      >
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Table
                className="customTable"
                columns={PCcolumns}
                dataSource={priceComparisionData}
                pagination={{
                  pageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30", "50"],
                }}
                bordered
                size="middle"
                scroll={{ x: 1400 }}
                rowKey="supplierID"
                //  rowClassName={(record, index) => (index % 2 === 0 ? "" : "striped-row")}
              />
            </Row>
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default TopPurchaseCategoriesDetailsDrawer;
