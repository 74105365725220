import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosInstance from "../axiosInstance";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

export interface ApiCallConfig {
  method: "get" | "post" | "put" | "delete" | "patch";
  url: string;
  data?: any;
  params?: Record<string, any>;
}

async function makeApiCall<T>(config: ApiCallConfig): Promise<T> {
  const accessToken = Cookies.get("access-token");
  try {
    const axiosConfig: AxiosRequestConfig = {
      method: config.method,
      url: config.url,
      data: config.data,
      params: config.params,
      headers: accessToken ? { Authorization: `${accessToken}` } : {},
    };

    const response: AxiosResponse<T> = await axiosInstance(axiosConfig);
    return response.data;
  } catch (error: any) {
    console.log("Error intercepted in makeApiCall:", error);
    if (error.message === "Token Expired") {
      Swal.fire({
        title: "Session Expired",
        text: "Re-login to continue",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      }).then(async (result) => {
        if (result.isConfirmed) {
          window.location.href = "/";
        }
      });
      await new Promise((resolve) => setTimeout(resolve, 10000));
      window.location.href = "/";
    }
    return error;
  }
}

export default makeApiCall;
