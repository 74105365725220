import React, { useState, useEffect, FC, useCallback } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
  Button,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import InventoryAside from "./InventoryAside";
import invoiceApis from "../../api/Invoice/invoice";
import ServerSideTable from "../form-components/PaginationTableComponent";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import inventoryCreation from "../../api/inventory/inventoryCreation";
import { EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import dayjs from "dayjs";
import SiteInventoryAllocationDrawer from "./SiteInventoryAllocationDrawer";
import { render } from "@testing-library/react";
import { set } from "date-fns";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

const SiteIssue: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sm, setSm] = useState(false);
  const navigate = useNavigate();
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [externalData, setExternalData] = useState(null);
  const [rowData, setRowData] = useState<any>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // Track unsaved changes
  const [showSubmitDrawer, setShowSubmitDrawer] = useState(false);
  const [site, setSite] = useState("");
  const [siteOptions, setSiteOptions] = useState<any>([]);
  const { data: currentUser } = useSelector(
    (state: RootState) => state.currentUser
  );
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [data, setData] = useState<any[]>([]);
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [editValues, setEditValues] = useState<{ [key: string]: any }>({});
  const [sortOrder, setSortOrder] = useState("ASC");
  const [direction, setDirection] = useState("P");
  const [orderNumber, setOrderNumber] = useState(null);
  const [previousPage, setPreviousPage] = useState(0);

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  useEffect(() => {
    viewChange();
    // fetchAllInvoices();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const columns = [
    {
      title: "#",
      key: "#",
      width: 50,
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Material Category",
      dataIndex: "categoryName",
      key: "categoryName",
      width: 200,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
    },
    {
      title: "UOM",
      dataIndex: "primaryUomName",
      key: "primaryUomName",
      width: 200,
    },
    {
      title: "Qty Available For Utilization",
      dataIndex: "quantityAvailable",
      key: "quantityAvailable",
      width: 220,
      render: (text: number, record: any) => (
        <div>
          <span>{text?.toLocaleString("en-IN")}</span>
        </div>
      ),
    },
    {
      title: "Qty To Issue",
      dataIndex: "uomQty",
      key: "uomQty",
      editable: true,
      width: 170,
      render: (text: number, record: any) =>
        editingKey === record.paginationId ? (
          <Input
            type="number"
            min={1} // Prevent direct typing of numbers less than 1
            value={editValues[record.paginationId]?.uomQty ?? text}
            onChange={(e) =>
              handleFieldChange(
                record.paginationId,
                "uomQty",
                e.target.value // Allow user to type freely
              )
            }
            onBlur={(e) =>
              handleFieldChange(
                record.paginationId,
                "uomQty",
                validateQuantity(e.target.value, record.quantityAvailable) // Validate on blur
              )
            }
          />
        ) : (
          record.uomQty
        ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 150,
      render: (text: any, record: any) =>
        editingKey === record.paginationId ? (
          <div>
            <Button
              type="link"
              icon={<SaveOutlined />}
              onClick={() => commitChanges(record.paginationId)}
            />
            <Button
              type="link"
              icon={<CloseOutlined />}
              onClick={() => cancelEdit()}
            />
          </div>
        ) : (
          <EditOutlined onClick={() => handleEdit(record.paginationId)} />
        ),
    },
  ];

  const validateQuantity = (value: string, maxAvailable: number): number => {
    const numericValue = Number(value);

    if (numericValue < 1) return 1; // Minimum value is 1
    if (numericValue > maxAvailable) return maxAvailable; // Clamp to max available

    return numericValue; // Return valid value
  };

  const handleSiteChange = (value: string) => {
    setSite(value);
    setPagination({ ...pagination, current: 1 }); // Reset pagination on site change
  };

  const handleEdit = (id: string) => {
    const currentItem = data.find((item: any) => item.paginationId === id);
    if (currentItem) {
      setEditValues((prev) => ({
        ...prev,
        [id]: { ...currentItem },
      }));
      setEditingKey(id);
    }
  };

  const handleFieldChange = (key: string, field: string, value: any) => {
    setEditValues((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [field]: value,
      },
    }));
  };

  const commitChanges = (id: string) => {
    const updatedData = data.map((item: any) =>
      item.paginationId === id
        ? { ...item, uomQty: editValues[id]?.uomQty || item.uomQty }
        : item
    );
    setData(updatedData);
    setEditingKey(null);
    setEditValues({});
    setHasUnsavedChanges(true);
  };

  const cancelEdit = () => {
    setEditingKey(null);
    setEditValues({});
  };

  const fetchData = async (params: any) => {
    setIsLoading(true);
    try {
      const response = await inventoryCreation.fetchSiteInventory(
        params.sortOrder,
        params.pageSize,
        params.direction,
        params.pageDiff,
        params.orderNumber,
        params.siteId
      );
      const data = response.response;
      const enrichedData = data.data.list.map((item: any) => ({
        ...item,
        uomQty: item.uomQty || "",
      }));
      setData(enrichedData);
      setPagination((prev: any) => ({
        ...prev,
        total: direction === "P" ? data.data.count : prev.total,
      }));
    } catch (error) {
      console.error("API Error:", error);
      setData([]);
      setPagination((prev: any) => ({
        ...prev,
        total: 0,
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    console.log("Submitting data:", rowData);
    setShowSubmitDrawer(true);
    // Add API call or submission logic here
    // setHasUnsavedChanges(false); // Reset the Submit button
  };

  const handelCreationSubmit = async (formValues: any) => {
    console.log("data", formValues);
    let formData = {
      header: {
        issueNumber: formValues.FormData.issueId,
        siteId: Number(formValues.FormData.siteId),
        block: Number(formValues.FormData.bloceId),
        contractorId: Number(formValues.FormData.contractorId),
        approvalRequired: formValues.FormData.approvals === "true" ? "Y" : "N",
      },
      approvals: formValues.approvalsData,
      lines: data
        .map((item: any) => {
          if (item.uomQty && item.uomQty !== "") {
            console.log("item", item);
            return {
              inventoryId: item.inventoryId,
              materialId: item.materialId,
              categoryId: item.categoryId,
              uomId: item.primaryUomId,
              uomType: "primary",
              uomQty: Number(item.uomQty),
            };
          }
          return null;
        })
        .filter((item: any) => item !== null),
    };
    console.log("formData", formData);
    // return
    try {
      const { response, message, status } =
        await inventoryCreation.createSiteIssue(formData);
      console.log("response", response);
      if (status == true) {
        Swal.fire("Success", message, "success");
        setShowSubmitDrawer(false);
        setHasUnsavedChanges(false); // Reset the Submit button
        // message.success(message);
        navigate("/material-issue");
      } else {
        message.error(message);
      } // Add API call or submission logic here
    } catch (error) {
      console.error("Failed to submit data:", error);
      // message.error("Failed to submit data");
    }
  };

  useEffect(() => {
    if (currentUser) {
      const { siteAccessibility } = currentUser;
      if (siteAccessibility) {
        const options = currentUser.siteAccessibility.map((item: any) => ({
          label: item.siteName,
          value: item.siteID,
        }));
        setSiteOptions(options);
        setSite(options[0]?.value || ""); // Set default site if available
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (site) {
      const pageDiff =
        direction === "P"
          ? pagination.current - 1
          : Math.abs(pagination.current - previousPage) - 1;

      const apiParams = {
        sortOrder,
        pageSize: pagination.pageSize.toString(),
        direction,
        pageDiff,
        orderNumber,
        siteId: site,
      };
      fetchData(apiParams);
    }
  }, [
    site,
    pagination.current,
    pagination.pageSize,
    direction,
    orderNumber,
    previousPage,
    sortOrder,
  ]);

  return (
    <>
      <Head title="Issue Material" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <InventoryAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      <span>Issue Material</span>
                    </BlockTitle>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>

                  {hasUnsavedChanges && (
                    <div className="actions clearfix">
                      <ul
                        className="m-auto d-flex align-items-center justify-content-center p-0"
                        style={{
                          flexDirection: "row-reverse",
                        }}
                      >
                        <li className="pt-0 pb-0">
                          <Button
                            type="primary"
                            onClick={handleSubmit}
                            style={{ marginTop: "20px" }}
                          >
                            Submit
                          </Button>
                        </li>
                      </ul>
                    </div>
                  )}
                </BlockBetween>
              </BlockHead>
              <Row>
                <div>
                  <div>
                    <label style={{ fontWeight: "bold", marginRight: 8 }}>
                      Filter by Site:
                    </label>
                    <Select
                      style={{ width: 200 }}
                      onChange={handleSiteChange}
                      value={site}
                    >
                      {siteOptions?.map((item: any) => (
                        <Option value={item.value} key={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </Row>
              <div>
                <div
                  style={{
                    width: screenSize - 330 + "px",
                    minHeight: "200px",
                    marginTop: "20px",
                  }}
                >
                  <Spin spinning={isLoading}>
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={{
                        ...pagination,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "30", "50"],
                        onChange: (page, pageSize) => {
                          // Update the pagination state with the new page number and page size
                          setPagination((prevPagination: any) => ({
                            ...prevPagination,
                            current: page,
                            pageSize: pageSize,
                          }));
                        },
                        onShowSizeChange: (current, size) => {
                          // This ensures that the page size is updated when the size changer is used
                          setPagination((prevPagination: any) => ({
                            ...prevPagination,
                            pageSize: size,
                            current: 1, // Reset to page 1 when page size is changed
                          }));
                        },
                      }}
                      onChange={(pagination) =>
                        setPagination({
                          ...pagination,
                          total: pagination.total,
                        })
                      }
                      rowKey="paginationId"
                      bordered
                      size="middle"
                    />
                  </Spin>
                </div>
              </div>
            </div>
          </div>
          {showSubmitDrawer && (
            <SiteInventoryAllocationDrawer
              visible={showSubmitDrawer}
              site={site}
              lines={data
                .map((item: any) => {
                  if (item.uomQty && item.uomQty !== "") {
                    console.log("item", item);
                    return item;
                  }
                  return null;
                })
                .filter((item: any) => item !== null)}
              onClose={() => setShowSubmitDrawer(false)}
              onSubmit={(data: any) => handelCreationSubmit(data)} // Ensure this is correctly passed
            />
          )}
        </Card>
      </Content>
    </>
  );
};

export default SiteIssue;
