import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";
import FormatDate from "../../masters/DateFormater";
import {Icon} from "../../../components/Component"
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";

function PaymentsReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Transaction Number",
      dataIndex: "transactionReferenceId",
      key: "transactionReferenceId",
      fixed: "left",
      width: 200,
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-details-for-payments-report/${text}`,
                {
                  state: {
                    name: record?.transactionReferenceId
                  }
                }
              );
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title:"Created BY",
      dataIndex:"createdBy",
      key:"createdBy",
      width:200
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      width: 100,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      fixed: "left",
      width: 200,
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      width: 120,
      render: (text: any) => <span style={{ textTransform: "capitalize" }}>{text}</span>,
    },
    {
      title: "Credit Utilized Amount",
      dataIndex: "creditUtilizedAmount",
      key: "creditUtilizedAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Payment Amount",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      width: 150,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Status", 
      dataIndex: "status",
      key: "status",
      width: 150,
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Payments Report"
      moduleKey="paymentsReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
        aging: false,
      }}
      previousPath="/view-accounts-payable-reports"
      extra={{
        tableScrollX: 1300,
      }}
    />
  );
}
export default PaymentsReport;
