import React, { useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {  Card} from "reactstrap";
import InvoiceAside from "./InvoiceAside";
import {  useParams } from "react-router";
import InvoiceComponent from "./InvoiceComponent";
function ViewInvoiceDetails() {

  let { id }: any = useParams();
  const [sm, setSm] = useState(false);


  return (
    <>
      <Head title="Material Invoice" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            {/* <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg 
                ${sm ? "content-active" : ""}
              `}
            >
              <InvoiceAside updateSm={setSm} sm={sm} />
            </div> */}
              <InvoiceComponent invoiceNumber={id} previousPath={"/view-invoice-list"}
              extra={{reduceTableWidth: 130}}/>
          </div>
        </Card>
      </Content>
    </>
  );
}

export default ViewInvoiceDetails;
