import React, { useEffect } from "react";
import * as echarts from "echarts";

import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";
import { Empty } from "antd";
// Overdue data structure

const OverdueAgingAnalysis: React.FC<any> = ({ overdueData }: any) => {
  useEffect(() => {
    const chartDom = document.getElementById(
      "overdue-aging-chart"
    ) as HTMLElement;
    if (chartDom) {
      const myChart = echarts.init(chartDom);

      // Chart configuration
      const option = {
        title: {
          //   text: "Overdue Aging Analysis",
          //   left: "center",
        },
        grid: {
          left: "90px",
          right: "10px",
          top: "15px",
          bottom: "60px",
        },
        tooltip: {
          trigger: "axis",
          formatter: (params: any) => {
            const { data, axisValue } = params[0];
            return `${axisValue}: ${formatCurrency(
              data,
              overdueData.currency
            )}`;
          },
        },
        xAxis: {
          type: "category",
          name: "Aging Range",
          data: Object.keys(overdueData).filter((key) => key !== "currency"), // Exclude "currency" from x-axis
          axisLabel: {
            rotate: 0,
          },
          nameLocation: "middle",
          nameTextStyle: {
            fontWeight: "bold",
            fontSize: 14,
            padding: 20,
          },
        },
        yAxis: {
          type: "value",
          name: "Overdue Amount",
          axisLabel: {
            formatter: (value: number) =>
              formatCurrency2(value, overdueData.currency),
          },
          nameLocation: "middle",
          nameTextStyle: {
            fontWeight: "bold",
            fontSize: 14,
            padding: 60,
          },
          position: "left",
        },
        series: [
          {
            data: Object.values(overdueData).filter(
              (value) => typeof value === "number"
            ), // Only use numeric values for the bars
            type: "bar",

            barWidth: "20%",
          },
        ],
      };

      myChart.setOption(option);

      // Clean up chart on component unmount
      return () => {
        myChart.dispose();
      };
    }
  }, [overdueData]);

  const TotalValue =
    overdueData?.[">45 Days"] +
    overdueData?.["31-45 Days"] +
    overdueData?.["21-30 Days"] +
    overdueData?.["11-20 Days"] +
    overdueData?.["<10 Days"];

  return (
    <div>
      <div className="card-title-group mb-1">
        <div className="card-title">
          <h6 className="title">Overdue Aging Analysis</h6>
          <p>Amount overdue by day ranges</p>
        </div>
      </div>
      <div
        id="overdue-aging-chart"
        style={{
          width: "100%",
          height: "300px",
          display: `${TotalValue ? "block" : "none"}`,
        }}
      />
      {!TotalValue && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          <Empty description="No data available to generate or display the graph." />
        </div>
      )}
    </div>
  );
};

export default OverdueAgingAnalysis;
