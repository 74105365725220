import makeApiCall from "../master/axiosIndex";

const InventoryAnalyticsAPI = {
  fetchMonthOnMonthInventoryTrend: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/inventory-dashboard/getMonthlyInventoryData",
      method: "post",
      data: data,
    }),
  fetchAverageInventoryHoldingDaysByMaterial: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/inventory-dashboard/getHoldingQuantityDetails",
      method: "post",
      data: data,
    }),
  fetchMaterialWiseInventoryPurchasedvsUtilization: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/inventory-dashboard/getMaterialHandoverDetails",
      method: "post",
      data: data,
    }),
  fetchWidgetData: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/inventory-dashboard/getInventorySummaryData",
      method: "post",
      data: data,
    }),
  fetchAverageInventoryValueByMaterial: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/inventory-dashboard/getInventoryDetails",
      method: "post",
      data: data,
    }),
};

export default InventoryAnalyticsAPI;
