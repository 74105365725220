import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Card, Col, Row } from "reactstrap";
import {
  Accordian,
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  PreviewCard,
} from "../../components/Component";

const Faq = ({ ...props }) => {
  return (
    <React.Fragment>
      <Head title="Privacy Policy" />
      <Content>
        <div className="content-page">
          <BlockHead size="lg" wide="sm">
            <BlockHeadContent>
              <BlockTitle tag="h2" className="fw-normal">
                Privacy Policy
              </BlockTitle>
              <p>
                  <strong>Effective Date:</strong> [2024]
                </p>
              <BlockDes>
                <p className="lead">
                At ProcureX, we are committed to protecting the privacy and
                  confidentiality of our users. This Privacy Policy outlines the
                  types of personal and business information we collect, how it
                  is used, shared, and safeguarded, and your rights regarding
                  your data.
                </p>
                {/* <p className="text-soft ff-italic">Last Update: Nov 12, 2019</p> */}
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>
          <Block>
            <PreviewCard className="card-bordered" bodyClass="card-inner-xl">
              <div class="container">
                {/* <h1>Privacy Policy</h1>
                <p>
                  <strong>Effective Date:</strong> [2024]
                </p> */}
                {/* <p>
                  At ProcureX, we are committed to protecting the privacy and
                  confidentiality of our users. This Privacy Policy outlines the
                  types of personal and business information we collect, how it
                  is used, shared, and safeguarded, and your rights regarding
                  your data.
                </p> */}

                <h3>1. Information We Collect</h3>
                <ul>
                  <li>
                    <strong>User Information:</strong> Name, email address,
                    phone number, and job title. Login credentials (e.g.,
                    username and password).
                  </li>
                  <li>
                    <strong>Business Information:</strong> Company name,
                    address, and contact details. Procurement-related data,
                    including purchase requests, quotations, and supplier
                    information.
                  </li>
                  <li>
                    <strong>Usage Information:</strong> Interaction logs with
                    the platform, such as page views, session duration, and
                    navigation paths. Device and browser data (e.g., IP address,
                    device type, operating system).
                  </li>
                  <li>
                    <strong>Payment Information:</strong> Billing address and
                    payment details for subscription and transactions.
                  </li>
                  <li>
                    <strong>Third-party Data:</strong> Information provided
                    through integrations with third-party tools (e.g.,
                    accounting or ERP systems).
                  </li>
                </ul>

                <h3>2. How We Use Your Information</h3>
                <p>We use your information to:</p>
                <ul>
                  <li>Provide, operate, and improve our SaaS platform.</li>
                  <li>
                    Facilitate procurement processes and enable collaboration
                    between buyers and suppliers.
                  </li>
                  <li>Process payments and manage subscriptions.</li>
                  <li>
                    Send updates, notifications, and promotional materials (with
                    your consent).
                  </li>
                  <li>
                    Ensure compliance with legal obligations and prevent
                    fraudulent activities.
                  </li>
                  <li>Provide customer support and respond to inquiries.</li>
                </ul>

                <h3>3. How We Share Your Information</h3>
                <p>
                  We do not sell your personal information. However, we may
                  share your information with:
                </p>
                <ul>
                  <li>
                    <strong>Suppliers and Buyers:</strong> To facilitate
                    procurement transactions.
                  </li>
                  <li>
                    <strong>Service Providers:</strong> Third-party vendors for
                    hosting, payment processing, and analytics.
                  </li>
                  <li>
                    <strong>Legal and Regulatory Authorities:</strong> If
                    required to comply with legal obligations.
                  </li>
                  <li>
                    <strong>Business Transfers:</strong> In case of mergers,
                    acquisitions, or asset sales, your data may be transferred.
                  </li>
                </ul>

                <h3>4. Data Security</h3>
                <p>
                  We implement industry-standard security measures to protect
                  your information, including:
                </p>
                <ul>
                  <li>Encryption of data in transit and at rest.</li>
                  <li>Regular vulnerability assessments and audits.</li>
                  <li>Restricted access to sensitive data.</li>
                </ul>
                <p>
                  However, no method of transmission or storage is completely
                  secure. By using our platform, you acknowledge and accept the
                  inherent risks.
                </p>

                <h3>5. Your Rights</h3>
                <p>You have the following rights concerning your data:</p>
                <ul>
                  <li>
                    <strong>Access:</strong> Request a copy of your data.
                  </li>
                  <li>
                    <strong>Correction:</strong> Update inaccurate or incomplete
                    information.
                  </li>
                  <li>
                    <strong>Deletion:</strong> Request the deletion of your
                    data, subject to legal and contractual requirements.
                  </li>
                  <li>
                    <strong>Restriction:</strong> Limit how we process your
                    data.
                  </li>
                  <li>
                    <strong>Portability:</strong> Request your data in a
                    structured, machine-readable format.
                  </li>
                  <li>
                    <strong>Withdraw Consent:</strong> Opt-out of marketing or
                    data-sharing agreements.
                  </li>
                </ul>
                <p>
                  To exercise these rights, contact us at{" "}
                  <a href="mailto:info@procurextech.com">
                    info@procurextech.com
                  </a>
                  .
                </p>

                <h3>6. Data Retention</h3>
                <p>
                  We retain your information for as long as necessary to fulfill
                  the purposes outlined in this policy or as required by law.
                </p>

                <h3>7. Third-Party Links</h3>
                <p>
                  Our platform may contain links to third-party websites. We are
                  not responsible for their privacy practices. We encourage you
                  to review their privacy policies before sharing your
                  information.
                </p>

                <h3>8. Cookies and Tracking Technologies</h3>
                <p>We use cookies and similar technologies to:</p>
                <ul>
                  <li>Enhance user experience.</li>
                  <li>Analyze platform usage.</li>
                  <li>Personalize content and advertisements.</li>
                </ul>
                <p>
                  You can manage cookie preferences through your browser
                  settings.
                </p>

                <h3>9. International Data Transfers</h3>
                <p>
                  If you are located outside India, your information may be
                  transferred to and processed in India, specifically in
                  Telangana, where our operations are based. Please note that
                  data protection laws in India may differ from the laws in your
                  country. By using our platform, you consent to such transfers,
                  processing, and storage of your data in accordance with this
                  Privacy Policy.
                </p>

                <h3>10. Updates to This Privacy Policy</h3>
                <p>
                  We may update this policy from time to time. Significant
                  changes will be notified through email or platform
                  notifications. The updated policy will include a revised
                  "Effective Date."
                </p>

                <h3>11. Contact Us</h3>
                <p>
                  If you have any questions or concerns about this Privacy
                  Policy, please contact us at:
                </p>
                <p>
                  <strong>Email:</strong>{" "}
                  <a href="mailto:info@procurextech.com">
                    info@procurextech.com]
                  </a>
                  <br />
                  <strong>Address:</strong> Sy.No.237,H No.4-49/2
                  Vattinagulapally,Gandipet Gachibowli K.V.Rangareddy Telangana
                  500032
                </p>

                <p>
                  Your trust is our priority. We are committed to safeguarding
                  your data while delivering the best procurement experience
                  through our platform.
                </p>
              </div>
            </PreviewCard>
          </Block>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Faq;
