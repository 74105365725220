import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler, useFieldArray, FormProvider, Controller } from "react-hook-form";
import { Row, Col, Button, FormGroup, Label, Spinner } from "reactstrap";
import { Drawer, Switch } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import FormInput from "../../form-components/InputComponent";

interface DocumentFormValues {
  documents: {
    documentName: string;
    description: string;
    mandatory: any;
  }[];
}

interface AskDocumentsProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: DocumentFormValues) => void;
  documentData: DocumentFormValues; // New prop for current document data
}

const AskDocuments: React.FC<AskDocumentsProps> = ({ open, onClose, onSubmit, documentData }) => {
  console.log("documentData",documentData)
  const methods = useForm<DocumentFormValues>({
    defaultValues: {
      documents: documentData.documents || [{ documentName: "", description: "", mandatory: false }]
    }
  });

  const { control, handleSubmit, formState: { errors } } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "documents"
  });

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Simulate data loading
    setTimeout(() => {
      setLoading(false); // Set loading to false once data is simulated to be loaded
    }, 1000);
  }, []);

  const addNewDocument = () => {
    if (fields.length < 10) {
      append({ documentName: "", description: "", mandatory: false });
    }
  };

  const submitDocuments: SubmitHandler<DocumentFormValues> = (data) => {
    const transformedData = {
      ...data,
      documents: data.documents.map(doc => ({
        ...doc,
        mandatory: doc.mandatory ? 1 : 0
      }))
    };
    onSubmit(transformedData);
  };

  return (
    <Drawer
      title="Required Documents from Supplier"
      placement="right"
      onClose={onClose}
      open={open}
      size="large"
    >
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Spinner color="primary" />
        </div>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitDocuments)}>
            {fields.map((field, index) => (
              <Row className="mt-2" key={field.id}>
                <Col lg="4">
                  <FormInput
                    name={`documents.${index}.documentName`}
                    label="Name of the document"
                    required={true}
                  />
                </Col>
                <Col lg="4">
                  <FormInput
                    name={`documents.${index}.description`}
                    label="Description"
                    required={true}
                  />
                </Col>
                <Col lg="2">
                  <FormGroup>
                    <Label>Mandatory</Label>
                    <Controller
                      control={control}
                      name={`documents.${index}.mandatory`}
                      render={({ field }) => (
                        <Switch
                          checked={field.value}
                          onChange={(checked) => field.onChange(checked)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col lg="2" className="d-flex align-items-center">
                  <a onClick={() => remove(index)} className="danger" style={{ fontSize: 16, color: 'red', marginTop:14,cursor:"pointer" }}>
                    <MinusCircleOutlined /> Remove
                  </a>
                </Col>
              </Row>
            ))}
            <Row className="mt-2">
              <Col lg="12">
                <a onClick={addNewDocument} className="primary" style={{ fontSize: 16,cursor:"pointer" }}>
                  <PlusCircleOutlined /> Add More
                </a>
              </Col>
            </Row>
            <Button type="submit" color="primary" className="mt-3">
              Save
            </Button>
          </form>
        </FormProvider>
      )}
    </Drawer>
  );
};

export default AskDocuments;
