import React, { FC, useEffect, useState } from "react";
import * as echarts from "echarts";
import { Empty } from "antd";

const MonthOnMonthInventoryTrend: FC<any> = ({ monthlyInventory }) => {
  const [chartData, setChartData] = useState<any>([]);

  useEffect(() => {
    console.log("monthlyInventoryData", monthlyInventory);
    if (monthlyInventory.length != 0) {
      const data = monthlyInventory;
      const months = data.map((item: any) => item.month_name);
      const purchaseValues = data.map((item: any) =>
        parseFloat(item.total_inward_price)
      );
      const utilizedValues = data.map((item: any) =>
        parseFloat(item.total_handover_price)
      );
      const utilizationPercentages = data.map((item: any) =>
        parseFloat(item.handover_percentage)
      );

      setChartData({
        months,
        purchaseValues,
        utilizedValues,
        utilizationPercentages,
      });
    }
  }, []);

  useEffect(() => {
    if (chartData.months) {
      const chartDom = document.getElementById(
        "month-on-month-inventory-trend-chart"
      ) as HTMLElement;
      if (chartDom) {
        const myChart = echarts.init(chartDom);

        const option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["Purchase Value", "Utilized Value", "Utilized %"],
            top: 10,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: chartData.months, // Use mapped months
          },
          yAxis: [
            {
              type: "value",
              name: "Inventory Value",
              axisLabel: {
                formatter: "₹{value}",
              },
            },
            {
              type: "value",
              name: "Utilized %",
              axisLabel: {
                formatter: "{value} %",
              },
            },
          ],
          series: [
            {
              name: "Purchase Value",
              type: "bar",
              data: chartData.purchaseValues, // Use mapped purchase values
              itemStyle: {
                color: "#73c0de",
              },
            },
            {
              name: "Utilized Value",
              type: "bar",
              data: chartData.utilizedValues, // Use mapped utilized values
              itemStyle: {
                color: "#5470c6",
              },
            },
            {
              name: "Utilized %",
              type: "line",
              yAxisIndex: 1, // Use the secondary y-axis
              data: chartData.utilizationPercentages, // Use mapped percentages
              itemStyle: {
                color: "#91cc75",
              },
              smooth: true, // Smooth line
            },
          ],
        };

        myChart.setOption(option);

        return () => {
          myChart.dispose();
        };
      }
    }
  }, [chartData]);
  const showGraph =
    chartData?.utilizedValues?.reduce(
      (prev: any, curr: any) => prev + curr,
      0
    ) +
    chartData?.utilizationPercentages?.reduce(
      (prev: any, curr: any) => prev + curr,
      0
    );
  return (
    <div>
      <div
        className="card-title-group mb-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="card-title">
          <h6 className="title">Month on Month Inventory Utilization Trend</h6>
          <p>Analysis of Inventory for the last 12 months.</p>
        </div>
      </div>
      <div
        id="month-on-month-inventory-trend-chart"
        style={{ width: "100%", height: "400px",
            display: `${showGraph > 0 ? "block" : "none"}`,
         }}
      />
      {showGraph === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          <Empty description="No data available to generate or display the graph." />
        </div>
      )}
    </div>
  );
};

export default MonthOnMonthInventoryTrend;
