import makeApiCall from "../master/axiosIndex";
const boqApis = {
  fetchConsumptionTarget: (
    sortOrder: any,
    pageSize: any,
    direction: any,
    pageDiff: any,
    paginationId: any
  ): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/inventory/consumptionTarget/list",
      params: { sortOrder, pageSize, direction, pageDiff, paginationId },
    }),
  newConsumptionTarget: (data: any): Promise<any> =>
    makeApiCall({
      url: "/inventory/consumptionTarget/new",
      method: "post",
      data,
    }),
  fetchConsumptionTargetMaterialDeviationsList: ({
    sortOrder,
    pageSize,
    direction,
    pageDiff,
    paginationId,
    deviation,
    siteIds,
    blockIds,
  }: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/inventory/consumptionTargetMaterialDeviationsList",
      params: {
        sortOrder,
        pageSize,
        direction,
        pageDiff,
        paginationId,
        deviation,
        siteIds,
        blockIds,
      },
    }),
};

export default boqApis;
