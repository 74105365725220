import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../layout/head/Head";
import { Block } from "../../components/Component";
import QuotationsAside from "./QuotationsAside";
import { Steps, Step } from "react-step-builder";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import RFQHeader from "./newQuotations/RFQHeader";
import RFQGeneralDetails from "./newQuotations/RFQGeneralDetails";
import RFQMaterials from "./newQuotations/RFQMaterials";
import RFQOthers from "./newQuotations/RFQOthers";
import RFQPreview from "./newQuotations/RFQPreview";
import dayjs from "dayjs";
const config = {
  before: RFQHeader as React.ComponentType<{}> as () => React.ReactElement,
};

const RFQCreation: FC = () => {
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const formMethods = useForm({
    mode: "onChange",
  });
  const { watch, register, reset, setValue } = formMethods;

  const location = useLocation();
  const convertionData = location.state?.data;

  console.log("convertionData", convertionData);

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };


  useEffect(() => {
    try {
      const _data = convertionData;
      if (!_data) {
        return;
      }
      let {
        deliveryAddressId,
        prId,
        deliveryLevelType,
        deliveryDateType,
        siteId,
        deliveryDate,
        contractorId
      }: any = _data?.header;

    const lines = _data?.lines;

      // if (deliveryDateType === "ORDER_LEVEL") {
      //   deliveryDateType = "Order Level"
      // }

      setValue('prId', prId);
      setValue('siteId', siteId.toString());
      setValue('deliveryAddressId', deliveryAddressId);
      setValue('deliveryLevelType', deliveryLevelType);
      setValue('needByDateType', deliveryDateType);
      setValue('needByDate', dayjs(deliveryDate));
      setValue('contractorId',contractorId?.toString())

      if (lines)
        setValue(
          "items",
          lines?.map((item: any) => {
            let _item: any = {
              ...item,
          key: item.prLineId,
          materialLabel: item.materialName,
          materialName: item.materialId,
          materialCategory: item.categoryId,
          categoryLabel: item.categoryName,
          uomId: item.uom,
          uomLabel: item.uomName,
          qty: item.quantity,
          specification:item.specification,
          prLineId: item.prLineId,
        }
        if (item.needByDate) _item.needByDate = dayjs(item.needByDate);
        return _item;
      })
      );
     } catch (error) {
      console.log(error);
    }
  }, []);


  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document.getElementsByClassName("nk-header")[0]?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document.getElementsByClassName("nk-header")[0]?.removeEventListener("click", headerClick);
    };
  }, []);

  return (
    <>
      <Head title="RFQ - Create New" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""}`}
            >
              <QuotationsAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => setSm(!sm)}></div>}
              <Block>
                <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                  <FormProvider {...formMethods}>
                    <Steps config={config}>
                      <Step component={RFQGeneralDetails} />
                      <Step component={RFQMaterials} />
                      <Step component={RFQOthers} />
                      <Step component={RFQPreview} />
                    </Steps>
                  </FormProvider>
                </div>
              </Block>
            </div>
          </div>
        </Card>
      </Content >
    </>
  );
};

export default RFQCreation;
