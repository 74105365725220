import React from "react";
import ReportTemplate2 from "../reportTemplate2";
import dayjs from "dayjs";
import { useNavigate } from 'react-router';

function InventorySummaryReport() {
  const navigate = useNavigate();
  const columns: any = [
    // {
    //   title: "Material ID",
    //   key: "materialId",
    //   dataIndex: "materialId",
    //   width: 100,
    // },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 250,
    },
    {
      title: "Category",
      key: "categoryName",
      dataIndex: "categoryName",
      width: 250,
    },
    {
      title: "Material",
      key: "materialName",
      dataIndex: "materialName",
      width: 100,
    },
    {
      title: "UOM",
      key: "primaryUomName",
      dataIndex: "primaryUomName",
      width: 100,
    },
    {
      title: "Operations Start Date",
      key: "operationStartDate",
      dataIndex: "operationStartDate",
      width: 200,
      render: (text: any, record: any) => (
        <div>
          <span>{dayjs(text).format("DD-MMM-YYYY")}</span>
        </div>
      ),
    },
    {
      title: "Opening Qty",
      key: "openingStock",
      dataIndex: "openingStock",
      width: 100,
    },
    {
      title: "Qty Ordered",
      key: "quantityOrdered",
      dataIndex: "quantityOrdered",
      width: 150,
    },
    {
      title: "Qty IN",
      key: "quantityInward",
      dataIndex: "quantityInward",
      width: 100,
    },
    {
      title: "Qty OUT",
      key: "quantityHandovered",
      dataIndex: "quantityHandovered",
      width: 100,
    },
    {
      title: "Qty On Hand",
      key: "quantityOnHand",
      dataIndex: "quantityOnHand",
      width: 150,
    },
    {
      title: "Qty Committed",
      key: "quantityCommitted",
      dataIndex: "quantityCommitted",
      width: 150,
      render(text: any, record: any) {
        return (
          <span
            className={text > 0 ? "inlineTableCellLink" : ""} 
            onClick={() => {
              navigate(`/inventory-committed-stock-report-list/${record.inventoryId}/${record.historyId}`);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Available for Utilization",
      key: "quantityAvailableToIssue",
      dataIndex: "quantityAvailableToIssue",
      width: 250,
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Inventory Summary Report"
      moduleKey="InventorySummaryReport"
      columns={columns}
      filtersOption={{
        site: true,
        stockAvailability: true,
        date:true,
        dateRange: false,
      }}
      previousPath="/view-inventory-reports"
    />
  );
}

export default InventorySummaryReport;
