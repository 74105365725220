enum AllowedFileTypes {
    PDF = "application/pdf",
    JPEG = "image/jpeg",
    PNG = "image/png",
    GIF = "image/gif",
    MSWORD = "application/msword",
    XLSX = "application/xlsx",
    DOCX = "application/docx",
    HBS = "application/hbs",
    HTML = "application/html",
    SPREED_OPENXML = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    WORD_OPENXML = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  }

  export { AllowedFileTypes };