import React, { useState, useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, UserAvatar } from "../../../components/Component"; // Assuming these are properly typed elsewhere
import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface StockAvailabilityAsideProps {
  updateSm: (value: boolean) => void; // Function to update state
  sm: boolean; // Boolean state
}

const StockAvailabilityAside: FC<StockAvailabilityAsideProps> = ({ updateSm, sm }) => {
  const location = useLocation();
  const { data: allowedRoutes }: any = useSelector(
    (state: RootState) => state.allowedRoutes
  );
  useEffect(() => {
    sm
      ? document.body.classList.add("toggle-shown")
      : document.body.classList.remove("toggle-shown");
  }, [sm]);

  const isActive = (path: string): string => {
    return location.pathname === path ? "active" : "";
  };

  const LinkTo = ({ path }: { path: string }) => {
    if (!allowedRoutes.includes(path)) {
      return null;
    }
    switch (path) {
      case "stock-availability":
        return (
          <Link
            to={"/stock-availability"}
            className={isActive(`${process.env.PUBLIC_URL}/stock-availability`)}
          >
            <Icon name="growth-fill" />
            <span>Stock Availability Report</span>
          </Link>
        );
      default:
        return null;
    }
  };

  return (
    <div className="card-inner-group">
      <div className="card-inner">
        <div className="user-account-info py-0">
          <h6 className="overline-title-alt" style={{ fontSize: 14 }}>
          Stock Availability
          </h6>
        </div>
      </div>
      <div className="card-inner p-0">
        <ul className="link-list-menu">
          {[
            "stock-availability",
          ].map((item) => (
            <li key={item} onClick={() => updateSm(false)}>
              <LinkTo path={item} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StockAvailabilityAside;
