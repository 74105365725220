import React from "react";
import EnglishFlag from "../../images/flags/english.png";
import SpanishFlag from "../../images/flags/spanish.png";
import FrenchFlag from "../../images/flags/french.png";
import TurkeyFlag from "../../images/flags/turkey.png";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

const Footer = () => {
  return (
    <div className="nk-footer bg-white">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            {" "}
            &copy; 2024, ALL Rights Reserved,{" "}
            <a target="_blank" href="https://procurextech.com">
              ProcureX Technologies PVT LTD
            </a>
          </div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item">
                <Link
                  to={`${process.env.PUBLIC_URL}/terms-policy`}
                  className="nav-link"
                >
                  Terms
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`${process.env.PUBLIC_URL}/privacy`}
                  className="nav-link"
                >
                  Privacy
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="https://support.procurex.app/open.php"
                  className="nav-link"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent React Router's default behavior
                    window.open(
                      "https://support.procurex.app/open.php",
                      "_blank",
                      "noopener,noreferrer"
                    ); // Open in new tab
                  }}
                >
                  Help
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
