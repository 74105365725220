import React from "react";
import { Card, Empty, Tooltip } from "antd";
import {
  FileTextOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  FileProtectOutlined,
  FileDoneOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import { QuotationAnalyticsProps } from "./interfaces";
import * as Icons from "@ant-design/icons";
const data = [
  {
    parameter: "totalRFQsGenerated",
    name: "Total RFQs Generated",
    count: 50,
    icon: <FileDoneOutlined />,
    percentage: false,
  },
  {
    parameter: "totalQuotesReceived",
    name: "Total Quotes Received",
    count: 200,
    icon: <FileTextOutlined />,
    percentage: false,
  },
  {
    parameter: "averageQuotesPerRequest",
    name: "Average Quotes Per Request",
    count: 4,
    icon: <CheckCircleOutlined />,
    percentage: false,
  },
  {
    parameter: "averageTimeToReceiveQuote",
    name: "Average Time to Receive a Quote",
    count: "1 Day",
    icon: <ClockCircleOutlined />,
    percentage: false,
  },
  {
    parameter: "avgCostSavings",
    name: "Average Cost Savings",
    count: 8.3,
    icon: <RiseOutlined />,
    percentage: true,
  },
  {
    parameter: "totalAwardedSuppliers",
    name: "Total Awarded Suppliers",
    count: 30,
    icon: <FileProtectOutlined />,
    percentage: false,
  },
];

const renderIcon = (iconName: string) => {
  // Log the raw iconName for debugging
  console.log("Raw iconName:", iconName);

  // Clean the icon name string by removing '<', '/', and '>'
  const cleanedIconName = iconName.replace(/[<>/]/g, "").trim();

  // Log the cleaned iconName for debugging
  console.log("Cleaned iconName:", cleanedIconName);

  // Ensure cleanedIconName is treated as a string key in Icons
  const IconComponent: any = Icons[cleanedIconName as keyof typeof Icons];

  // Log the IconComponent for debugging
  console.log("IconComponent:", IconComponent);

  // Return the icon component if it exists, otherwise return null
  return IconComponent ? <IconComponent /> : null;
};

const QuotationSummaryAnalytics: React.FC<any> = ({ quotationSummaryData }) => {
  console.log("quotationSummaryData?.quotaitonSummary", quotationSummaryData);
  return (
    <>
      <div
        className="card-title-group mb-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="card-title">
          <h6 className="title">Quotation Summary</h6>
          <p>Comprehensive Overview of quotations to conversions</p>
        </div>
      </div>
      {quotationSummaryData?.quotaitonSummary ? (
        <div>
          {quotationSummaryData?.quotaitonSummary?.map(
            (item: any, index: any) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: index % 2 === 0 ? "#f1f1f1" : "#ffffff",
                  padding: 8,
                }}
              >
                <div
                  style={{
                    fontSize: 22,
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#333",
                  }}
                >
                  {renderIcon(item.icon)}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                    flex: 1,
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    {item.name}
                  </span>
                  <div
                    style={{
                      flex: 1,
                      borderBottom: "1px solid #dbdfea",
                      marginRight: 10,
                    }}
                  ></div>
                  <span style={{ fontSize: "20px" }}>
                    {item.name === "Avg Time to Receive a Quote" ? (
                      <Tooltip title={item.count}>
                        <div
                          style={{
                            // position: "absolute",
                            // top: "-24px",
                            width: "100%",
                            // fontSize: "12px",
                            // color: "#888",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "80px", // Adjust max width as needed
                            // textAlign: "center",
                          }}
                        >
                          {item.count}
                        </div>
                      </Tooltip>
                    ) : (
                      <>{item.count}</>
                    )}
                    {item.percentage && <span>%</span>}
                  </span>
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          <Empty description="No Data found for the selected category" />
        </div>
      )}
    </>
  );
};

export default QuotationSummaryAnalytics;
