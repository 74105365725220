import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody, Form, Row, Alert, Col, Button } from "reactstrap";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import FormInput from "../form-components/InputComponent";
import { Icon, TooltipComponent } from "../../components/Component";
import FormSelect from "../form-components/SelectComponent";
import FormDatePicker from "../form-components/DatePicker";
import FormTextArea from "../form-components/TextAreaComponent";
import AntSelect from "../form-components/AntSelectComponent";
import { Popover } from "antd";
import adminOrgApis from "../../api/master/adminOrg";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import misc from "../../api/misc/dropDowns";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import moment from "moment";
import materialsApis from "../../api/master/materials";
import supplierApis from "../../api/master/supplier";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

interface SelectOptions {
  label: string;
  value: any;
}
interface bomLine {
  categoryId: string;
  categoryName: string;
  materialId: string;
  materialName: string;
  quantity: string;
  uomId: string;
  uomName: string;
  allocated: string;
}

interface FormData {
  budgetLineName: string;
  budgetValue: string;
  startDate: any;
  endDate: any;
  bom: string;
  allocation: string;
  lineDescription: string;
  currencyCode: string;
  bomLines: bomLine[];
}
const MaterialModal: FC<any> = ({
  modal,
  closeModal,
  appenddata,
  update,
  formData,
  status,
  budgetStartDate,
  budgetEndDate
}) => {
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);
  const [materialOptions, setMaterialOptions] = useState<SelectOptions[]>([]);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);

  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
    SelectOptions[]
  >([]);

  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
    setValue,
  } = methods;

  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "bomLines",
  });

  const startDateWatch = watch("startDate");

  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });

  const billOfMaterialWatch = watch("bom");

  const addNewDocument = () => {
    // if (fields.length < 10) {
    append({
      categoryId: "",
      categoryName: "",
      materialId: "",
      materialName: "",
      uomId: "",
      uomName: "",
      quantity: "",
      allocated: "",
    });
    // }
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  useEffect(() => {
    if (modal && formData.item) {
      console.log("formData.item.allocation", formData.item.allocation)
      reset({
        budgetLineName: formData.item.budgetLineName,
        budgetValue: formData.item.budgetValue
          ? formData.item.budgetValue
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Add commas for the last three digits
            .replace(/(\d)(?=(\d{2})+(?=\d))/g, "$1,") // Group in pairs for Indian numbering
          : "",
        startDate: dayjs(formData.item.startDate),
        endDate: dayjs(formData.item.endDate),
        bom: formData.item.bom,
        currencyCode: formData.item.currencyCode,
        allocation: formData.item.allocation,
        lineDescription: formData.item.lineDescription,
        bomLines: formData.item.bomLines.map((bomLine: any) => ({
          ...bomLine,
          allocated: formData.item.allocation == "value" ? (bomLine.allocated ? bomLine.allocated.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "") : bomLine.allocated,
          quantity: bomLine.quantity ? bomLine.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "", // Format the quantity with commas
        })),
      });
    } else {
      reset({
        budgetLineName: "",
        budgetValue: "",
        startDate: "",
        endDate: "",
        bom: "",
        allocation: "",
        lineDescription: "",
        currencyCode: "",
        bomLines: [],
      });
    }
  }, [formData.item, modal, reset]);

  //   useEffect(() => {
  //     if (billOfMaterialWatch === "Y" && fields.length === 0) {
  //       append({
  //         categoryId: "",
  //         categoryName: "",
  //         materialId: "",
  //         materialName: "",
  //         quantity: "",
  //         allocated: "",
  //       });
  //     }
  //   }, [billOfMaterialWatch, append, fields.length]);

  const fetchCategorys = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const categoryOption = data.map((item: any) => ({
          label: item.category,
          value: item.id.toString(),
        }));
        setCategoryOptions(categoryOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMaterials = async () => {
    try {
      const { data, status } = await materialsApis.fetchProduct();
      if (status) {
        setMaterialCategoryOptions(data);
        const materialOption = data.map((item: any) => ({
          label: item.productName,
          value: item.materialID.toString(),
        }));
        setMaterialOptions(materialOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryChange = (index: number, value: string) => {
    const selectedCategory = categoryOptions.find(
      (option) => option.value === value
    );
    if (selectedCategory) {
      setValue(`bomLines.${index}.categoryId`, value);
      setValue(`bomLines.${index}.categoryName`, selectedCategory.label);
    }
  };

  const handleMaterialChange = (index: number, value: string) => {
    const selectedMaterial = materialOptions.find(
      (option) => option.value === value
    );
    if (selectedMaterial) {
      setValue(`bomLines.${index}.materialId`, value);
      setValue(`bomLines.${index}.materialName`, selectedMaterial.label);
    }
  };

  const handleUOMChange = (index: number, value: string) => {
    const selectedUom = uomOptions.find(
      (option) => option.value === value
    );
    if (selectedUom) {
      setValue(`bomLines.${index}.uomId`, value);
      setValue(`bomLines.${index}.uomName`, selectedUom.label);
    }
  }



  const fetchUOMOPtions = async () => {
    try {
      const { data, status, message } = await adminOrgApis.fetchUom();
      if (status) {
        setUomOptions(
          data.map((item: any) => {
            return {
              label: item.uom_name,
              value: item.id.toString(),
            };
          })
        );
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    fetchMaterials();
    fetchCategorys();
    fetchUOMOPtions();
  }, []);

  const submitForm = (dataForm: any) => {
    console.log("formData", formData);

    // Log the incoming data for debugging
    console.log("dataForm", dataForm);
    const totalAllocated = dataForm.bomLines.reduce((sum: any, item: any) => {
      return sum + Number(item.allocated || "0");
    }, 0);
    const budgetValue = Number(dataForm.budgetValue);
    if (dataForm.allocation === "value") {
      if (dataForm.bom === "Y" && totalAllocated > budgetValue) {
        // Show validation message if allocated value exceeds budget
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Total Allocated value in BOM Lines cannot exceed the Budget Value.",
        });
        return;
      }
    } else if (dataForm.allocation === "percentage") {
      const totalPercentage = dataForm.bomLines.reduce((sum:any, item:any) => {
        return sum + parseFloat(item.allocated || "0");
      }, 0);
      if (dataForm.bom === "Y" && totalPercentage > 100) {
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Total Allocated % in BOM Lines cannot exceed the more than 100%.",
        });
        return;
      }
    }
    // Extract the existing data for the current item being edited
    const existingData = formData.item || {};

    // Merge existing data with the updated form data
    const updatedData = {
      ...existingData,
      ...dataForm,
    };

    if(updatedData.bom === "N") {
      updatedData.bomLines = [];
      delete updatedData.allocation;
    }
    delete updatedData.boms;
    // updatedData.boms = updatedData.bomLines;
    // delete updatedData.bomLines;
    console.log("Updated Data", updatedData);
    if (updatedData.bomLines.length > 0) {
      updatedData.bomLines = updatedData.bomLines.map((bom: any) => {
        bom.quantity = bom.quantity;
        bom.allocated = bom.allocated;
        bom.uomId = Number(bom.uomId);
        return bom;
      });
    }

    updatedData.budgetValue = updatedData.budgetValue;

    updatedData.startDate = dayjs(updatedData.startDate).toDate();
    updatedData.endDate = dayjs(updatedData.endDate).toDate();
    updatedData.currencyCode = "INR"

    console.log("Updated Data", updatedData);
    console.log("Updated Data", formData.action, formData.index);

    if (formData.action == "add" || !formData.index == null) {
      console.log("iffff")
      // Append the new line if it's a new entry
      appenddata({
        ...updatedData,
        lineId: null,
      });
    } else {
      console.log("|| formData.index == null", formData.index)
      // Update the existing line with the merged data
      update(formData.index, {
        ...updatedData,
        lineId: formData?.item?.lineId,
      });
    }

    // Close the modal after the update
    closeModal();
  };

  return (
    <Modal isOpen={modal} toggle={closeModal} size="xl">
      <ModalBody>
        <h5 className="title">
          {formData.action == "add" || formData.index == null
            ? "Add New Line"
            : "Update Line"}
        </h5>
        <div style={{ marginTop: "15px" }}></div>
        {alertInfo.message && (
          <div className="mb-3 mt-1">
            <Alert
              color={alertInfo.type === "error" ? "danger" : "success"}
              className="alert-icon"
            >
              <strong>{alertInfo.message}</strong>
              <Icon
                name={
                  alertInfo.type === "error" ? "alert-circle" : "check-circle"
                }
              />
              {alertInfo.errors.length > 0 ? (
                <ul>
                  {alertInfo.errors.map((err: any, index: any) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </Alert>
          </div>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitForm)}>
            <Row className="gy-4 mt-2">
              <Col lg="6">
                <FormInput
                  name="budgetLineName"
                  label="Budget Line Name"
                  required={true}
                  placeholder="Enter Budget Line Name"
                />
              </Col>

              <Col lg="6">
                <FormInput
                  min={1}
                  type="text" // Use 'text' type to allow formatting like 1,000
                  name="budgetValue"
                  label="Budget Value"
                  required={true}
                  placeholder="Enter Budget Value"
                  onInput={(e: any) => {
                    // Parse input to remove non-numeric characters
                    const parsedValue = e.target.value.replace(/\D/g, ''); // Remove all non-digits

                    // Format with Indian numbering system
                    const formattedValue = parsedValue
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")  // Add commas for the last three digits
                      .replace(/(\d)(?=(\d{2})+(?=\d))/g, "$1,"); // Group in pairs for Indian numbering

                    // Update input value
                    e.target.value = formattedValue;
                  }}
                />
              </Col>
              <Col lg="6">
                <FormDatePicker
                  name="startDate"
                  label="Planned Start Date"
                  placeholder="Select Start Date"
                  required={true}
                  minDate={new Date(budgetStartDate)}
                  maxDate={new Date(budgetEndDate)}
                />
              </Col>
              <Col lg="6">
                <FormDatePicker
                  name="endDate"
                  label="Planned End Date"
                  placeholder="Select End Date"
                  required={true}
                  minDate={startDateWatch}
                  maxDate={new Date(budgetEndDate)}
                />
              </Col>
              <Col lg="6">
                <FormSelect
                  name="bom"
                  label="Bill Of Material (BOM)"
                  options={[
                    { label: "Yes", value: "Y" },
                    { label: "No", value: "N" },
                  ]}
                  required={true}
                  placeholder="Select BOM"
                  onChange={(e) => {
                    console.log("e.target.value", e);
                    if (e === "Y" && fields.length === 0) {
                      append({
                        categoryId: "",
                        categoryName: "",
                        materialId: "",
                        materialName: "",
                        uomId: "",
                        uomName: "",
                        quantity: "",
                        allocated: "",
                      });
                    } else if (e === "N") {
                      reset({
                        ...methods.getValues(),
                        bomLines: [],
                      });
                    }
                  }}
                />
              </Col>
              {billOfMaterialWatch === "Y" && (
                <Col lg="6">
                  <FormSelect
                   disabled={(formData.action !== "add" && formData.item.allocation)}
                    name="allocation"
                    label="BOM Allocation"
                    options={[
                      { label: "Value", value: "value" },
                      { label: "%", value: "percentage" },
                    ]}
                    required={true}
                    placeholder="Select BOM Allocation"
                  />
                </Col>
              )}
              <Col lg="12">
                <FormTextArea
                  name="lineDescription"
                  label="Budget Line Description"
                  required={true}
                  placeholder="Enter Budget Line Description"
                />
              </Col>
            </Row>

            {billOfMaterialWatch === "Y" && (
              <Row className="gy-4 mt-2">
                {fields.map((field, index) => {
                  const materialCategory = watch(`bomLines.${index}.categoryId`);
                  return (
                    <Row className="mt-2" key={field.id}>
                      <Col lg="2">
                        <FormSelect
                          name={`bomLines.${index}.categoryId`}
                          label="Category"
                          options={categoryOptions}
                          required={true}
                          onChange={(value) => handleCategoryChange(index, value)}
                        />
                      </Col>
                      <Col lg="2">
                        <FormSelect
                          name={`bomLines.${index}.materialId`}
                          label="Material Name"
                          options={materialCategoryOptions?.filter(
                            (item: any) => item.category == materialCategory
                          )?.map((item: any) => ({
                            label: item.productName,
                            value: item.materialID,
                          }))}
                          required={true}
                          onChange={(value) => handleMaterialChange(index, value)}
                        />
                      </Col>
                      <Col lg="2">
                        <FormInput
                          min={1}
                          name={`bomLines.${index}.quantity`}
                          label="Qty"
                          type="text"
                          required={true}
                          onInput={(e: any) => {
                            let value = e.target.value.replace(/,/g, ""); // Remove commas for calculation
                            if (value) {
                              const formattedValue = parseFloat(value).toLocaleString("en-US"); // Format the value with commas
                              e.target.value = formattedValue;
                            }
                          }}
                        />
                      </Col>
                      <Col lg="2">
                        <FormSelect
                          name={`bomLines.${index}.uomId`}
                          label="UOM"
                          options={uomOptions}
                          required={true}
                          onChange={(value) =>
                            handleUOMChange(index, value)
                          }
                        />
                      </Col>
                      <Col lg="2">
                        <FormInput
                          min={1}
                          type="text"
                          name={`bomLines.${index}.allocated`}
                          label="Allocation"
                          required={true}
                          onInput={(e: any) => {
                            let value = e.target.value.replace(/,/g, ""); // Remove commas for calculation
                            if (value) {
                              const formattedValue = parseFloat(value).toLocaleString("en-US"); // Format the value with commas
                              e.target.value = formattedValue;
                            }
                          }}
                        />
                      </Col>

                      <Col lg="2" className="d-flex align-items-center">
                        <a
                          onClick={() => remove(index)}
                          className="danger"
                          style={{ fontSize: 16, color: "red", marginTop: 14,cursor:"pointer" }}
                        >
                          <MinusCircleOutlined /> Remove
                        </a>
                      </Col>
                    </Row>
                  )
                }
                )}
                <Row className="mt-2">
                  <Col lg="12">
                    <a
                      onClick={addNewDocument}
                      className="primary"
                      style={{ fontSize: 16,cursor:"pointer" }}
                    >
                      <PlusCircleOutlined /> Add More
                    </a>
                  </Col>
                </Row>
              </Row>
            )}
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  {formData.action === "edit" && status !== "Rejected" && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        Update
                      </Button>
                    </li>
                  )}
                  {formData.action == "add" && status !== "Rejected" && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        Save
                      </Button>
                    </li>
                  )}
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default MaterialModal;