import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, Row } from "reactstrap";
import FormSelect from "../../form-components/SelectComponent";
import FormInput from "../../form-components/InputComponent";
import sites from "../../../api/master/sites";
import supplierApis from "../../../api/master/supplier";
import materialsApis from "../../../api/master/materials";
import misc from "../../../api/misc/dropDowns";
import { line } from "d3";
interface SelectOptions {
  label: string;
  value: string;
}
const ConsumptionTarget_Materails: React.FC<any> = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    reset,
    control,
  } = useFormContext();
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);
  const [materialOptions, setMaterialOptions] = useState<SelectOptions[]>([]);
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "lines",
  });

  const previousFormDate = watch();

  const blockArea = previousFormDate?.blockArea || 1; // Default to 1 to prevent division by zero

  const fetchCategorys = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const categoryOption = data.map((item: any) => ({
          label: item.category,
          value: item.id.toString(),
        }));
        setCategoryOptions(categoryOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMaterialFilter = async (categoryId: any) => {
    console.log("categoryId", categoryId);
    try {
      const { data, status } = await misc.fetchProductByCategoryId(categoryId);
      if (status) {
        let _materialOptions: any = [];
        let allMaterial: any = [];
        data.forEach((item: any) => {
          _materialOptions.push({
            label: item?.productName,
            value: item?.materialID?.toString(),
          });
        });
        // setValue("material", allMaterial?.join(","));
        if (_materialOptions.length > 1) {
          _materialOptions = [
            // {
            //   label: "ALL",
            //   value: allMaterial?.join(","),
            // },
            ..._materialOptions,
          ];
        }
        setMaterialOptions(_materialOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchMaterials = async () => {
    try {
      const { data, status } = await materialsApis.fetchProduct();
      if (status) {
        setMaterialCategoryOptions(data);
        const materialOption = data.map((item: any) => ({
          label: item.productName,
          value: item.materialID.toString(),
        }));
        setMaterialOptions(materialOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("previousFormDate", previousFormDate.blockArea);
    fetchCategorys();
    fetchMaterials();
  }, []);

  useEffect(() => {
    // Initialize fields if they are empty
    if (fields.length === 0) {
      replace([
        {
          categoryId: "",
          materialId: "",
          quantitySet: "",
          materialName: "",
          categoryName: "",
        },
      ]);
    }
  }, [fields, replace]);

  const submitForm = (data: any) => {
    console.log("data", data);
    // data.lines.map((line: any, index: number) => {
    //   line.materialName = materialOptions.find(
    //     (item: any) => item.value == line.materialId
    //   )?.label;

    //   line.categoryName = categoryOptions.find(
    //     (item: any) => item.value === line.categoryId
    //   )?.label;
    // });

    props.next();
  };

  const handleQuantityBlur = (quantity: string, index: number) => {
    const quantityValue = parseFloat(quantity) || 0; // Ensure it's a number
    const targetPerSft =
      quantityValue && blockArea ? quantityValue / blockArea : 0;
    // setValue(`lines.${index}.quantitySet`, quantity);
    setValue(`lines.${index}.targetPerSft`, targetPerSft.toFixed(2));
  };

  return (
    <form className="content clearfix" onSubmit={()=>{
      const processedData = {
        ...getValues(), // Get current form values
        lines: getValues("lines").map((line: any) => ({
          ...line,
          materialName: materialOptions.find((item: any) => item.value == line.materialId)?.label || "Unknown Material",
          categoryName: categoryOptions.find((item: any) => item.value === line.categoryId)?.label || "Unknown Category",
        })),
      };
    
      console.log("Processed Data:", processedData);
    
      // Call handleSubmit with the processed data
      handleSubmit(() => submitForm(processedData))();
    }}>
      <Row>
        {fields.map((field: any, index) => {
          const selectedCategoryId = watch(`lines.${index}.categoryId`);

          return (
            <div key={field.id} className="row mb-3">
              <div className="col-md-2">
                <FormSelect
                  name={`lines.${index}.categoryId`}
                  label="Category"
                  required={true}
                  options={categoryOptions}
                  placeholder="Select Category"
                />
              </div>
              <div className="col-md-2">
                <FormSelect
                  name={`lines.${index}.materialId`}
                  label="Material"
                  required={true}
                  options={materialCategoryOptions
                    ?.filter((item: any) => item.category == selectedCategoryId)
                    ?.map((item: any) => ({
                      label: item.productName,
                      value: item.materialID,
                    }))}
                  placeholder="Select Material"
                />
              </div>
              <div className="col-md-2">
                <FormInput
                  name={`lines.${index}.quantitySet`}
                  label="Quantity"
                  type="number"
                  placeholder="Enter Quantity"
                  // required={true}
                  onInput={(e: any) =>
                    handleQuantityBlur(e.target.value, index)
                  }
                />
              </div>
              <div className="col-md-2">
                <FormInput
                  name={`lines.${index}.targetPerSft`}
                  label="Set Target/Sft"
                  type="number"
                  placeholder="Enter Target/Sft"
                  disabled={true} // Prevent user input
                />
              </div>
              <div className="col-md-2">
                <div style={{ marginTop: 30 }}>
                  <button
                    type="button"
                    className="btn btn-success ms-2"
                    onClick={() =>
                      append({
                        categoryId: "",
                        materialId: "",
                        quantitySet: "",
                        materialName: "",
                        categoryName: "",
                        // maximumStockLevel: "",
                        // reorderPoint: "",
                      })
                    }
                  >
                    +
                  </button>
                  &nbsp;
                  {index > 0 && (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => remove(index)}
                    >
                      -
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </Row>
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Next
            </Button>
          </li>
          <li>
            <Button color="primary" onClick={props.prev}>
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default ConsumptionTarget_Materails;
