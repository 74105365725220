import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useLocation, useNavigate, useParams } from "react-router";
import dayjs from "dayjs";
import FormatDate from "../../masters/DateFormater";
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";
import { Icon } from "../../../components/Component";

function OrderDetailsBySiteReport() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const name = location.state?.name;
  const columns: any = [
    {
      key: "orderId",
      title: "Order #",
      dataIndex: "orderId",
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/purchase-order-report-preview/${record.orderNumber}`, {
                state: {
                  previousPath: `/order-details-by-site-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      width:100,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: {
                  previousPath: `/order-details-by-site-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width:100,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 200,
    },
    // {
    //   title: "Category Name",
    //   dataIndex: "categoryName",
    //   key: "categoryName",
    // },
    // {
    //   title: "Material Name",
    //   dataIndex: "materialName",
    //   key: "materialName",
    // },
    // {
    //   title: "Quantity",
    //   dataIndex: "quantity",
    //   key: "quantity",
    //   render: (quantity: string, record: any) => (
    //     <span>
    //       {quantity} {record?.quantityUom}
    //     </span>
    //   ),
    // },
    // {
    //   title: "Unit Price",
    //   dataIndex: "unitPrice",
    //   key: "unitPrice",
    //   render: (text: any, record: any) => (
    //     <div style={{ textAlign: "right" }}>
    //       <span>{text}</span>
    //     </div>
    //   ),
    // },
    {
      title: "Invoice Value Without Tax",
      dataIndex: "amountWithoutTax",
      key: "amountWithoutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Invoice Value With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle={`${name} - Purchases, Detail Report`}
      moduleKey="purchaseBySiteDetailReport"
      columns={columns}
      filtersOption={{
        site: false,
        contractor: false,
        dateRange: false,
      }}
      previousPath="/purchase-by-site-reports-list"
      extra={{
        id: id,
        tableScrollX: 1100,
      }}
    />
  );
}

export default OrderDetailsBySiteReport;
