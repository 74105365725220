import 'reactflow/dist/style.css';
import ReactFlow, { addEdge, useNodesState, useEdgesState, Background, Handle, Position, ReactFlowProvider, useReactFlow } from 'reactflow';
import dagre from 'dagre';
import React, { useState, useEffect, FC, useRef } from "react";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../layout/head/Head";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Button, Icon } from "../../components/Component";
import PurchaseRequestAside from "./PurchaseRequestAside";
import purchaseRequestApis from '../../api/master/purchaseRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Spin } from 'antd';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';


const CustomNode = ({ data, isConnectable }: any) => {
  console.log("customeNode", data)

  return (
    <div style={{ border: '1px solid black', fontSize: '16px', padding: '10px', borderRadius: '5px', width: '250px' }}>
      <Handle type="target" position={Position.Top} />
      <div style={{ backgroundColor: 'rgba(0, 0, 255, 0.16)', color: 'blue', padding: '5px', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><strong>{data.label}</strong></div>
      <div><strong>Sequence ID:</strong> {data.trackingId}</div>
      <div><strong>Created At:</strong> {dayjs(data.createdAt).format("DD-MMM-YYYY")}</div>
      <div><strong>Created By:</strong> {data.createdBy}</div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

const nodeTypes = {
  selectorNode: CustomNode,
};

const dagreLayout = (nodes: any[], edges: any[]) => {
  const g = new dagre.graphlib.Graph();
  g.setGraph({});
  g.setDefaultEdgeLabel(() => ({}));

  // Set nodes in dagre graph
  nodes.forEach((node) => {
    g.setNode(node.id, { width: 250, height: 200 });
  });

  // Set edges in dagre graph
  edges.forEach((edge) => {
    g.setEdge(edge.source, edge.target);
  });

  // Run the layout
  dagre.layout(g);

  // Apply dagre layout positions to nodes
  return nodes.map((node) => {
    const nodeData = g.node(node.id);
    return {
      ...node,
      position: { x: nodeData.x - 125, y: nodeData.y - 75 }, // Offset the position to center nodes
      type: 'selectorNode',
    };
  });
};


const TrackPurchaseRequests = () => {
  let { prId }: any = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    getPRTracking()
  }, []);

  const getPRTracking = async () => {
    try {
      const { response, status, message } = await purchaseRequestApis.prTracking(prId);
      if (status) {
        const data = response;
        setNodes(dagreLayout(data.initialNodes, data.initialEdges)); // Apply layout
        setEdges(data.initialEdges);
      }
      else {
        Swal.fire("Error", message, "error");
      }
      setIsLoading(false);

    } catch (error) {
      console.log("Error fetching PR tracking data:", error);
    }
  };


  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document.getElementsByClassName("nk-header")[0]?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document.getElementsByClassName("nk-header")[0]?.removeEventListener("click", headerClick);
    };
  }, []);


  const onFlowInit = (instance: any) => {
    if (!isLoading && nodes.length > 0 && edges.length > 0) {
      instance.fitView({ padding: 0.1, includeHiddenNodes: true });
    }
  };


  const onConnect = (params: any) => setEdges((eds) => addEdge(params, eds));

  return (
    <>
      <Head title="Purchase Requests - Track" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""}`}>
              <PurchaseRequestAside updateSm={setSm} sm={sm} />
            </div>
            {isLoading ? (
              <Flex align="center" gap="middle" className="pageLoader">
                <Spin size="large" className="m-auto" />
              </Flex>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && <div className="toggle-overlay" onClick={() => setSm(!sm)}></div>}
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">Track Purchase Request </BlockTitle>
                      <BlockDes>
                        <p>PR ID - {prId}</p>
                      </BlockDes>
                    </BlockHeadContent>
                    <div className="d-flex">
                      {
                        <div className="paddingLeft10">
                          <Button
                            className="toggle"
                            color="primary"
                            onClick={async () => {
                              navigate(-1);
                            }}
                          >
                            <Icon name="arrow-left" />
                            <span>Back</span>
                          </Button>
                        </div>
                      }
                    </div>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`} onClick={() => setSm(!sm)}>
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>
                <Block>
                  <Card className="card-bordered">
                    <div style={{ width: '100%', height: '600px' }}>
                      <ReactFlowProvider>
                        <ReactFlow
                          nodes={nodes}
                          edges={edges}
                          onNodesChange={onNodesChange}
                          onEdgesChange={onEdgesChange}
                          onConnect={onConnect}
                          nodeTypes={nodeTypes}
                          onInit={onFlowInit}
                        >
                          <Background color='#aaa' gap={16} />
                        </ReactFlow>
                      </ReactFlowProvider>
                    </div>
                  </Card>
                </Block>
              </div>
            )}
          </div>
        </Card>
      </Content>
    </>
  );
};

export default TrackPurchaseRequests;
