import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import dayjs from "dayjs";
import FormatDate from "../../masters/DateFormater";
import {Icon} from "../../../components/Component"
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";
import { useLocation, useNavigate, useParams } from "react-router";

function SupplierBalancesDetailReport() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const name = location.state?.name;

   const columns: any = [
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      width: 200,
      fixed:"left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: {
                  previousPath: `/supplierBalancesDetailReport/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      width: 100,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "due Date",
      dataIndex: "dueDate",
      width: 100,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "GRN Date",
      dataIndex: "grnDate",
      width: 100,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Due Type",
      dataIndex: "dueType",
      width: 100,
      render: (dueType: any) => (
        <span className="text-capitalize">{dueType}</span>
      ),
    },
    {
      title: "Aging",
      dataIndex: "aging",
      width: 100,
      render: (aging: any) => <span>{aging} days</span>,
    },
    {
      title: "Invoice Amount Without Tax",
      dataIndex: "invAmountWithoutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Invoice Amount With Tax",
      dataIndex: "invAmountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Outstanding Amount",
      dataIndex: "outstandingAmount",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Overdue Amount",
      dataIndex: "overDueAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      width:150
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle={`${name} - Balances, Detail Report`}
      moduleKey="supplierBalancesDetailReport"
      columns={columns}
      filtersOption={{
        site: false,
        contractor: false,
        dateRange: false,
        aging: false,
      }}
      previousPath="/supplier-balances-report"
      extra={{
        tableScrollX: 1850,
        id
      }}
    />
  );
}

export default SupplierBalancesDetailReport;
