import React, { FC, useEffect, useState } from "react";
import * as echarts from "echarts";
import { Button, Empty, Select } from "antd";
import supplierApis from "../../api/master/supplier";
import InventoryAnalyticsAPI from "../../api/inventory/InventoryAnalytics";
import { useNavigate } from "react-router";
const { Option } = Select;

const InventoryPurchasedvsUtilized: FC<any> = ({ dates, sites }) => {
  const navigate = useNavigate();
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [category, setCategory] = useState("");
  const [purchasevsUtilizationData, setPurchasevsUtilizationData] = useState(
    []
  );
  const [chartData, setChartData] = useState<any | null>(null);

  useEffect(() => {
    if (sites && category) {
      fetchPurchasevsUtilizationData();
    }
  }, [sites, category, dates]);

  const fetchPurchasevsUtilizationData = async () => {
    try {
      const { from, to } = dates;
      const payload: any = {
        siteId: sites,

        categoryId: category,
        startDate: from,
        endDate: to,
      };
      const res =
        await InventoryAnalyticsAPI.fetchMaterialWiseInventoryPurchasedvsUtilization(
          payload
        );
      const { response, status } = res;
      if (status) {
        console.log("response:::PurchasevsUtilizationData", response);
        setPurchasevsUtilizationData(response);
        const materials = response.map((item: any) => item.materialName);
        const purchasedValues = response.map((item: any) =>
          parseFloat(item.total_inward_price)
        );
        const utilizedValues = response.map((item: any) =>
          parseFloat(item.total_handover_price)
        );
        setChartData({
          materials,
          purchasedValues,
          utilizedValues,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  const fetchCategories = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const allCategory: any = [];
        const _categoryOptions: any = [];
        data?.forEach((item: any) => {
          allCategory.push(item.id);
          _categoryOptions.push({ label: item.category, value: item.id });
        });
        setCategory(allCategory.join(","));
        setCategoryOptions([
          {
            label: "All Categories",
            value: allCategory?.join(","),
          },
          ..._categoryOptions,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (chartData) {
      const chartDom = document.getElementById(
        "inventoryPurchasedvsUtilized-chart"
      ) as HTMLElement;
      if (chartDom) {
        const myChart = echarts.init(chartDom);

        const option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["Purchased", "Utilized"],
            top: "5%",
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            name: "Material",
            data: chartData?.materials,
          },
          yAxis: {
            type: "value",
            name: "Inventory Value",
            axisLabel: {
              formatter: "₹{value}", // Format with dollar symbol
            },
          },
          series: [
            {
              name: "Purchased",
              type: "line",
              data: chartData?.purchasedValues,
              smooth: true, // Smooth line
              lineStyle: {
                color: "#5470c6", // Customize line color
              },
              itemStyle: {
                color: "#5470c6", // Point color
              },
            },
            {
              name: "Utilized",
              type: "line",
              data: chartData?.utilizedValues, // Example utilized values
              smooth: true,
              lineStyle: {
                color: "#73c0de", // Customize line color
              },
              itemStyle: {
                color: "#73c0de", // Point color
              },
            },
          ],
        };

        myChart.setOption(option);

        return () => {
          myChart.dispose();
        };
      }
    }
  }, [chartData]);

  const handleCategoryChange = (value: string) => {
    setCategory(value.toString());
  };

  return (
    <div>
      <div
        className="card-title-group mb-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="card-title">
          <h6 className="title">
            Material Wise Inventory Purchased vs Utilization
          </h6>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <div>
            <span style={{ fontWeight: "bold" }}> Filter by Category: </span>
            <Select
              defaultValue="ALL"
              style={{ width: 150 }}
              onChange={handleCategoryChange}
            >
              {categoryOptions?.map((item: any) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </div>
          <Button type="link" onClick={() => {
            navigate("/inventory-material-utilization-report-list")
          }}>
            Click here to View Details
          </Button>
        </div>
      </div>
      {chartData && (
        <div
          id="inventoryPurchasedvsUtilized-chart"
          style={{
            width: "100%",
            height: "400px",
            display: `${chartData?.materials?.length > 0 ? "block" : "none"}`,
          }}
        />
      )}
      {chartData?.materials?.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          <Empty description="No data available to generate or display the graph." />
        </div>
      )}
    </div>
  );
};

export default InventoryPurchasedvsUtilized;
