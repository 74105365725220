import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

export const defaultReportTemplateSlice = createSlice({
  name: "defaultReportTemplate",
  initialState,
  reducers: {
    updateDefaultReportTemplateState: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    clearDefaultReportTemplate: (state) => {
      state.data = {};
      state.loading = false;
      state.error = null;
    },
  },
});


export const { updateDefaultReportTemplateState, clearDefaultReportTemplate} = defaultReportTemplateSlice.actions;

export default defaultReportTemplateSlice.reducer;