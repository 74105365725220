import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import BudgetAside from "./BudgetAside";
import ViewBudgetDetails from "./ViewBudgetDetails";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import budgetApis from "../../api/master/budget";
import { useNavigate } from "react-router";
import { formatCurrency } from "../../utils/Utils";
import dayjs from "dayjs";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}
const widgetData: WidgetData[] = [
  {
    title: "Draft RFQs",
    count: 10,
    progress: 30,
    color: "#OO65BD",
  },
  {
    title: "PENDING",
    count: 5,
    progress: 50,
    color: "#20639B",
  },
  {
    title: "In Progress",
    count: 7,
    progress: 70,
    color: "#FA991C",
  },
  {
    title: "Converted to POs",
    count: 12,
    progress: 90,
    color: "#F6D55C",
  },
  {
    title: "Delivered",
    count: 15,
    progress: 100,
    color: "#OOCC99",
  },
];
const orderStatusCards: any = {
  // draft: {
  //   color: "#e85347",
  //   lable: "Draft Purchase Order",
  // },

  pending: {
    color: "#09c2de",
    lable: "PENDING",
  },
  withSuppliers: {
    color: "#364a63",
    lable: "Order With Suppliers",
  },
  invoiced: {
    color: "#1ee0ac",
    lable: "Invoiced",
  },
  // deliveredGrn: {
  //   color: "#2263b3",
  //   lable: "Delivered GRN",
  // },
  shortClosed: {
    color: "rgb(249, 140, 69)",
    lable: "Short Closed",
  },
};
const PoStatusColors: any = {
  PENDING: "#09c2de",
  Approved: "#1ee0ac",
  // Rejected: "#e85347",
  // "Pending Change Approval": "#09c2de",
  // "Accepted by Supplier": "#1ee0ac",
  // "Rejected by Supplier": "#e85347",
  // "To be Invoiced": "#2263b3",
  // "To be Delivered": "#2263b3",
};
const PoStatusIcons: any = {
  PENDING: "alert-circle",
  "To be Invoiced": "alert-circle",
  Approved: "check-circle",
  Rejected: "info",
  "Pending Change Approval": "alert-circle",
  "Accepted by Supplier": "check-circle",
  "To be Delivered": "check-circle",
  "Rejected by Supplier": "info",
};
const PoStatusIconsColors: any = {
  PENDING: "secondary",
  "To be Invoiced": "secondary",
  Approved: "success",
  Rejected: "danger",
  "Pending Change Approval": "secondary",
  "To be Delivered": "success",
  "Accepted by Supplier": "success",
  "Rejected by Supplier": "danger",
};

const ViewBudgetList: FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [viewBudgetDetails, setViewBudgetDetails] = useState<any>(null);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState<string | undefined>(
    undefined
  );
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    console.log("11111:::Usefeect");
    viewChange();
    fetchBudget();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, [viewBudgetDetails]);
  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "Budget Id",
      dataIndex: "budgetId",
      key: "budgetId",
      width: 200,
      fixed: "left",
      // render: (text) => (
      //   <div className="align-left">
      //     <span>{text}</span>
      //   </div>
      // ),
    },
    {
      title: "Budget Name",
      dataIndex: "budgetName",
      key: "budgetName",
      width: 150,
      fixed: "left",
      render: (text) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    // {
    //   title: "Budget For",
    //   dataIndex: "budgetFor",
    //   key: "budgetFor",
    //   width: 120,
    // },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Contractor",
      dataIndex: "contractorName",
      key: "contractorName",
      width: 200,
    },
    {
      title: "Planned Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 150,
      render: (text) => <span>{dayjs(text).format("DD-MMM-YYYY")}</span>,
    },
    {
      title: "Planned End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: 150,
      render: (text) => <span>{dayjs(text).format("DD-MMM-YYYY")}</span>,
    },
    {
      title: "Budget Value",
      dataIndex: "budgetValue",
      key: "budgetValue",
      width: 200,
      render: (text: any, record: any) => (
        <p className="justify-end">{formatCurrency(Number(text), "INR")}</p>
      ),
    },
    {
      title: "Allocated Value",
      dataIndex: "allocatedValue",
      key: "allocatedValue",
      width: 200,
      render: (text: number) => (
        <p className="justify-end">
          {text
            ? new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              }).format(text)
            : formatCurrency(0, "INR")}
        </p>
      ),
    },
    {
      title: "Utilized Value",
      dataIndex: "utilizedValue",
      key: "utilizedValue",
      width: 200,
      render: (text: number, record: any) => {
        const budgetValue = record.budgetValue || 0;
        const percentage = budgetValue ? (text / budgetValue) * 100 : 0;

        // Determine color based on percentage
        let color = "black"; // Default color
        if (percentage >= 75) color = "red";
        else if (percentage >= 50) color = "orange";
        else if (percentage >= 25) color = "yellow";
        else color = "green";

        return (
          <div style={{ color }}>
            {new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
            }).format(text)}{" "}
            ({percentage.toFixed(2)}%)
          </div>
        );
      },
    },

    // {
    //   title: "GRN Status",
    //   dataIndex: "deliveryStatus",
    //   key: "deliveryStatus",
    //   width: 200,
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (status: any) => (
        <div className="justify-start align-center">
          <Icon
            className={`text-${PoStatusIconsColors[status]}`}
            name={`${PoStatusIcons[status]}`}
            style={{ fontSize: "20px" }}
          />
          <h6
            className={`tb-status ms-1`}
            style={{
              color: `${PoStatusColors[status]}`,
              fontSize: "14px",
            }}
          >
            {status}
          </h6>
        </div>
      ),
    },

    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 120,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(`/view_budget_details/${record.headerId}`);
              // setViewBudgetDetails({
              //   status: record.status,
              //   headerId: record.headerId,
              // });
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ]);
  const handleStatusFilterChange = (value: string | undefined) => {
    setStatusFilter(value);
    filterTable(searchText, value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
    filterTable(value, statusFilter);
  };

  const filterTable = (searchText: string, status: string | undefined) => {
    let _filteredData: any = originalData;
    if (searchText) {
      console.log("searchText", searchText);

      _filteredData = _filteredData.filter(
        (item: any) =>
          item?.budgetId?.toLowerCase().includes(searchText.toLowerCase()) ||
          item?.description?.toLowerCase().includes(searchText.toLowerCase()) ||
          item?.siteName?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.createdUserName
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.budgetName?.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (status && status !== "ALL") {
      console.log("status", status);
      console.log("filteredData", _filteredData);
      _filteredData = _filteredData?.filter(
        (item: any) => item.status == status
      );
    }
    console.log("filteredData------", _filteredData);

    setFilteredData(_filteredData);
  };
  const fetchBudget = async () => {
    try {
      const res = await budgetApis.fetchBudgetAll();
      const { response, status, message } = res;
      console.log("data", res);
      if (status) {
        let data = response.map((item: any) => item.header);
        setOriginalData(data);
        setFilteredData(data);
      } else {
        Swal.fire("Error", message, "error");
      }
      setIsLoading(false);
    } catch (error) {}
  };

  const FormatCurrency = (
    number: any,
    currencyFormat: any,
    currencySymbol: any
  ) => {
    if (currencyFormat == "") {
      currencyFormat = "₹#,##0.00";
    }
    if (currencySymbol == "") {
      currencySymbol = "₹";
    }
    // Define regex patterns to extract formatting details
    const symbolBeforePattern = /^([^#0,]+)?([#0.,]+)$/;
    const symbolAfterPattern = /^([#0.,]+)([^#0,]+)?$/;
    let locale = "en-US"; // Default locale
    let symbolPosition = "before"; // Default to symbol before the number
    let minimumFractionDigits = 2; // Default to 2 decimal places
    // Check for symbol position
    let matchBefore = currencyFormat?.match(symbolBeforePattern);
    let matchAfter = currencyFormat?.match(symbolAfterPattern);
    if (matchBefore) {
      symbolPosition = "before";
    } else if (matchAfter) {
      symbolPosition = "after";
    }
    // Check for fraction digits
    let fractionMatch = currencyFormat?.match(/[.,]0*$/);
    if (fractionMatch) {
      minimumFractionDigits = fractionMatch[0].length - 1;
    }
    // Create a new Intl.NumberFormat object
    let formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    });
    // Format the number
    let formattedNumber = formatter.format(number);
    if (formattedNumber == "NaN" || formattedNumber == "undefined") {
      return "";
    }
    // Add the currency symbol in the correct position
    if (symbolPosition === "before") {
      return currencySymbol + formattedNumber;
    } else {
      return formattedNumber + currencySymbol;
    }
  };

  const BudgetCreation = async (data: any) => {
    console.log("data:11111q1", data);
    try {
      const response: any = await budgetApis.budgetCreationMethod(data);
      console.log(response);
      const { status, message } = response;
      if (status) {
        Swal.fire("Updated!", "Budget has been Updated", "success");
        setViewBudgetDetails(null);
        // navigate(`/view-budget`);
        // fetchBudgetDetails();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };
  const submitButton = (data: any) => {
    console.log("showSubmitButton", data);
  };
  return (
    <>
      <Head title="Purchase Requests - View" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <BudgetAside updateSm={setSm} sm={sm} />
            </div>
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">
                        {viewBudgetDetails == null && <span>Budget</span>}
                      </BlockTitle>
                    </BlockHeadContent>
                    {/* <Button
                    className="toggle"
                    style={{
                      visibility: viewBudgetDetails ? "visible" : "hidden",
                    }}
                    color="primary"
                    onClick={() => setViewBudgetDetails(null)}
                  >
                    <Icon name="arrow-left" />
                    <span>Back</span>
                  </Button> */}
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${
                          sm ? "active" : ""
                        }`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>

                <Block>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <div>
                      <span>Search by Status: </span>
                      <Select
                        style={{ width: 200 }}
                        onChange={handleStatusFilterChange}
                        value={statusFilter || "ALL"}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <div
                              style={{
                                padding: "8px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>Search by Status: </span>
                              <SearchOutlined />
                            </div>
                          </>
                        )}
                      >
                        <Option value="ALL">All</Option>
                        {filteredData
                          .filter(
                            (item, index, self) =>
                              index ===
                              self.findIndex((t) => t.status === item.status)
                          )
                          .map((item, index) => (
                            <Option key={index} value={item.status}>
                              {item.status}
                            </Option>
                          ))}
                      </Select>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Input
                        placeholder="Search by Site Name &amp; Invoice Number"
                        value={searchText}
                        onChange={handleSearchChange}
                        style={{ width: 400 }}
                        prefix={<SearchOutlined />}
                      />
                    </div>
                  </div>
                </Block>

                {/* {viewBudgetDetails == null ? ( */}
                {filteredData.length > 0 ? (
                  <>
                    <div>
                      {/* <Block>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div>
                        <span>Search by Status: </span>
                        <Select
                          style={{ width: 200 }}
                          onChange={handleStatusFilterChange}
                          value={statusFilter || "ALL"}
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <div
                                style={{
                                  padding: "8px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>Search by Status: </span>
                                <SearchOutlined />
                              </div>
                            </>
                          )}
                        >
                          <Option value="ALL">All</Option>
                          {Object.keys(PoStatusColors).map((item, index) => (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Input
                          placeholder="Search by Site Name &amp; Budget Id"
                          value={searchText}
                          onChange={handleSearchChange}
                          style={{ width: 400 }}
                          prefix={<SearchOutlined />}
                        />
                      </div>
                    </div>
                  </Block> */}

                      <div
                        style={{
                          width: screenSize - 330 + "px",
                          minHeight: "200px",
                          marginTop: "20px",
                        }}
                      >
                        <Table
                          className="customTable"
                          columns={columns.map((item: any, index) => ({
                            ...item,
                            index,
                          }))}
                          dataSource={filteredData}
                          pagination={{
                            pageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "20", "30", "50"],
                          }}
                          bordered
                          size="middle"
                          scroll={{ x: 1400 }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <Empty description="No Budgets" />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </Card>
      </Content>
    </>
  );
};

export default ViewBudgetList;

// ) : (
//   <Block>
//     <ViewBudgetDetails BudgetData={viewBudgetDetails}
//       addRow={BudgetCreation}
//       showSubmitButton= {submitButton}
//     />
//   </Block>
// )}
