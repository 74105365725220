import { configureStore } from '@reduxjs/toolkit';
import { currentUserSlice } from './slices/misc/currentUser';
import { menuDataSlice } from './slices/misc/menuData';
import { accessibilitySlice } from './slices/misc/accessibility';
import { allowedRoutesSlice } from './slices/misc/allowedRoutes';
import { sequenceAccessSlice } from './slices/misc/sequenceAccess';
import { defaultReportTemplateSlice } from './slices/misc/defaultReportTemplate';

export const store = configureStore({
  reducer: {
    // key : slice.reducer
    currentUser: currentUserSlice.reducer,
    menu: menuDataSlice.reducer,
    accessibility: accessibilitySlice.reducer,
    allowedRoutes: allowedRoutesSlice.reducer,
    sequenceAccess: sequenceAccessSlice.reducer,
    defaultReportTemplate: defaultReportTemplateSlice.reducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;