import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import InventoryAside from "./InventoryAside";
import invoiceApis from "../../api/Invoice/invoice";
import ServerSideTable from "../form-components/PaginationTableComponent";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import inventoryCreation from "../../api/inventory/inventoryCreation";
import dayjs from "dayjs";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

const InventoryPendingApproval: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sm, setSm] = useState(false);
  const navigate = useNavigate();
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  // const [viewPODetails, setViewPODetails] = useState<any>(null);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState<string | undefined>(
    undefined
  );
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [inputValues, setInputValues] = useState<any>({});
  const [showSubmit, setShowSubmit] = useState(false);

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  useEffect(() => {
    viewChange();
    // fetchAllInvoices();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "#",
      key: "#",
      width: 50,
      render: (text, record, index) => index + 1,
      sorter: true,
    },
    {
      title: "Material Issue Number",
      dataIndex: "issueNumber",
      key: "issueNumber",
      width: 200,
      fixed: "left",
      sorter: true,
      render: (text) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    // {
    //   title: "Site ID",
    //   dataIndex: "siteId",
    //   key: "siteId",
    //   sorter: true,
    //   width: 200,
    // },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      sorter: true,
      width: 200,
    },
    {
      title: "Block / Tower",
      dataIndex: "blockName",
      key: "blockName",
      sorter: true,
      width: 200,
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      sorter: true,
      width: 200,
    },
    {
      title: "Creation Date",
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: true,
      width: 200,
      render: (text, record) => (
        <span>{dayjs(text).format("DD-MMM-YYYY")}</span>
    ),
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 120,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(`/view-material-issue-details/${record.materialIssueId}`, {
                state: { screenStatus: "Pending Material Issue Approvals" },
              });
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ]);

  const handleInputChange = (e: any, key: any) => {
    const value = e.target.value;
    setInputValues((prevValues: any) => ({ ...prevValues, [key]: value }));

    // Check if any field has a value
    const hasInput = Object.values({ ...inputValues, [key]: value }).some(
      (val: any) => val && val.trim() !== ""
    );
    setShowSubmit(hasInput);
  };

  const handleStatusFilterChange = (value: string | undefined) => {
    // setStatusFilter(value);
    // filterTable(searchText, value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const { value } = e.target;
    // setSearchText(value);
    // filterTable(value, statusFilter);
  };

  // const filterTable = (searchText: string, status: string | undefined) => {
  //   let _filteredData: any = originalData;
  //   if (searchText) {
  //     console.log("searchText", searchText);

  //     _filteredData = _filteredData.filter(
  //       (item: any) =>
  //         item.invoiceId
  //           .toString()
  //           .toLowerCase()
  //           .includes(searchText.toLowerCase()) ||
  //         item.orgName.toLowerCase().includes(searchText.toLowerCase()) ||
  //         item.siteName.toLowerCase().includes(searchText.toLowerCase()) ||
  //         item.createdUserName
  //           .toLowerCase()
  //           .includes(searchText.toLowerCase()) ||
  //         item.supplierName.toLowerCase().includes(searchText.toLowerCase())
  //     );
  //   }

  //   if (status && status !== "ALL") {
  //     console.log("status", status);
  //     console.log("filteredData", _filteredData);
  //     _filteredData = _filteredData?.filter(
  //       (item: any) => item.invoiceStatus == status
  //     );
  //   }

  //   setFilteredData(_filteredData);
  // };
  // const fetchAllInvoices = async () => {
  //   try {
  //     const res = await invoiceApis.fetchAllInvoices();
  //     const { data, status, message } = res;
  //     if (status) {
  //       setOriginalData(data);
  //       setFilteredData(data);
  //     } else {
  //       Swal.fire("Error", message, "error");
  //     }
  //     setIsLoading(false);
  //   } catch (error) {}
  // };

  const fetchData = async (params: any) => {
    console.log("params", params);
    try {
      const response = await inventoryCreation.fetchPendingApprovals(
        params.sortOrder,
        params.pageSize,
        params.direction,
        params.pageDiff,
        params.orderNumber
      );
      console.log("response", response);
      const data = response.response;
      console.log("data", data);
      return {
        data: data.data.list,
        totalCount: data.data.count,
      };
    } catch (error) {
      console.error("Failed to fetch data:", error);
      return {
        data: [],
        totalCount: 0,
      };
    }
  };

  const FormatCurrency = (
    number: any,
    currencyFormat: any,
    currencySymbol: any
  ) => {
    if (currencyFormat == "") {
      currencyFormat = "₹#,##0.00";
    }
    if (currencySymbol == "") {
      currencySymbol = "₹";
    }
    // Define regex patterns to extract formatting details
    const symbolBeforePattern = /^([^#0,]+)?([#0.,]+)$/;
    const symbolAfterPattern = /^([#0.,]+)([^#0,]+)?$/;
    let locale = "en-US"; // Default locale
    let symbolPosition = "before"; // Default to symbol before the number
    let minimumFractionDigits = 2; // Default to 2 decimal places
    // Check for symbol position
    let matchBefore = currencyFormat?.match(symbolBeforePattern);
    let matchAfter = currencyFormat?.match(symbolAfterPattern);
    if (matchBefore) {
      symbolPosition = "before";
    } else if (matchAfter) {
      symbolPosition = "after";
    }
    // Check for fraction digits
    let fractionMatch = currencyFormat?.match(/[.,]0*$/);
    if (fractionMatch) {
      minimumFractionDigits = fractionMatch[0].length - 1;
    }
    // Create a new Intl.NumberFormat object
    let formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    });
    // Format the number
    let formattedNumber = formatter.format(number);
    if (formattedNumber == "NaN" || formattedNumber == "undefined") {
      return "";
    }
    // Add the currency symbol in the correct position
    if (symbolPosition === "before") {
      return currencySymbol + formattedNumber;
    } else {
      return formattedNumber + currencySymbol;
    }
  };

  return (
    <>
      <Head title="Pending Approvals" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <InventoryAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      <span>Material Issue Pending Approvals</span>
                    </BlockTitle>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <div>
                <div
                  style={{
                    width: screenSize - 330 + "px",
                    minHeight: "200px",
                    marginTop: "20px",
                  }}
                >
                  <ServerSideTable
                    columns={columns}
                    fetchData={fetchData} // Pass the fetchData function
                    rowKey={(record: any) => record.key}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default InventoryPendingApproval;
