import React, { useState, useEffect, FC } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Label, Input, Row, Col, Button, Alert } from "reactstrap";
import FormSelect from "../../form-components/SelectComponent";
import Swal from "sweetalert2";
import { Icon } from "../../../components/Component";
import departments from "../../../api/master/departments";
import rolesApi from "../../../api/master/roles";
interface SelectOptions {
  label: string;
  value: string;
}
const RoleHeirarchyMaster: FC<{
  id?: string | null;
  formAction?: string | null;
  setAction?: () => void;
  toggleModelPopUp: () => void;
}> = ({ id, formAction, toggleModelPopUp }) => {
  const [action, setAction] = useState(formAction);
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = methods;
  const [alertInfo, setAlertInfo] = useState<any>({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [departmentData, setDepartmentData] = useState<SelectOptions[]>([]);
  const [roles, setRoles] = useState<SelectOptions[]>([]);
  const [data, setData] = useState<any>([]);
  const [toggleReportingToDropdown, setToggleReportingToDropdown] =
    useState(false);
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [reportingRoleIDSelected, setReportingRoleIDSelected] = useState();
  const reportingRoleIDWatch = watch("reportingRoleID");
  const fetchDept = async () => {
    try {
      const { data, status, message } = await departments.fetchDeptData();
      if (status) {
        setDepartmentData(
          data.map((obj: any) => ({
            label: obj.deptName,
            value: obj.deptID.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.message, "error");
    }
  };

  const fetchRoles = async () => {
    try {
      const { data, status, message } = await rolesApi.fetchRolesData();

      if (status) {
        setRoles(
          data.map((obj: any) => ({
            label: obj.roleName,
            value: obj.roleID.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  const onSubmit = async (data: any) => {
    console.log("data:::", data);
    if (data.leader === "true") {
      console.log("111", data.reportingToRoleID);
      data.reportingToRoleID = data.reportingRoleID;
    } else if (data.leader === "false") {
      data.reportingToRoleID = data.reportingToRoleID;
    }
    data = {
      ...data,
      leader: data.leader === "true",
    };
    console.log("data:::FINAL", data);
    // return
    try {
      if (!action && !id) {
        const { status, message } = await rolesApi.createReportingHierarchy(
          data
        );
        if (status) {
          Swal.fire("Created!", "Role Hierarchy has been created", "success");
          toggleModelPopUp();
        } else {
          setAlertInfo({
            type: "error",
            message: message,
            errors: [],
          });
          // Swal.fire("Failed!", message, "error");
        }
      } else if (action === "edit") {
        const { status, message } = await rolesApi.updateReportingHierarchy(
          id,
          data
        );
        if (status) {
          Swal.fire("Updated!", "Role Hierarchy has been updated", "success");
          toggleModelPopUp();
        } else {
          setAlertInfo({
            type: "error",
            message: message,
            errors: [],
          });
          // Swal.fire("Failed!", message, "error");
        }
      }
    } catch (err: any) {
      setAlertInfo({
        type: "error",
        message: err.response?.data?.message || err.message,
        errors: [],
      });
    }
  };
  const setRolesByDept = async (id: any) => {
    try {
      const { data, status, message } = await rolesApi.fetchRoleWithDeptId(id);
      if (status) {
        setRoles(
          data.map((obj: any) => ({
            label: obj.roleName,
            value: obj.roleID.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };
  const fetchReportingHierarchy = async () => {
    try {
      const { data, status, message } =
        await rolesApi.fetchReportingHierarchy();
      if (status) {
        setData(data.map((obj: any) => obj.reportingRoleID));
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };
  const fetchReportingHierarchyById = async (id: any) => {
    try {
      const { data, status, message } =
        await rolesApi.fetchReportingHierarchyById(id);
      if (status) {
        console.log(data);
        setSelectedNode(data);
        setValue("leader", data.leader.toString());
        setToggleReportingToDropdown(
          data.leader.toString() == "false" ? true : false
        );
        setValue("reportingRoleID", data.reportingRoleID.toString());
        if (data.leader == false) {
          setValue("reportingToRoleID", data.reportingToRoleID.toString());
        }
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  const deleteReportingHierarchy = async () => {
    try {
      const { data, status, message } =
        await rolesApi.deleteReportingHierarchyById(id);
      if (status) {
        Swal.fire(
          "Deleted!",
          "Reporting Hierarchy has been deleted",
          "success"
        );
        toggleModelPopUp();
        await fetchReportingHierarchy(); // Assuming fetchData is a function to fetch updated supplier data
      } else {
        let errorMessage = "An error occurred";
        let fullErrorMessage;
        if (typeof message == "string") {
          fullErrorMessage = `${errorMessage}\n${"-"}\n${message}`;
        } else {
          fullErrorMessage = `${errorMessage}\n${"-"}\n${message?.join(" ")}`;
        }
        setAlertInfo({
          type: "error",
          message: fullErrorMessage,
          errors: [],
        });
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  useEffect(() => {
    fetchDept();
    fetchRoles();
    fetchReportingHierarchy();
    if (id) {
      fetchReportingHierarchyById(id);
    }
  }, [id]);

  return (
    <>
      <h5 className="title">
        {action === "edit"
          ? "Edit Hierarchy"
          : action === "view"
          ? "View Hierarchy"
          : "Create Hierarchy"}
      </h5>

      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}

      <div style={{ marginTop: "15px" }}></div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row style={{ minHeight: "200px" }}>
            <Col lg="6">
              <FormSelect
                name="leader"
                label="Leadership Role"
                options={[
                  {
                    label: "Yes",
                    value: "true",
                  },
                  {
                    label: "No",
                    value: "false",
                  },
                ]}
                onChange={(e: any) => {
                  setToggleReportingToDropdown(e === "false" ? true : false);
                }}
                required={true}
              />
            </Col>
            <Col lg="6">
              <FormSelect
                name="reportingRoleID"
                label="Select Role"
                options={roles.filter(
                  (role: any) =>
                    !data.includes(Number(role.value)) ||
                    (action === "edit" &&
                      role.value == selectedNode?.reportingRoleID)
                )}
                // onChange={(e: any) => {
                //   setReportingRoleIDSelected(e);
                // }}
                required={true}
              />
            </Col>
            {/* {reportingRoleIDSelected}  */}
            {/* {JSON.stringify(roles)}
            ------------{JSON.stringify(reportingRoleIDWatch)} */}
            {toggleReportingToDropdown && (
              <Col lg="6">
                <FormSelect
                  name="reportingToRoleID"
                  label="Reporting Role"
                  options={[...roles].filter(
                    (role: any) =>
                      data.includes(Number(role.value)) &&
                      role.value != reportingRoleIDWatch // Filter out the selected "Select Role"
                  )}
                  required={true}
                />
              </Col>
            )}
          </Row>
          {action !== "view" ? (
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 d-flex">
                  <li>
                    <Button
                      className="btn btn-primary btn-md"
                      type="submit"
                      color="primary"
                    >
                      {/* <Icon name="check" /> */}
                      <span>{action === "edit" ? "Update" : "Submit"}</span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="link link-light"
                      type="button"
                      onClick={() => {
                        toggleModelPopUp();
                      }}
                    >
                      {/* <Icon name="cross" /> */}
                      <span>Cancel</span>
                    </Button>
                  </li>
                  {action === "edit" && (
                    <li className="ml-auto" style={{ marginLeft: "auto" }}>
                      <Button
                        className="link link-light"
                        type="button"
                        onClick={() => {
                          deleteReportingHierarchy();
                        }}
                      >
                        {/* <Icon name="cross" /> */}
                        <span>Delete</span>
                      </Button>
                    </li>
                  )}
                </ul>
              </Col>
            </Row>
          ) : (
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <div
                      className="btn btn-primary btn-md"
                      color="primary"
                      onClick={() => setAction("edit")}
                    >
                      <Icon name="pen" />
                      <span>Edit</span>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default RoleHeirarchyMaster;
