import data from "../../pages/app/file-manager/Data";
import makeApiCall from "../master/axiosIndex";

const reportApis = {
  // purchase Reports Start ------------------------------
  purchaseOrdersReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseOrdersReport",
      data,
    });
  },
  purchaseBySupplierReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseBySupplierReport",
      data,
    });
  },
  purchaseBySupplierDetailReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseBySupplierDetailReport",
      data,
    });
  },
  purchaseByMaterialReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseByMaterialReport",
      data,
    });
  },
  purchaseByMaterialDetailReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseByMaterialDetailReport",
      data,
    });
  },

  purchaseByCategoryReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseByCategoryReport",
      data,
    });
  },
  purchaseByCategoryReportDetailReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseByCategoryReportDetailReport",
      data,
    });
  },
  purchaseBySiteReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseBySiteReport",
      data,
    });
  },
  purchaseBySiteDetailReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/purchaseBySiteDetailReport",
      data,
    });
  },
  deliveryNoteDetailsReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/deliveryNoteDetailsReport",
      data,
    });
  },

  materialsByDeliveryNoteReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/materialsByDeliveryNoteReport",
      data,
    });
  },
  invoiceBySupplierReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/invoiceBySupplierReport",
      data,
    });
  },
  // purchase Reports End ------------------------------

  // Account Payable Reports Start ------------------------------
  supplierBalancesReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/supplierBalancesReport",
      data,
    });
  },
  supplierBalancesDetailReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/supplierBalancesDetailReport",
      data,
    });
  },
  supplierPaymentsReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/supplierPaymentsReport",
      data,
    });
  },
  supplierPaymentsInvoiceReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/supplierPaymentsInvoiceReport",
      data,
    });
  },
  paymentsAgingSummaryReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/paymentsAgingSummaryReport",
      data,
    });
  },
  paymentsAgingSummaryInvoiceReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/paymentsAgingSummaryInvoiceReport",
      data,
    });
  },
  paymentsAgingReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/paymentsAgingReport",
      data,
    });
  },
  paymentsReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/paymentsReport",
      data,
    });
  },
  invoiceDetailsForPaymentsReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/invoiceDetailsForPaymentsReport",
      data,
    });
  },
  getInventorySummaryReport: (data: any) => {
    return makeApiCall({
      method: "get",
      url: "/inventory/inventorySummaryReport",
      params: data,
    });
  },
  fetchInventoryAgingMaterials: (data: any) => {
    return makeApiCall({
      method: "get",
      url: "/inventory/inventoryAgingMaterials",
      params: data,
    });
  },
  fetchInventoryValueReport: (data: any) => {
    return makeApiCall({
      method: "get",
      url: "/inventory/inventoryValueReport",
      params: data,
    });
  },
  fetchInventoryValueInvoiceReport: (data: any, params: any) => {
    return makeApiCall({
      method: "post",
      url: "/inventory/inventoryValueInvoiceReport",
      data,
      params: params,
    });
  },
  fetchMaterialAbcClassificationList: (data: any) => {
    return makeApiCall({
      method: "get",
      url: "/inventory/materialAbcClassificationList",
      params: data,
    });
  },
  fetchCommittedStockReport: (data: any, params: any) => {
    return makeApiCall({
      method: "post",
      url: "/inventory/inventorySummary/committedReport",
      data,
      params: params,
    });
  },
  fetchInventoryTrackReport: (data: any) => {
    return makeApiCall({
      method: "get",
      url: "/inventory/inventoryTrackReport",
      params: data,
    });
  },
  fetchMaterialIssueToSite: (data: any) => {
    return makeApiCall({
      method: "get",
      url: "/inventory/materialIssue/handoverList",
      params: data,
    });
  },

  // Account Payable Reports Start ------------------------------
};

export default reportApis;
