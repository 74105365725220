import React from "react";
import ReportTemplate2 from "../reportTemplate2";
import { useNavigate, useParams } from "react-router-dom";
import { render } from "@testing-library/react";
import { formatCurrency2 } from "../../../utils/Utils";
import dayjs from "dayjs";

function InventoryValueReportPreview() {
  const navigate = useNavigate();
  const { id, historyId } = useParams();
  console.log("id", id);
  const columns: any = [
    {
      title: "INV #",
      key: "invoiceId",
      dataIndex: "invoiceId",
      width: 100,
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/view-invoice/${record.invoiceNumber}`);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "GRN #",
      key: "grnId",
      dataIndex: "grnId",
      width: 100,
    },
    {
      title: "INV Date",
      key: "invoiceDate",
      dataIndex: "invoiceDate",
      width: 100,
      render: (text: any, record: any) => (
        <div>
          <span>{dayjs(text).format("DD-MMM-YYYY")}</span>
        </div>
      ),
    },
    {
      title: "Supplier",
      key: "supplierName",
      dataIndex: "supplierName",
      width: 200,
    },
    {
      title: "Material Name",
      key: "materialName",
      dataIndex: "materialName",
      width: 100,
    },
    {
      title: "Unit Price Exclusive of GST",
      key: "inwardUnitPrice",
      dataIndex: "inwardUnitPrice",
      width: 100,
      render: (text: any) => <span>{formatCurrency2(text, "INR")}</span>,
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Inventory Value Detail Report"
      moduleKey="InventoryValueDetailReport"
      columns={columns}
      filtersOption={{
        site: false,
        // contractor: true,
        dateRange: false,
      }}
      previousPath="/inventory-value-report-list"
      extra={{
        id: id,
        historyId: historyId,
      }}
    />
  );
}

export default InventoryValueReportPreview;
