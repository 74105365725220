import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import { TableColumnsType, Table, Progress } from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import Swal from "sweetalert2";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import InvoiceAside from "./InvoiceAside";
import { formatCurrency } from "../../utils/Utils";

const PoStatusColors: any = {
  "Pending Approval": "#09c2de",
  "Changes to Buyer": "#364a63",
  "Changes from Buyer": "#e85347",
  Approved: "#1ee0ac",
  Rejected: "#e85347",
  "To be Invoiced": "#364a63",
  "To be Delivered": "#09c2de",
};
const PoStatusIcons: any = {
  "Pending Approval": "alert-circle",
  "Changes to Buyer": "alert-circle",
  "Changes from Buyer": "alert-circle",
  Approved: "check-circle",
  Rejected: "info",
  "To be Invoiced": "alert-circle",
  "To be Delivered": "alert-circle",
};
const PoStatusIconsColors: any = {
  "Pending Change Approval": "secondary",
  "Pending Approval": "secondary",
  "Changes to Buyer": "warning",
  "Changes from Buyer": "warning",
  Approved: "success",
  Rejected: "danger",
  "To be Invoiced": "secondary",
  "To be Delivered": "secondary",
};

const InvoiceApproval: FC = () => {
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewPODetails, setViewPODetails] = useState<any>(null);
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  useEffect(() => {
    viewChange();
    fetchPurchaseOrders();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Order Number",
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: 120,
      fixed: "left",
    },
    {
      title: "Reference ID",
      dataIndex: "referenceId",
      key: "referenceId",
      width: 100,
      fixed: "left",
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      width: 100,
      fixed: "left",
      render: (date) => <span>{FormatDate(date)}</span>,
    },
    {
      title: "Customer Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 200,
    },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Delivery Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 200,
      render: (date) => <span>{FormatDate(date)}</span>,
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, record.currencyCode)}</span>
        </div>
      ),
    },
    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {!Number(record?.sgst) && !Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "Gross Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 250,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, record?.currencyCode)}</span>
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date) => <span>{FormatDate(date)}</span>,
      width: 170,
    },
    {
      title: "INVOICE STATUS",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      width: 200,
    },
    {
      title: "GRN Status",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      width: 200,
      render(value: any, record: any, index: any) {
        const grnStatus: any = {
          FullyDelivered: "Fully Delivered",
          PartiallyDelivered: "Partially Delivered",
        };
        return <p className="tb-status">{grnStatus[value] || value}</p>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (status: any) => (
        <div className="justify-start align-center">
          <Icon
            className={`text-${PoStatusIconsColors[status]}`}
            name={`${PoStatusIcons[status]}`}
            style={{ fontSize: "20px" }}
          />
          <h6
            className={`tb-status ms-1`}
            style={{
              color: `${PoStatusColors[status]}`,
              fontSize: "14px",
            }}
          >
            {status}
          </h6>
        </div>
      ),
    },

    {
      title: "View",
      key: "actions",
      fixed: "right",
      width: 120,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              setViewPODetails({
                status: record.status,
                orderNumber: record.orderNumber,
              });
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ]);

  const fetchPurchaseOrders = async () => {
    try {
      const res = await purchaseOrderApis.fetchPurchaseOrders();
      const { response, status, message } = res;
      console.log(response);
      if (status) {
        console.log(response);
        setRowData(response);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const FormatCurrency = (
    number: any,
    currencyFormat: any,
    currencySymbol: any
  ) => {
    if (currencyFormat == "") {
      currencyFormat = "₹#,##0.00";
    }
    if (currencySymbol == "") {
      currencySymbol = "₹";
    }
    // Define regex patterns to extract formatting details
    const symbolBeforePattern = /^([^#0,]+)?([#0.,]+)$/;
    const symbolAfterPattern = /^([#0.,]+)([^#0,]+)?$/;
    let locale = "en-US"; // Default locale
    let symbolPosition = "before"; // Default to symbol before the number
    let minimumFractionDigits = 2; // Default to 2 decimal places
    // Check for symbol position
    let matchBefore = currencyFormat?.match(symbolBeforePattern);
    let matchAfter = currencyFormat?.match(symbolAfterPattern);
    if (matchBefore) {
      symbolPosition = "before";
    } else if (matchAfter) {
      symbolPosition = "after";
    }
    // Check for fraction digits
    let fractionMatch = currencyFormat?.match(/[.,]0*$/);
    if (fractionMatch) {
      minimumFractionDigits = fractionMatch[0].length - 1;
    }
    // Create a new Intl.NumberFormat object
    let formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    });
    // Format the number
    let formattedNumber = formatter.format(number);
    if (formattedNumber == "NaN" || formattedNumber == "undefined") {
      return "";
    }
    // Add the currency symbol in the correct position
    if (symbolPosition === "before") {
      return currencySymbol + formattedNumber;
    } else {
      return formattedNumber + currencySymbol;
    }
  };

  return (
    <>
      <Head title="Purchase Order - Invoice" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <InvoiceAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      {viewPODetails ? (
                        <span>
                          PO {viewPODetails.orderNumber} -{" "}
                          <span
                            className={`tb-status`}
                            style={{
                              color: `${PoStatusColors[viewPODetails.status]}`,
                            }}
                          >
                            {viewPODetails.status}
                          </span>
                        </span>
                      ) : (
                        <span>Purchase Orders Invoice - Approvals </span>
                      )}
                    </BlockTitle>
                  </BlockHeadContent>
                  <Button
                    className="toggle"
                    style={{
                      visibility: viewPODetails ? "visible" : "hidden",
                    }}
                    color="primary"
                    onClick={() => setViewPODetails(null)}
                  >
                    <Icon name="arrow-left" />
                    <span>Back</span>
                  </Button>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <Block>
                <div
                  style={{
                    width: screenSize - 330 + "px",
                    minHeight: "200px",
                    marginTop: "20px",
                  }}
                >
                  <Table
                    className="customTable"
                    columns={columns.map((item: any, index) => ({
                      ...item,
                      // key: item.id,
                      index,
                    }))}
                    dataSource={rowData}
                    scroll={{ x: 1000 }}
                    bordered
                    size="small"
                    pagination={false}
                  />
                </div>
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default InvoiceApproval;
