import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";
import {Icon} from "../../../components/Component"
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";

function PaymentsAgingSummaryReport() {
  const navigate = useNavigate();

  const columns: any = [
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      fixed: "left",
      width: 200,
    },

    {
      title: "Outstanding Amount",
      dataIndex: "totalOutstandingAmount",
      key: "totalOutstandingAmount",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Overdue Amount",
      dataIndex: "totalOverdueAmount",
      key: "totalOverdueAmount",
      width: 230,

      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/payments-aging-summary-invoice-report/${record?.supplierId}`, {
                state: {
                  name: record?.supplierName,
                },
              });
            }}
          >
          {formatCurrency(text?.toString(),record?.currencyCode)}{" ("}{record?.overduePercentage}{"%)"}
          </span>
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Payments Aging Summary Report"
      moduleKey="paymentsAgingSummaryReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
        aging: true,
      }}
      previousPath="/view-accounts-payable-reports"
      extra={{
        tableScrollX: 900,
      }}
    />
  );
}

export default PaymentsAgingSummaryReport;