import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import {
  TableColumnsType,
} from "antd";
import Head from "../../layout/head/Head";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import { useNavigate } from "react-router";
import ServerSideTable from "../form-components/PaginationTableComponent";
import inventoryCreation from "../../api/inventory/inventoryCreation";
import dayjs from "dayjs";
import BOQAside from "./boqAside";
import boqApis from "../../api/boq/boq";

const BOQList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [sm, setSm] = useState(false);
    const navigate = useNavigate();
    const [screenSize, setScreenSize] = useState(0);
    const [mobileView, setMobileView] = useState(false);
    const [rowData, setRowData] = useState<any>([]);
    // const [viewPODetails, setViewPODetails] = useState<any>(null);
    const [searchText, setSearchText] = useState("");
    const [statusFilter, setStatusFilter] = useState<string | undefined>(
      undefined
    );
    const [originalData, setOriginalData] = useState([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [inputValues, setInputValues] = useState<any>({});
    const [showSubmit, setShowSubmit] = useState(false);
  
    const viewChange = () => {
      setScreenSize(window.innerWidth);
      if (window.innerWidth < 990) {
        setMobileView(true);
      } else {
        setMobileView(false);
        setSm(false);
      }
    };
    useEffect(() => {
      viewChange();
      // fetchAllInvoices();
      window.addEventListener("load", viewChange);
      window.addEventListener("resize", viewChange);
      const headerClick = () => setSm(false);
      document
        .getElementsByClassName("nk-header")[0]
        ?.addEventListener("click", headerClick);
  
      return () => {
        window.removeEventListener("resize", viewChange);
        window.removeEventListener("load", viewChange);
        document
          .getElementsByClassName("nk-header")[0]
          ?.removeEventListener("click", headerClick);
      };
    }, []);
    const [columns, setColumns] = useState<TableColumnsType<any>>([
      {
        title: "Target Name",
        dataIndex: "targetNumber",
        key: "targetNumber",
        sorter: true,
        width: 200
      },
      {
        title: "Site Name",
        dataIndex: "siteName",
        key: "siteName",
        sorter: true,
        width: 200,
      },
      {
        title: "Block / Tower",
        dataIndex: "blockName",
        key: "blockName",
        sorter: true,
        width: 200,
      },
      {
        title: "Contractor",
        dataIndex: "contractorName",
        key: "contractorName",
        sorter: true,
        width: 200,
      },

      {
        title: "BOQ Details",
        dataIndex: "createdUserName",
        key: "createdUserName",
        sorter: true,
        width: 200,
      },
      {
        title: "Limit GI",
        dataIndex: "issueStatus",
        key: "issueStatus",
        sorter: true,
        width: 200,
      },
      {
        title: "Consumption Alerts",
        dataIndex: "issueStatus",
        key: "issueStatus",
        sorter: true,
        width: 200,
      },
      {
        title: "Action",
        key: "operation",
        // fixed: "right",
        width: 150,
      },
    ]);
    const fetchData = async (params: any) => {
        console.log("params", params);
        try {
          const response = await boqApis.fetchConsumptionTarget(
            params.sortOrder,
            params.pageSize,
            params.direction,
            params.pageDiff,
            params.orderNumber
          );
          console.log("response", response);
          const data = response.response;
          console.log("data", data);
          return {
            data: data.list,
            totalCount: data.count,
          };
        } catch (error) {
          console.error("Failed to fetch data:", error);
          return {
            data: [],
            totalCount: 0,
          };
        }
      };
    
  return (
    <>
      <Head title="Material Issue" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <BOQAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      <span>BOQ List</span>
                    </BlockTitle>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <div>
                <div
                  style={{
                    width: screenSize - 330 + "px",
                    minHeight: "200px",
                    marginTop: "20px",
                  }}
                >
                  <ServerSideTable
                    columns={columns}
                    fetchData={fetchData} // Pass the fetchData function
                    rowKey={(record: any) => record.key}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Content>
    </>
  )
};

export default BOQList;
