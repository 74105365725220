import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Col, Row } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  TooltipComponent,
  PreviewAltCard,
} from "../../components/Component";
import { formatCurrency, formatCurrency2 } from "../../utils/Utils";
import { InfoCircleOutlined } from "@ant-design/icons"; // Import Info Icon from Ant Design
import InventoryAside from "./InventoryAside";
import InventoryWidgets from "./InventoryWidgets";
import MonthOnMonthInventoryTrend from "./MonthOnMonthInventoryTrend";
import AverageInventoryValueByCategory from "./AverageInventoryValueByCategory";
import AverageInventoryHoldingDaysByCategory from "./AverageInventoryHoldingDaysByCategory";
import InventoryPurchasedvsUtilized from "./InventoryPurchasedvsUtilized";
import dayjs, { Dayjs } from "dayjs";
import { Select, DatePicker } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import InventoryAnalyticsAPI from "../../api/inventory/InventoryAnalytics";
const { Option } = Select;
const { RangePicker } = DatePicker;
const InventoryAnalytics: FC = () => {
  const { data: currentUser } = useSelector(
    (state: RootState) => state.currentUser
  );
  const [site, setSite] = useState("");
  const [siteOptions, setSiteOptions] = useState<any>([]);
  const [dateRange, setDateRange] = useState("Quarter to Date");
  const [customDateRange, setCustomDateRange] = useState<any>();
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [monthlyInventoryData, setMonthlyInventoryData] = useState([]);

  const [widgetData, setWidgetData] = useState({});
  useEffect(() => {
    if (currentUser) {
      const { siteAccessibility } = currentUser;
      if (siteAccessibility) {
        const allSites: any = [];
        let _siteOptions: any = [];
        siteAccessibility?.forEach((item: any) => {
          allSites.push(item.siteID);
          _siteOptions.push({ label: item.siteName, value: item.siteID });
        });
        setSite(allSites.join(","));
        if (_siteOptions?.length > 1) {
          _siteOptions = [
            {
              label: "ALL",
              value: allSites?.join(","),
            },
            ..._siteOptions,
          ];
        }
        setSiteOptions(_siteOptions);
      }
    }
  }, [currentUser]);

  const convertToDates = (dateType: string) => {
    switch (dateType) {
      case "Month to Date":
        return {
          from: dayjs().startOf("month").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      case "Quarter to Date":
        return {
          from: dayjs()
            .subtract(3, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      case "Year to Date":
        return {
          from: dayjs().startOf("year").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      default:
        return {
          from: dayjs(customDateRange[0]).format("YYYY-MM-DD"),
          to: dayjs(customDateRange[1]).format("YYYY-MM-DD"),
        };
    }
  };

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  const handleDateRangeChange = (value: string) => {
    setDateRange(value);
    if (value !== "Custom Date") {
      setCustomDateRange(null);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const handleSiteChange = (value: string) => {
    setSite(value);
  };

  const handleCustomDateChange = (
    dates: [Dayjs | null, Dayjs | null] | null
  ) => {
    if (dates && dates[0] && dates[1]) {
      setCustomDateRange([dates[0], dates[1]]);
    } else {
      setCustomDateRange(null);
    }
  };

  useEffect(() => {
    if (site) {
      fetchMonthlyInventoryData();
      fetchWidgetData();
    }
  }, [site]);

  const fetchWidgetData = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const payload: any = {
        siteId: site,
        startDate: from,
        endDate: to,
      };
      const res = await InventoryAnalyticsAPI.fetchWidgetData(payload);
      const { response, status } = res;
      if (status) {
        console.log("response:::WidgetData", response);
        setWidgetData(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMonthlyInventoryData = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const payload: any = {
        siteId: site,
        startDate: from,
        endDate: to,
      };
      const res = await InventoryAnalyticsAPI.fetchMonthOnMonthInventoryTrend(
        payload
      );
      const { response, status } = res;
      if (status) {
        console.log("response", response);
        setMonthlyInventoryData(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Head title="Inventory Dashboard" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <InventoryAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Inventory Analytics</BlockTitle>
                    <BlockDes>
                      <p>Integrated Dashboard for Inventory</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent className="d-flex align-items-center"></BlockHeadContent>
                </BlockBetween>
                <div className="d-flex align-items-center flex-wrap">
                  <div style={{ marginRight: 16, marginTop: 8 }}>
                    <label style={{ fontWeight: "bold", marginRight: 8 }}>
                      Filter by Site:
                    </label>
                    <Select
                      defaultValue={
                        siteOptions?.length == 1 ? siteOptions[0].label : "ALL"
                      }
                      style={{ width: 150 }}
                      onChange={handleSiteChange}
                    >
                      {siteOptions?.map((item: any) => (
                        <Option value={item.value}>{item.label}</Option>
                      ))}
                    </Select>
                  </div>

                  <div style={{ marginTop: 8 }}>
                    <label style={{ fontWeight: "bold", marginRight: 8 }}>
                      Filter by Date Range:
                    </label>
                    <Select
                      defaultValue="Quarter to Date"
                      style={{ width: 200, marginRight: 16 }}
                      onChange={handleDateRangeChange}
                    >
                      <Option value="Month to Date">Month to Date</Option>
                      <Option value="Quarter to Date">Quarter to Date</Option>
                      <Option value="Year to Date">Year to Date</Option>
                      <Option value="Custom Date">Custom Date</Option>
                    </Select>
                    {dateRange === "Custom Date" && (
                      <RangePicker
                        value={
                          customDateRange
                            ? [
                                dayjs(customDateRange[0].toDate()),
                                dayjs(customDateRange[1].toDate()),
                              ]
                            : null
                        }
                        onChange={(dates, dateStrings) =>
                          handleCustomDateChange(dates)
                        }
                        style={{ width: 300 }}
                      />
                    )}
                  </div>
                </div>
              </BlockHead>
              <Block>
                <Row className="g-gs mt-2"></Row>
                <Row className="g-gs">
                  <Col lg="12">
                    {widgetData && (
                      <InventoryWidgets widgetResponse={widgetData} />
                    )}
                  </Col>
                </Row>
                <Row className="g-gs  mt-1">
                  <Col lg="12">
                    <PreviewAltCard className="card-full" bodyClass={undefined}>
                      {monthlyInventoryData.length > 0 && (
                        <MonthOnMonthInventoryTrend
                          monthlyInventory={monthlyInventoryData}
                        />
                      )}
                    </PreviewAltCard>
                  </Col>
                </Row>
                <Row className="g-gs  mt-1">
                  <Col lg="6">
                    <PreviewAltCard className="card-full" bodyClass={undefined}>
                      <AverageInventoryValueByCategory 
                      dates={convertToDates(dateRange)}
                      sites={site} />
                    </PreviewAltCard>
                  </Col>
                  <Col lg="6">
                    <PreviewAltCard className="card-full" bodyClass={undefined}>
                      <AverageInventoryHoldingDaysByCategory
                        dates={convertToDates(dateRange)}
                        sites={site}
                      />
                    </PreviewAltCard>
                  </Col>
                </Row>
                <Row className="g-gs  mt-1">
                  <Col lg="12">
                    <PreviewAltCard className="card-full" bodyClass={undefined}>
                      <InventoryPurchasedvsUtilized
                        dates={convertToDates(dateRange)}
                        sites={site}
                      />
                    </PreviewAltCard>
                  </Col>
                </Row>
                {/* <Row className="g-gs  mt-1">
                  <Col lg="6">
                    <PreviewAltCard className="card-full" bodyClass={undefined}>
                      <OverdueAgingAnalysis overdueData={overdueData} />
                    </PreviewAltCard>
                  </Col>
                  <Col lg="6">
                    <PreviewAltCard className="card-full" bodyClass={undefined}>
                      <ImmediatePayments paymentData={paymentData} />
                    </PreviewAltCard>
                  </Col>
                </Row>
                <Row className="g-gs  mt-1">
                  <Col lg="12">
                    <PreviewAltCard className="card-full" bodyClass={undefined}>
                      <MonthOnMonthOverdue
                        overdueData={monthOnMonthOverDue}
                        monthOnMonthOverDueAgingSupplierWishList={
                          monthOnMonthOverDueAgingSupplierWishList
                        }
                      />
                    </PreviewAltCard>
                  </Col>
                </Row> */}
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default InventoryAnalytics;
