import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../../layout/content/Content";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  UncontrolledDropdown,
} from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  BlockDes,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  PaginationComponent,
} from "../../../components/Component";
import Swal from "sweetalert2";
import consultantsApis from "../../../api/master/consultants";
import ConsultantMaster from "./ConsultantsMaster";
import { useNavigate } from "react-router-dom";
import FormatDate from "../DateFormater";
import Export from "../../../components/export/Export";
const Consultants: FC = () => {
  const navigate = useNavigate();
  const [sm, setSm] = useState(false);

  const [rowData, setRowData] = useState<any>([]);
  const [originalData, setOriginalData] = useState([]);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [tablesm, updateTableSm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [popUpContent, setPopUpContent] = useState<JSX.Element | null>(null);

  const fetchData = async () => {
    try {
      const { data, status, message } =
        await consultantsApis.fetchConsultants();
      if (status) {
        setRowData(data);
        setOriginalData(data); // Store the original data
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.message, "error");
    }
  };

  const deleteConsultant = async (id: any) => {
    try {
      const { status, message } = await consultantsApis.deleteConsultantById(
        id
      );
      if (status) {
        Swal.fire("Deleted!", "Consultant has been deleted.", "success");
        fetchData();
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response.data.message, "error");
    }
  };

  const handleDelete = (id: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteConsultant(id);
      }
    });
  };

  const handleModelPopUp = (id?: any, type?: string) => {
    const content = (
      <ConsultantMaster
        formAction={type}
        id={id}
        toggleModelPopUp={() => {
          setIsOpen(false);
          fetchData();
        }}
      />
    );
    setPopUpContent(content);
    setIsOpen(true);
  };

  const viewChange = () => {
    if (window.innerWidth < 990) {
    } else {
      setSm(false);
    }
  };

  useEffect(() => {
    fetchData();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  useEffect(() => {
    if (onSearchText !== "") {
      const searchText = onSearchText.toLowerCase();
  
      const filteredObject = originalData.filter((item: any) => {
        return Object.values(item).some((value: any) => {
          // Convert value to string and then perform case-insensitive search
          return value && value.toString().toLowerCase().includes(searchText);
        });
      });
      setRowData(filteredObject); // Update state with filtered data
    } else {
      setRowData(originalData); // Reset to original data
    }
  }, [onSearchText, originalData]);

  const sortFunc = (params: any) => {
    let defaultData = rowData;
    if (params === "asc") {
      let sortedData = defaultData.sort((a: any, b: any) =>
        a.createdDate.localeCompare(b.createdDate)
      );
      setRowData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a: any, b: any) =>
        b.createdDate.localeCompare(a.createdDate)
      );
      setRowData([...sortedData]);
    }
  };
  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = rowData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const onFilterChange = (e: any) => {
    setSearchText(e.target.value);
  };

  const addConsultant = () => {
    navigate(`/addConsultants`);
  };

  return (
    <React.Fragment>
      <Head title="Consultants"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Consultants
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {rowData.length} consultants.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => setSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-4">
                    <li>
                      <Export data={rowData} />
                    </li>
                    <li>
                      <Button
                        color="primary"
                        onClick={() => {
                          addConsultant();
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add </span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable
            className="card-stretch"
            bodyClassName={undefined}
            title={undefined}
          >
            <div className="card-inner position-relative card-tools-toggle">
              <div
                className={`card-search search-wrap active`}
              >
                <div className="card-body">
                  <div className="search-content" style={{ whiteSpace: "nowrap", display: "flex" }}>
                  <Button
                      className="search-back btn-icon toggle-search active"
                    >
                      <Icon name="search"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                   <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="btn btn-trigger btn-icon dropdown-toggle"
                      >
                        <Icon name="filter"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end className="dropdown-menu-xs">
                        <ul className="link-check">
                          <li>
                            <span>Show</span>
                          </li>
                          <li className={itemPerPage === 10 ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setItemPerPage(10);
                              }}
                            >
                              10
                            </DropdownItem>
                          </li>
                          <li className={itemPerPage === 15 ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setItemPerPage(15);
                              }}
                            >
                              15
                            </DropdownItem>
                          </li>
                        </ul>
                        <ul className="link-check">
                          <li>
                            <span>Order</span>
                          </li>
                          <li className={sort === "dsc" ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSortState("dsc");
                                sortFunc("dsc");
                              }}
                            >
                              DESC
                            </DropdownItem>
                          </li>
                          <li className={sort === "asc" ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSortState("asc");
                                sortFunc("asc");
                              }}
                            >
                              ASC
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
            </div>

            <DataTableBody compact className={undefined} bodyclass={undefined}>
              <DataTableHead>
                {/* <DataTableRow className={undefined} size={undefined}>
                  <span className="sub-text">#</span>
                </DataTableRow> */}
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Company Name</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Contact Person</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Email Address</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Phone Number</span>
                </DataTableRow>
                {/* <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Mailing Address</span>
                </DataTableRow> */}
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Business Description</span>
                </DataTableRow>
                {/* <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Years In Business</span>
                </DataTableRow> */}
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Created Date</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}></DataTableRow>
              </DataTableHead>
              {rowData.length > 0
                ? rowData.map((item: any, index: number) => {
                    return (
                      <DataTableItem
                        key={item.id}
                        className={undefined}
                        style={{ color: "black" }}
                      >
                        {/* <DataTableRow size="md" className={undefined}>
                          <span>{index + 1}</span>
                        </DataTableRow> */}
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.companyName}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.contactPerson}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.emailAddress}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.phoneNumber}</span>
                        </DataTableRow>
                        {/* <DataTableRow size="md" className={undefined}>
                          <span>{item.mailingAddress}</span>
                        </DataTableRow> */}
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.businessDescription}</span>
                        </DataTableRow>
                        {/* <DataTableRow size="md" className={undefined}>
                          <span>{item.yearsInBusiness}</span>
                        </DataTableRow> */}
                        <DataTableRow size="md" className={undefined}>
                          <span>{FormatDate(item.createdDate)}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span
                            className={`tb-status text-${
                              item.status === "Active" ? "success" : "danger"
                            }`}
                          >
                            {item.status}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <ul className="nk-tb-actions">
                            <li
                              className="nk-tb-action-hidden"
                              onClick={() => {
                                navigate(
                                  `${process.env.PUBLIC_URL}/ViewConsultantDetails/${item.consultantID}`
                                );
                              }}
                            >
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="focus"
                                direction="top"
                                text="View Details"
                                iconClass={undefined}
                              />
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <Link
                                        to={`${process.env.PUBLIC_URL}/ViewConsultantDetails/${item.consultantID}`}
                                      >
                                        <Icon name="focus"></Icon>
                                        <span>View Details</span>
                                      </Link>
                                    </li>
                                    <li
                                      onClick={() => handleDelete(item.consultantID)}
                                    >
                                      <Link to={`#`}>
                                        <Icon name="trash"></Icon>
                                        <span>Delete</span>
                                      </Link>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {rowData.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={rowData.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
      </Content>
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        className="modal-dialog-centered"
        size="lg"
      >
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setIsOpen(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        <ModalBody>{popUpContent}</ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Consultants;
