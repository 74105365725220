import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Alert, Form } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import {
  Icon,
  Button,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import { Row, Col } from "reactstrap";
import FormCheckbox from "../../form-components/CheckboxComponent";
import contractorsApis from "../../../api/master/contractors";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
const EmergencyResponseandContingencyPlans: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const navigate = useNavigate();
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const submitForm = (data: any) => {
    console.log("Final form data:", data);
    createContractor(data);
    // Add your API submission logic here
  };
  const emergencyProceduresWatch = watch("emergencyProcedures");

  useEffect(() => {
    const fileSizeCheck = (files: any) => {
      if (files && files[0]?.size && files[0].size > 2097152) {
        Swal.fire({
          icon: "error",
          title: "File size should be less than 2MB.",
        });
      }
    };

    fileSizeCheck(emergencyProceduresWatch);
  }, [emergencyProceduresWatch]);

  const createContractor = async (data: any) => {
    const {
      regulatoryCompliancedocsUpload,
      licensesAndPermitsdocsUpload,
      insuranceCoveragedocsUpload,
      ScopeofWorkdocsUpload,
      BillingandPaymentTermsdocsUpload,
      contractTermsdocsUpload,
      environmentalCompliancedocsUpload,
      wasteManagementProceduresdocsUpload,
      qualityAssurancePlandocsUpload,
      disputeResolutionProcessdocsUpload,
      emergencyProcedures,
    } = data;
    const filesData = [];
    const files = [];
    const formData: any = new FormData();
    if (regulatoryCompliancedocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "regulatoryCompliancedocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(regulatoryCompliancedocsUpload[0]);
    }
    if (licensesAndPermitsdocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "licensesAndPermitsdocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(licensesAndPermitsdocsUpload[0]);
    }
    if (insuranceCoveragedocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "insuranceCoveragedocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(insuranceCoveragedocsUpload[0]);
    }
    if (ScopeofWorkdocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "ScopeofWorkdocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(ScopeofWorkdocsUpload[0]);
    }
    if (BillingandPaymentTermsdocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "BillingandPaymentTermsdocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(BillingandPaymentTermsdocsUpload[0]);
    }
    if (contractTermsdocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "contractTermsdocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(contractTermsdocsUpload[0]);
    }
    if (environmentalCompliancedocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "environmentalCompliancedocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(environmentalCompliancedocsUpload[0]);
    }
    if (wasteManagementProceduresdocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "wasteManagementProceduresdocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(wasteManagementProceduresdocsUpload[0]);
    }
    if (qualityAssurancePlandocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "qualityAssurancePlandocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(qualityAssurancePlandocsUpload[0]);
    }
    if (disputeResolutionProcessdocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "disputeResolutionProcessdocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(disputeResolutionProcessdocsUpload[0]);
    }
    if (emergencyProcedures[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "emergencyProcedures",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(emergencyProcedures[0]);
    }

    delete data.regulatoryCompliancedocsUpload;
    delete data.licensesAndPermitsdocsUpload;
    delete data.insuranceCoveragedocsUpload;
    delete data.ScopeofWorkdocsUpload;
    delete data.BillingandPaymentTermsdocsUpload;
    delete data.contractTermsdocsUpload;
    delete data.environmentalCompliancedocsUpload;
    delete data.wasteManagementProceduresdocsUpload;
    delete data.qualityAssurancePlandocsUpload;
    delete data.disputeResolutionProcessdocsUpload;
    delete data.emergencyProcedures;
    formData.append("contractorData", JSON.stringify(data));
    formData.append("filesData", JSON.stringify(filesData));
    files.forEach((file) => {
      formData.append(`files`, file);
    });
    const response = await contractorsApis.createContractor(formData);
    const { status, message } = response;
    if (status) {
      Swal.fire("Created!", "Contractor has been created", "success");
      navigate(`/general-contractors`);
    } else {
      console.log("Error in form submission", message, response);
      let errorMessage = "An error occurred";
      let fullErrorMessage;
      if (typeof message == "string") {
        fullErrorMessage = `${errorMessage}\n${"-"}\n${message}`;
      } else {
        fullErrorMessage = `${errorMessage}\n${"-"}\n${response?.message?.join(
          ", "
        )}`;
      }
      // const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
      setAlertInfo({
        type: "error",
        message: fullErrorMessage,
        errors: [],
      });
    }
  };
  useEffect(() => {
    setAlertInfo({
      type: "", // 'success' or 'error'
      message: "",
      errors: [],
    });
  }, []);
  return (
    <>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col md="6">
            <FormInput
              name="emergencyContactPerson"
              label="Contact Person"
              type="text"
              placeholder="Enter Contact Person"
              // disabled={action === "view"}
            />
          </Col>
          <Col md="6">
            <FormInput
              name="emergencyContactNumber"
              // disabled={action === "view"}
              label="Contact Number"
              type="number"
              placeholder="Enter Contact Information"
              // pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
            />
          </Col>
          {/* <Col md="6">
            <FormInput
              name="emergencyProcedures"
              label="Upload Emergency Procedures"
              // disabled={action === "view"}
              type="file"
              placeholder="Upload Emergency Procedures"
            />
            {emergencyProceduresWatch && (
              <div>{emergencyProceduresWatch[0]?.name}</div>
            )}
          </Col> */}
          <Col lg="6">
            {emergencyProceduresWatch && emergencyProceduresWatch[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label className="form-label" htmlFor={"emergencyProcedures"}>
                    Upload Emergency Procedures
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`emergencyProcedures-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{emergencyProceduresWatch[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("emergencyProcedures", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
            <div
              style={{
                display: `${
                  emergencyProceduresWatch && emergencyProceduresWatch[0]?.name
                    ? "none"
                    : "block"
                }`,
              }}
            >
              <FormInput
                name="emergencyProcedures"
                label="Upload Emergency Procedures"
                type="file"
                placeholder="Docs Upload"
              />
            </div>
          </Col>
          {/* <Col md="6">
                    <FormInput
                        name="docsUpload3"
                        label="Docs Upload"
                        // disabled={action === "view"}
                        type="file"
                        placeholder="Docs Upload"
                    />
                </Col> */}
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Submit Form
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default React.memo(EmergencyResponseandContingencyPlans);
