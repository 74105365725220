import React from "react";
import ReportTemplate2 from "../reportTemplate2";
import { useNavigate } from "react-router";
import { render } from "@testing-library/react";
import dayjs from "dayjs";

function InventoryTrackReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Type",
      key: "actionType",
      dataIndex: "actionType",
      width: 100,
    },
    {
      title: "Date",
      key: "actionDate",
      dataIndex: "actionDate",
      width: 100,
      render: (date: string) => dayjs(date).format("DD-MMM-YYYY"),
    },
    {
      title: "Ref #",
      key: "refNumber",
      dataIndex: "actionType",
      width: 100,
      render: (_: any, record: any) => {
        switch (record?.actionType) {
          case "newInventory":
            return <span>-</span>;
          case "inward":
            return <span>{record?.grnId || "N/A"}</span>;
          case "handover":
            return <span>{record?.issueNumber || "N/A"}</span>;
          default:
            return <span>Unknown</span>; // Handle unexpected action types
        }
      },
    },
    {
      title: "Supplier",
      key: "supplierName",
      dataIndex: "supplierName",
      width: 250,
    },
    {
      title: "Material",
      key: "materialName",
      dataIndex: "materialName",
      width: 100,
    },
    {
      title: "UOM",
      key: "primaryUomName",
      dataIndex: "primaryUomName",
      width: 100,
    },
    {
      title: "Qty",
      dataIndex: "actualQuantity",
      key: "actualQuantity",
      width: 220,
      // render: (text: number, record: any) => {
      //   <div>
      //     <span>{text?.toLocaleString("en-IN")}</span>
      //   </div>;
      // },
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Inventory Track Report"
      moduleKey="InventoryTrackReport"
      columns={columns}
      filtersOption={{
        site: true,
        // contractor: true,
        material: true,
        category: true,
        date: true,
      }}
      previousPath="/view-inventory-reports"
    />
  );
}

export default InventoryTrackReport;
