import React, { useState, useEffect, useRef } from "react";
import { Select, Empty } from "antd";
import * as echarts from "echarts";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";
import MaterialOverrunDrawer from "./MaterialOverrunDrawer";
const { Option } = Select;

const categories = [
  { categoryID: "cat001", categoryName: "Steel" },
  { categoryID: "cat002", categoryName: "Cement" },
  { categoryID: "cat003", categoryName: "Electricals" },
  { categoryID: "cat004", categoryName: "HVAC" },
];

const MaterialWiseSpendAnalysis: React.FC<any> = ({
  materialWiseSpendAnalysisData,
}) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartInstanceRef = useRef<echarts.ECharts | null>(null);
  const [data, setData] = useState<any[]>(materialWiseSpendAnalysisData);
  console.log(
    "111::materialWiseSpendAnalysisData::11",
    materialWiseSpendAnalysisData
  );
  const disposeChart = () => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.dispose();
      chartInstanceRef.current = null;
    }
  };

  const initializeChart = (selectedData: any[]) => {
    if (chartContainerRef.current) {
      if (!chartInstanceRef.current) {
        chartInstanceRef.current = echarts.init(chartContainerRef.current);
      }

      const option = {
        tooltip: {
          trigger: "axis",
          formatter: (params: any) => {
            const budgetAllocated = formatCurrency(params[0].data.value, "INR");
            const amountSpent = formatCurrency(params[1].data.value, "INR");
            const variationColor =
              params[1].data.indicator === "up" ? "red" : "green";
            return `
                            <div>
                                <p><strong>${params[0].name}</strong></p>
                                <p>Budget Allocated: ${budgetAllocated}</p>
                                <p>Amount Spent: ${amountSpent}</p>
                                <p style="color: ${variationColor};">
                                    Variation: ${params[1].data.variation}% (${
              params[1].data.indicator === "up" ? "Over Spent" : "Under Spent"
            })
                                </p>
                            </div>
                        `;
          },
        },
        grid: {
          left: "3%",
          right: "0",
          bottom: "15%", // Increase this value to add more space for the legend
          top: "8%",
          containLabel: true,
        },
        legend: {
          data: ["Budget Allocated", "Amount Spent"],
          orient: "horizontal",
          bottom: "5%", // Adjust this to create space between the legend and axis labels
          right: "0",
        },
        xAxis: {
          type: "category",
          data: selectedData.map((item) => item.materialName),
          name: "Material",
          nameLocation: "middle",
          nameTextStyle: {
            fontWeight: "bold",
            fontSize: 14,
            padding: 20,
          },
        },
        yAxis: {
          type: "value",
          name: `Amount  (${"INR"})`,
          nameLocation: "middle",

          axisLabel: {
            formatter: (value: number) => formatCurrency2(value, "INR"),
          },
          nameTextStyle: {
            fontWeight: "bold",
            fontSize: 14,
            padding: 50,
          },
        },
        series: [
          {
            name: "Budget Allocated",
            type: "bar",
            data: selectedData.map((item) => ({
              value: item.budgetAllocated,
              indicator: item.indicator,
              variation: item.variation,
            })),
            itemStyle: {
              color: "#3BAEA3",
            },
            barWidth: "20%",
          },
          {
            name: "Amount Spent",
            type: "bar",
            data: selectedData.map((item) => ({
              value: item.spent,
              indicator: item.indicator,
              variation: item.variation,
            })),
            itemStyle: {
              color: "#5470C6",
            },
            barWidth: "20%",
          },
        ],
      };

      chartInstanceRef.current.setOption(option);
    }
  };

  useEffect(() => {
    if (materialWiseSpendAnalysisData?.length > 0) {
      initializeChart(materialWiseSpendAnalysisData);
    } else {
      disposeChart();
    }
  }, [materialWiseSpendAnalysisData]);

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

  const openDrawer = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };
  return (
    <div>
      <div
        className="card-title-group mb-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="card-title">
          <h6 className="title">Material Wise Spend Analysis</h6>
          <p>Insights on budget spends for different materials by category.</p>
        </div>
        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 8 }}>Filter by Category:</span>
                    <Select
                        placeholder="Select Category"
                        style={{ width: 200 }}
                        value={selectedCategoryID}
                        onChange={handleCategoryChange}
                    >
                        {categories.map(category => (
                            <Option key={category.categoryID} value={category.categoryID}>
                                {category.categoryName}
                            </Option>
                        ))}
                    </Select>
                </div> */}
      </div>
      {/* {JSON.stringify(materialWiseSpendAnalysisData)} */}
      {materialWiseSpendAnalysisData?.length !=0 ? (
        <div
          ref={chartContainerRef}
          id="budget-overrun-chart"
          style={{ width: "100%", height: "300px" }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          <Empty description="No data available to generate or display the graph." />
        </div>
      )}
      <MaterialOverrunDrawer
        visible={drawerVisible}
        onClose={closeDrawer}
        data={materialWiseSpendAnalysisData}
        currency={"INR"}
        categories={categories}
      />
      {materialWiseSpendAnalysisData?.length! > 0 && (
        <div style={{ marginTop: 20, fontSize: 16, textAlign: "center" }}>
          <a href="" onClick={openDrawer}>
            Click here to view more details
          </a>
        </div>
      )}
    </div>
  );
};

export default MaterialWiseSpendAnalysis;
