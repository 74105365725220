import React, { useEffect, useState } from "react";
import {
  Drawer,
  Button,
  Form,
  Input,
  Select,
  Space,
  Row,
  Col,
  message,
  Alert,
  Popover,
  Table,
} from "antd";
import { Spinner } from "reactstrap";
import { Option } from "antd/es/mentions";
import {
  PaperClipOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
  UploadOutlined,
  PlusSquareOutlined,
  EditOutlined,
} from "@ant-design/icons";
import SiteInventoryAllocationApprovalsDrawer from "./SiteInventoryAllocationApprovalsDrawer";
import usersApis from "../../api/master/users";
import sites from "../../api/master/sites";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/Component";
import { useDispatch, useSelector } from "react-redux";
import { fetchSequenceAccess } from "../../store/slices/misc/sequenceAccess";
import contractorsApis from "../../api/master/contractors";
const SiteInventoryAllocationDrawer: React.FC<any> = ({
  visible,
  onClose,
  site,
  lines,
  onSubmit,
}) => {
  const dispatch: any = useDispatch();
  const { data: sequenceAccess } = useSelector(
    (state: any) => state.sequenceAccess
  );
  const [siteData, setSiteData] = useState(site);
  const [sitesOptions, setSitesOptions] = useState<any[]>([]);
  const [blockOptions, setBlockOptions] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [showError, setShowError] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [approvals, setApprovals] = useState({
    open: false,
    data: [],
  });
  const [contractorOptions, setContractorOptions] = useState<any>([]);
  const [approvalRequired, setApprovalRequired] = useState("false");
  const handleFormSubmit = (values: any) => {
    values.siteId = sitesOptions.find(
      (item: any) => item.value == String(site)
    )?.value;
    console.log("Form data on submit:", values);
    setDisableSubmit(true);
    onSubmit({ FormData: values, approvalsData: approvals?.data });
    setDisableSubmit(false);
  };
  useEffect(() => {
    fetchSites();
  }, []);

  useEffect(() => {
    dispatch(fetchSequenceAccess());
  }, [dispatch]);

  const columns = [
    {
      title: "#",
      key: "#",
      width: 50,
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Material Category",
      dataIndex: "categoryName",
      key: "categoryName",
      width: 200,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
    },
    {
      title: "UOM",
      dataIndex: "primaryUomName",
      key: "primaryUomName",
      width: 200,
    },
    {
      title: "Qty Available For Utilization",
      dataIndex: "quantityAvailable",
      key: "quantityAvailable",
      width: 220,
      render: (text: number, record: any) => (
        <div>
          <span>{text?.toLocaleString("en-IN")}</span>
        </div>
      ),
    },
    {
      title: "Qty To Issue",
      dataIndex: "uomQty",
      key: "uomQty",
      editable: true,
      width: 170,
    },
  ];

  const handleSelectionChange = (value: any) => {
    console.log("value", value);
    fetchBlocks(value);
  };

  const fetchBlocks = async (value: any) => {
    try {
      const data = await sites.fetchBlockById(value);
      if (data) {
        console.log("data", data);
        let blockOptions = data?.data?.map((item: any) => ({
          label: item.blockName,
          value: item.blockID.toString(),
        }));
        setBlockOptions(blockOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const fetchContractorList = async (siteId: any) => {
    try {
      if (!siteId) return;
      const { data, status } = await contractorsApis.fetchContractorBySiteId({
        id: siteId + "",
      });
      if (status) {
        let allContractors: any = [];
        let _contractorOptions: any = [];
        data.forEach((item: any) => {
          allContractors.push(item?.contractorID?.toString());
          _contractorOptions.push({
            label: item?.companyName,
            value: item?.contractorID?.toString(),
          });
        });
        if (_contractorOptions.length > 1) {
          _contractorOptions = [
            ..._contractorOptions,
          ];
        }
        setContractorOptions(_contractorOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        console.log("sitesOptions", sitesOptions);
        setSitesOptions(sitesOptions);
        form.setFieldValue(
          "siteId",
          sitesOptions.find((item: any) => item.value === String(site))?.label
        );
        fetchBlocks(site);
        fetchContractorList(site);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      title="Material Issue Create"
      width={"80vw"}
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          {disableSubmit ? (
            <Button color="primary" type="primary">
              <Spinner size="sm" color="light" />
            </Button>
          ) : (
            <Button onClick={() => form.submit()} type="primary">
              Submit
            </Button>
          )}
        </div>
      }
    >
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <div className="justify-start w-100  flex-wrap pt-3">
        {!sequenceAccess?.includes("Material Issue") ? (
          <Form.Item
            name="issueId"
            label={<div className="data-label w-100 mb-1">Issue Number</div>}
            className="w-50 p-2 paddingLeft10  pt-0 pb-0"
            rules={[
              {
                required: true,
                message: "Please Enter",
              },
            ]}
          >
            <Input  placeholder="Issue Id" className="w-100" />
          </Form.Item>
        ) : (<></>)}
          <Form.Item
            name="siteId"
            label={<div className="data-label w-100 mb-1">Site</div>}
            className="w-50 p-2 paddingLeft10 pt-0 pb-0"
          >
            <Input disabled type="text" placeholder="Site" className="w-100" />
          </Form.Item>
          <Form.Item
            name="bloceId"
            label={<div className="data-label w-90 mb-1">Block</div>}
            className="w-50 p-3 paddingLeft10  pt-0 pb-0"
            rules={[
              {
                required: true,
                message: "Please select",
              },
            ]}
          >
            <Select placeholder="Select Block">
              {blockOptions.map((item: any) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="contractorId"
            label={<div className="data-label w-90 mb-1">Contractor</div>}
            className="w-50 p-3 paddingLeft10  pt-0 pb-0"
            rules={[
              {
                required: true,
                message: "Please select",
              },
            ]}
          >
            <Select placeholder="Select Block">
              {contractorOptions.map((item: any) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="approvals"
            label={
              <div className="data-label w-100 mb-1">Approval Required ?</div>
            }
            className="w-50 p-3 paddingLeft10 pt-0 pb-0"
            rules={[
              {
                required: true,
                message: "Please select approvals",
              },
            ]}
          >
            <Select
              placeholder="Select Approvals"
              value={approvalRequired}
              onChange={(e) => {
                form.setFieldValue("approvals", e);
                setApprovalRequired(() => e);
              }}
            >
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
            {approvalRequired == "true" && (
              <Popover
                content="Click here to set required approvals"
                title="Approval Required"
              >
                <a
                  href="#"
                  className="success"
                  onClick={(e) => {
                    e.preventDefault();
                    setApprovals((pre) => {
                      return {
                        ...pre,
                        open: true,
                      };
                    });
                  }}
                >
                  <EditOutlined /> Set Approvals &nbsp;
                  {approvals?.data?.length == 0 ? (
                    <span className="mandatory">Please set approvals</span>
                  ) : (
                    <></>
                  )}
                </a>
              </Popover>
            )}
          </Form.Item>
        </div>
      </Form>

      <Row className="mt-3">
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">
                <span> Materials Issued</span>
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <div>
          <div
            style={{
              // width: screenSize - 330 + "px",
              minHeight: "200px",
              marginTop: "20px",
            }}
          >
            <Table
              columns={columns}
              dataSource={lines}
              rowKey="paginationId"
              bordered
              size="middle"
            />
          </div>
        </div>
      </Row>

      {approvals?.open && (
        <SiteInventoryAllocationApprovalsDrawer
          open={approvals.open}
          siteId={site}
          onClose={() => {
            setApprovals((pre) => {
              return {
                ...pre,
                open: false,
              };
            });
          }}
          onSubmit={(data: any) => {
            setApprovals({
              open: false,
              data: data,
            });
          }}
          setApprovalSubmissionStatus={(status: boolean) => {}}
          approvalData={approvals.data} // Pass the current approval data
        />
      )}
    </Drawer>
  );
};

export default SiteInventoryAllocationDrawer;
