import React, { FC, useEffect, useState } from "react";
import * as echarts from "echarts";
import { Button, Empty, Select } from "antd";
import supplierApis from "../../api/master/supplier";
import InventoryAnalyticsAPI from "../../api/inventory/InventoryAnalytics";
import { useNavigate } from "react-router";
const { Option } = Select;
const AverageInventoryValueByCategory: FC<any> = ({ dates, sites }) => {
  const navigate = useNavigate();
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [category, setCategory] = useState("");
  const [chartData, setChartData] = useState<any | null>(null);
  useEffect(() => {
    fetchCategories();
  }, []);
  const fetchCategories = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const allCategory: any = [];
        const _categoryOptions: any = [];
        data?.forEach((item: any) => {
          allCategory.push(item.id);
          _categoryOptions.push({ label: item.category, value: item.id });
        });
        setCategory(allCategory.join(","));
        setCategoryOptions([
          {
            label: "All Categories",
            value: allCategory?.join(","),
          },
          ..._categoryOptions,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (sites && category) {
      fetchData();
    }
  }, [sites, category, dates]);

  const fetchData = async () => {
    try {
      const { from, to } = dates;
      const payload: any = {
        siteId: sites,
        categoryId: category,
        startDate: from,
        endDate: to,
      };
      const res =
        await InventoryAnalyticsAPI.fetchAverageInventoryValueByMaterial(
          payload
        );
      const { response, status } = res;
      if (status) {
        console.log("response:::InventoryValueByMaterial", response);
        const materials = response.map((item: any) => item.materialName);
        const inventoryValue = response.map((item: any) =>
          parseFloat(item.inventoryQty)
        );
        setChartData({
          materials,
          inventoryValue,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const chartDom = document.getElementById(
      "averageInventoryValueByCategory-chart"
    ) as HTMLElement;
    if (chartDom) {
      const myChart = echarts.init(chartDom);

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow", // Highlights the bar
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          name: "Inventory Value",
          axisLabel: {
            formatter: "₹{value}",
          },
        },
        yAxis: {
          type: "category",
          name: "Material",
          data: chartData?.materials,
        },
        series: [
          {
            name: "Inventory Value",
            type: "bar",
            data: chartData?.inventoryValue, // Example values
            itemStyle: {
              color: "#5470c6", // Customize the bar color
            },
            barWidth: "40%", // Adjust bar width
          },
        ],
      };

      myChart.setOption(option);

      return () => {
        myChart.dispose();
      };
    }
  }, [chartData]);

  const handleCategoryChange = (value: string) => {
    setCategory(value.toString());
  };

  return (
    <div>
      <div
        className="card-title-group mb-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="card-title">
          <h6 className="title">Average Inventory Value By Material</h6>
          <p>
            <Button type="link" onClick={() => {
              navigate("/inventory-value-report-list")
            }}>
              Click here to View Details
            </Button>
          </p>
        </div>

        <div>
          <span style={{ fontWeight: "bold" }}> Filter by Category: </span>
          <Select
            defaultValue="ALL"
            style={{ width: 150 }}
            onChange={handleCategoryChange}
          >
            {categoryOptions?.map((item: any) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </div>
      </div>
      <div
        id="averageInventoryValueByCategory-chart"
        style={{
          width: "100%",
          height: "400px",
          display: `${chartData?.materials?.length > 0 ? "block" : "none"}`,
        }}
      />
      {chartData?.materials?.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          <Empty description="No data available to generate or display the graph." />
        </div>
      )}
    </div>
  );
};

export default AverageInventoryValueByCategory;
