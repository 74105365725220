import makeApiCall from "../master/axiosIndex";

const inventoryCreation = {
  fetchSiteInventory: (
    sortOrder: any,
    pageSize: any,
    direction: any,
    pageDiff: any,
    paginationId: any,
    siteId:any
  ): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/inventory/siteInventory/list",
      params: { sortOrder, pageSize, direction, pageDiff, paginationId,siteId },
    }),
  fetchMaterialIssue: (
    sortOrder: any,
    pageSize: any,
    direction: any,
    pageDiff: any,
    paginationId: any
  ): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/inventory/materialIssue/list",
      params: { sortOrder, pageSize, direction, pageDiff, paginationId },
    }),
  fetchPendingApprovals: (
    sortOrder: any,
    pageSize: any,
    direction: any,
    pageDiff: any,
    paginationId: any
  ): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/inventory/materialIssue/pendingApprovals",
      params: { sortOrder, pageSize, direction, pageDiff, paginationId },
    }),
  createSiteIssue: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/inventory/materialIssue/new",
      data,
    }),
  fetchViewMaterialDetails: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/inventory/materialIssue/id/${id}`,
      // params:id
    }),
    approve: (id: any): Promise<any> =>
      makeApiCall<any>({
        method: "put",
        url: `/inventory/materialIssue/approve`,
        params: { id },
      }),
    reject: (id: any, reason: any): Promise<any> =>
      makeApiCall<any>({
        method: "put",
        url: `/inventory/materialIssue/reject`,
        params: { id, reason },
      }),
};

export default inventoryCreation;
