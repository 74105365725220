import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Form,
  Modal as AntModal,
  Empty,
  Flex,
  Spin,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import BudgetAside from "./BudgetAside";
import ViewBudgetDetails from "./ViewBudgetDetails";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import budgetApis from "../../api/master/budget";
import { useNavigate } from "react-router";
import { formatCurrency } from "../../utils/Utils";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}
const widgetData: WidgetData[] = [
  {
    title: "Draft RFQs",
    count: 10,
    progress: 30,
    color: "#OO65BD",
  },
  {
    title: "PENDING",
    count: 5,
    progress: 50,
    color: "#20639B",
  },
  {
    title: "In Progress",
    count: 7,
    progress: 70,
    color: "#FA991C",
  },
  {
    title: "Converted to POs",
    count: 12,
    progress: 90,
    color: "#F6D55C",
  },
  {
    title: "Delivered",
    count: 15,
    progress: 100,
    color: "#OOCC99",
  },
];
const orderStatusCards: any = {
  // draft: {
  //   color: "#e85347",
  //   lable: "Draft Purchase Order",
  // },

  pending: {
    color: "#09c2de",
    lable: "PENDING",
  },
  withSuppliers: {
    color: "#364a63",
    lable: "Order With Suppliers",
  },
  invoiced: {
    color: "#1ee0ac",
    lable: "Invoiced",
  },
  // deliveredGrn: {
  //   color: "#2263b3",
  //   lable: "Delivered GRN",
  // },
  shortClosed: {
    color: "rgb(249, 140, 69)",
    lable: "Short Closed",
  },
};
const PoStatusColors: any = {
  PENDING: "#09c2de",
  Approved: "#1ee0ac",
  // Rejected: "#e85347",
  // "Pending Change Approval": "#09c2de",
  // "Accepted by Supplier": "#1ee0ac",
  // "Rejected by Supplier": "#e85347",
  // "To be Invoiced": "#2263b3",
  // "To be Delivered": "#2263b3",
};
const PoStatusIcons: any = {
  PENDING: "alert-circle",
  "To be Invoiced": "alert-circle",
  Approved: "check-circle",
  Rejected: "info",
  "Pending Change Approval": "alert-circle",
  "Accepted by Supplier": "check-circle",
  "To be Delivered": "check-circle",
  "Rejected by Supplier": "info",
};
const PoStatusIconsColors: any = {
  PENDING: "secondary",
  "To be Invoiced": "secondary",
  Approved: "success",
  Rejected: "danger",
  "Pending Change Approval": "secondary",
  "To be Delivered": "success",
  "Accepted by Supplier": "success",
  "Rejected by Supplier": "danger",
};

const PendingApprovalBudget: FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [viewBudgetDetails, setViewBudgetDetails] = useState<any>(null);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState<string | undefined>(
    undefined
  );
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rejectRecord, setRejectRecord] = useState<any>(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  useEffect(() => {
    console.log("11111:::Usefeect");
    viewChange();
    fetchBudget();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, [viewBudgetDetails]);
  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Budget Id",
      dataIndex: "budgetId",
      key: "budgetId",
      width: 120,
      fixed: "left",
      // render: (text) => (
      //   <div className="align-left">
      //     <span>{text}</span>
      //   </div>
      // ),
    },
    {
      title: "Budget Name",
      dataIndex: "budgetName",
      key: "budgetName",
      width: 150,
      fixed: "left",
      render: (text) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    // {
    //   title: "Budget For",
    //   dataIndex: "budgetFor",
    //   key: "budgetFor",
    //   width: 120,
    // },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Contractor",
      dataIndex: "contractorName",
      key: "contractorName",
      width: 200,
    },
    {
      title: "Planned Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 120,
      render: (date) => <span>{FormatDate(date)}</span>,
    },
    {
      title: "Planned End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: 120,
      render: (date) => <span>{FormatDate(date)}</span>,
    },
    {
      title: "Budget Value",
      dataIndex: "budgetValue",
      key: "budgetValue",
      width: 200,
      render: (text: any, record: any) => (
        <p className="justify-end">
          {formatCurrency(
            Number(text),
            "INR"
          )}
        </p>
      ), 
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (status: any) => (
        <div className="justify-start align-center">
          <Icon
            className={`text-${PoStatusIconsColors[status]}`}
            name={`${PoStatusIcons[status]}`}
            style={{ fontSize: "20px" }}
          />
          <h6
            className={`tb-status ms-1`}
            style={{
              color: `${PoStatusColors[status]}`,
              fontSize: "14px",
            }}
          >
            {status}
          </h6>
        </div>
      ),
    },

    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 160, // Increase width to accommodate additional buttons
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(`/view_budget_details/${record.headerId}`);
              // setViewBudgetDetails({
              //   status: record.status,
              //   headerId: record.headerId,
              // });
            }}
            
          >
            <Icon name="focus" />
          </Button>

          <Button
            size="sm"
            color="success"
            onClick={() => {
              // Handle approve action here
              handleApprove(record.headerId);
            }}
            className="mx-1" // Adds a small margin between buttons
          >
            <Icon name="check" title="Approve" />
          </Button>

          <Button
            size="sm"
            color="danger"
            onClick={() => {
              // Handle reject action here
              handleReject(record.headerId);
            }}
          >
            <Icon name="cross" tile="Reject" />
          </Button>
        </>
      ),
    },
  ]);

  const handleApprove = async (id: string) => {
    try {
      const res = await budgetApis.approveBudget(id);
      const { response, status, message } = res;
      console.log("data", res);
      if (status) {
        Swal.fire("Approved!", "Budget has been Approved", "success");
        fetchBudget();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };
  const handleReject = async (record: any) => {
    console.log("record", record);
    setRejectRecord(record);
    setIsModalVisible(true);
    // try {
    //   const res = await budgetApis.rejectBudget(id);
    //   const { response, status, message } = res;
    //   console.log("data", res);
    //   if (status) {
    //     Swal.fire("Rejected!", "Budget has been Rejected", "success");
    //     fetchBudget();
    //   } else {
    //     Swal.fire("Failed!", message, "error");
    //   }
    // } catch (error) {
    //   Swal.fire("Failed!", "Something went wrong", "error");
    // }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      // Assuming `record.headerId` is used to identify the purchase order
      const payload = {
        headerId: rejectRecord,
        reason: values.reason,
      };

      // Call the API to submit the rejection
      await apiCallToRejectBudget(rejectRecord, values.reason);

      // Handle success (e.g., show a success message, refresh the table)

      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      // Handle validation errors or API errors
    }
  };

  const apiCallToRejectBudget = async (headerId: any, reason: string) => {
    // Replace this with your actual API call logic
    try {
      const res = await budgetApis.rejectBudget(headerId, { reason: reason });
      const { response, status, message } = res;
      console.log("data", response);
      if (res.status) {
        Swal.fire("Rejected!", "Budget has been Rejected", "success");
        fetchBudget();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleStatusFilterChange = (value: string | undefined) => {
    setStatusFilter(value);
    filterTable(searchText, value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
    filterTable(value, statusFilter);
  };

  const filterTable = (searchText: string, status: string | undefined) => {
    let _filteredData: any = originalData;
    if (searchText) {
      console.log("searchText", searchText);

      _filteredData = _filteredData.filter(
        (item: any) =>
          item.orderNumber
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.description.toLowerCase().includes(searchText.toLowerCase()) ||
          item.siteName.toLowerCase().includes(searchText.toLowerCase()) ||
          item.createdUserName
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.supplierName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (status && status !== "ALL") {
      console.log("status", status);
      console.log("filteredData", _filteredData);
      _filteredData = _filteredData?.filter(
        (item: any) => item.orderStatus == status
      );
    }
    console.log("filteredData------", _filteredData);

    setFilteredData(_filteredData);
  };
  const fetchBudget = async () => {
    try {
      const res = await budgetApis.pendingApprovals();
      const { response, status, message } = res;
      console.log("data", res);
      if (status) {
        let data = response.map((item: any) => item.header);
        setOriginalData(data);
        setFilteredData(data);
      } else {
        Swal.fire("Error", message, "error");
      }
      setIsLoading(false);
    } catch (error) {}
  };

  const FormatCurrency = (
    number: any,
    currencyFormat: any,
    currencySymbol: any
  ) => {
    if (currencyFormat == "") {
      currencyFormat = "₹#,##0.00";
    }
    if (currencySymbol == "") {
      currencySymbol = "₹";
    }
    // Define regex patterns to extract formatting details
    const symbolBeforePattern = /^([^#0,]+)?([#0.,]+)$/;
    const symbolAfterPattern = /^([#0.,]+)([^#0,]+)?$/;
    let locale = "en-US"; // Default locale
    let symbolPosition = "before"; // Default to symbol before the number
    let minimumFractionDigits = 2; // Default to 2 decimal places
    // Check for symbol position
    let matchBefore = currencyFormat?.match(symbolBeforePattern);
    let matchAfter = currencyFormat?.match(symbolAfterPattern);
    if (matchBefore) {
      symbolPosition = "before";
    } else if (matchAfter) {
      symbolPosition = "after";
    }
    // Check for fraction digits
    let fractionMatch = currencyFormat?.match(/[.,]0*$/);
    if (fractionMatch) {
      minimumFractionDigits = fractionMatch[0].length - 1;
    }
    // Create a new Intl.NumberFormat object
    let formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    });
    // Format the number
    let formattedNumber = formatter.format(number);
    if (formattedNumber == "NaN" || formattedNumber == "undefined") {
      return "";
    }
    // Add the currency symbol in the correct position
    if (symbolPosition === "before") {
      return currencySymbol + formattedNumber;
    } else {
      return formattedNumber + currencySymbol;
    }
  };

  return (
    <>
      <Head title="Budget - View" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <BudgetAside updateSm={setSm} sm={sm} />
            </div>
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      {viewBudgetDetails == null && <span>Budget - Pending Approvals</span>}
                    </BlockTitle>
                  </BlockHeadContent>
                  <Button
                    className="toggle"
                    style={{
                      visibility: viewBudgetDetails ? "visible" : "hidden",
                    }}
                    color="primary"
                    onClick={() => setViewBudgetDetails(null)}
                  >
                    <Icon name="arrow-left" />
                    <span>Back</span>
                  </Button>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              {viewBudgetDetails == null ? (
                <>
               
                {filteredData.length > 0 ? (
                <div>
                  <Block>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div>
                        <span>Search by Status: </span>
                        <Select
                          style={{ width: 200 }}
                          onChange={handleStatusFilterChange}
                          value={statusFilter || "ALL"}
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <div
                                style={{
                                  padding: "8px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>Search by Status: </span>
                                <SearchOutlined />
                              </div>
                            </>
                          )}
                        >
                          <Option value="ALL">All</Option>
                          {Object.keys(PoStatusColors).map((item, index) => (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Input
                          placeholder="Search by Site Name &amp; Budget Id"
                          value={searchText}
                          onChange={handleSearchChange}
                          style={{ width: 400 }}
                          prefix={<SearchOutlined />}
                        />
                      </div>
                    </div>
                  </Block>

                  <div
                    style={{
                      width: screenSize - 330 + "px",
                      minHeight: "200px",
                      marginTop: "20px",
                    }}
                  >
                    <Table
                      className="customTable"
                      columns={columns.map((item: any, index) => ({
                        ...item,
                        index,
                      }))}
                      dataSource={filteredData}
                      pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "30", "50"],
                      }}
                      bordered
                      size="middle"
                      scroll={{ x: 1400 }}
                    />
                  </div>
                </div>
                ):(
                  <>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                      <Empty description="No Pending Budget Approvals" />
                  </div>
              </>
              )}
                 </>
              ) : (
                <Block>
                  <ViewBudgetDetails BudgetData={viewBudgetDetails} />
                </Block>
              )}
            </div>
            )}
          </div>
        </Card>
        <AntModal
          title="Reject Budget"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Submit"
          cancelText="Cancel"
        >
          <Form form={form} layout="vertical" name="rejectForm">
            <Form.Item
              name="reason"
              label="Reason for Rejection"
              rules={[{ required: true, message: "Please input your reason!" }]}
            >
              <Input.TextArea placeholder="Type your reason here..." />
            </Form.Item>
          </Form>
        </AntModal>
      </Content>
    </>
  );
};

export default PendingApprovalBudget;
