import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Badge, ModalBody, Spinner } from "reactstrap";
import Head from "../../../layout/head/Head";
import { Block, Icon } from "../../../components/Component";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import {
  Table,
  Collapse as AntCollapse,
  Button as AntButton,
  TableColumnsType,
  Descriptions,
  List,
  Modal,
  Timeline,
  Popover,
  Empty,
} from "antd";
import FormatDate from "../../masters/DateFormater";
import supplierApis from "../../../api/master/supplier";
import costCenterApis from "../../../api/master/costCenter";
import sites from "../../../api/master/sites";
import GeneralLedgerApis from "../../../api/master/generalLedger";
import departments from "../../../api/master/departments";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
import adminOrgApis from "../../../api/master/adminOrg";
import { PaperClipOutlined, DownloadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import purchaseOrderCreation from "../../../api/purchase-orders/pruchaseOrderCreation";
import moment from "moment";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router";
import rolesApi from "../../../api/master/roles";
import dayjs from "dayjs";
import usersApis from "../../../api/master/users";
import purchaseRequestApis from "../../../api/master/purchaseRequest";
import { set } from "date-fns";
import contractorsApis from "../../../api/master/contractors";
import boqApis from "../../../api/boq/boq";

interface SelectOptions {
  label: string;
  value: string;
}

const { Panel } = AntCollapse;
const ConsumptionTarget_Preview: React.FC = (props: any) => {
  const navigate = useNavigate();
  const { watch, register } = useFormContext();
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const { control, handleSubmit } = useForm();
  const previousFormData = watch();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [submitData, setSubmitData] = useState<any>("SUBMIT");
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [blockOptions, setBlockOptions] = useState<SelectOptions[]>([]);
  const [contractorOptions, setContractorOptions] = useState<SelectOptions[]>(
    []
  );
  const [columns, setColumns] = useState<TableColumnsType<any>>([
    // {
    //   ...Table.EXPAND_COLUMN,
    //   fixed: "left",
    // },
    // {
    //     title: "#",
    //     key: "#",
    //     width: 50,
    //     fixed: "left",
    //     render: (text, record, index) => index + 1,
    // },
    {
      title: "Material Category",
      dataIndex: "categoryName",
      key: "categoryName",
      width: 200,
      fixed: "left",
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
      fixed: "left",
    },
    {
      title: "Qty",
      dataIndex: "quantitySet",
      key: "quantitySet",
      width: 150,
      // render: (text, record) => `${text} ${record?.uomLabel}`,
    },
    {
      title: "Target Per Sft",
      dataIndex: "targetPerSft",
      key: "targetPerSft",
      width: 150,
    },
    // {
    //     title: "Description",
    //     dataIndex: "description",
    //     key: "description",
    //     width: 150,
    // }
  ]);

  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBlockList = async (siteId: any) => {
    try {
      const { data, status } = await sites.fetchBlockById(siteId);
      if (status) {
        let blockOptions = data.map((item: any) => ({
          label: item.blockName,
          value: item.blockID.toString(),
        }));
        setBlockOptions(blockOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContractorList = async (siteId: any) => {
    try {
      const { data, status } = await contractorsApis.fetchContractorBySiteId({
        id: siteId,
      });
      if (status) {
        let contractorOptions = data.map((item: any) => ({
          label: item?.companyName,
          value: item?.contractorID?.toString(),
        }));
        setContractorOptions(contractorOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("previousFormData", previousFormData);
    fetchSites();
    if (previousFormData.siteId) {
      fetchBlockList(previousFormData.siteId);
      fetchContractorList(previousFormData.siteId);
    }
  }, []);

  const onSubmit = (status: string) => async (data: any) => {
    let submitData  = {
      header: {
        targetNumber: previousFormData.targetNumber,
        siteId: previousFormData.siteId && Number(previousFormData.siteId),
        blockId: previousFormData.blockId && Number(previousFormData.blockId),
        contractorId: previousFormData.contractorId && Number(previousFormData.contractorId),
        limitPostTargetConsumption: previousFormData.limitPostTargetConsumption == "Yes" ? 1 : 0,
        setAlerts: previousFormData.setAlerts ? [previousFormData.setAlerts && Number(previousFormData.setAlerts)] : null,
      },
      lines: previousFormData.lines.map((item: any) => ({
        materialId: item.materialId && Number(item.materialId),
        categoryId: item.categoryId && Number(item.categoryId),
        quantitySet: item.quantitySet && Number(item.quantitySet),
      }))
    }
    console.log("submitData", submitData);
    setDisableSubmit(true);
    try {
      const { data, status } = await boqApis.newConsumptionTarget(submitData);
      if (status) {
        setDisableSubmit(false);
        setSubmitData(status);
        // navigate("/boq/boq-creation/boq-list");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Head title="New - BOQ " />
      <div className="content clearfix">
        <form>
          <AntCollapse defaultActiveKey={["1", "2"]}>
            <Panel header="General Information" key="1">
              {/* <div className="tab-content mt-0"> */}
              {/* <div className={`tab-pane${tab === "1" && " active"}`}> */}
              <Col className="justify-start w-100  flex-wrap mt-3 p-3">
                <div className="w-50 border-end ">
                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-45">BOQ Number </div>
                    <div className="data-value w-55 ">
                      {previousFormData.targetNumber}
                    </div>
                  </div>
                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-45">Site</div>
                    <div className="data-value w-55 ">
                      {
                        sitesOptions.find(
                          (item) => item.value == previousFormData.siteId
                        )?.label
                      }
                    </div>
                  </div>
                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-45">Site</div>
                    <div className="data-value w-55 ">
                      {
                        sitesOptions.find(
                          (item) => item.value == previousFormData.siteId
                        )?.label
                      }
                    </div>
                  </div>
                </div>
                <div className="w-50 paddingLeft10  ">
                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-30">Block</div>
                    <div className="data-value w-70 ">
                      {
                        blockOptions.find(
                          (item) => item.value == previousFormData.blockId
                        )?.label
                      }
                    </div>
                  </div>
                  <div className="data-col align-center pt-1">
                    <div className="data-label w-30">Contractor</div>
                    <div className="data-value w-70">
                      {
                        contractorOptions.find(
                          (item) => item.value == previousFormData.contractorId
                        )?.label
                      }
                    </div>
                  </div>
                  <div className="data-col align-center pt-1">
                    <div className="data-label w-30">
                      Limit Goods/Materials Post Target Consumption?{" "}
                    </div>
                    <div className="data-value w-70">
                      {previousFormData.limitPostTargetConsumption}
                    </div>
                  </div>
                </div>
              </Col>
              {/* </div> */}
              {/* </div> */}
            </Panel>
            <Panel header="Material Information" key="2">
              <div className="table-responsive poTable">
                <div
                  style={{
                    width: screenSize - 400 + "px",
                    margin: "auto",
                  }}
                >
                  {columns && (
                    <Table
                      className="customTable"
                      columns={columns}
                      dataSource={previousFormData?.lines}
                      scroll={{ x: 1000 }}
                      bordered
                      size="small"
                      pagination={false}
                    />
                  )}
                </div>
              </div>
            </Panel>
          </AntCollapse>
          <div className="actions clearfix">
            <ul>
              <li>
                <Button
                  color="primary"
                  onClick={() => {
                    props.prev();
                  }}
                  className="btn-dim btn btn-secondary"
                >
                  Previous
                </Button>
              </li>
              <li>
                {disableSubmit ? (
                  <Button color="primary" type="button">
                    <Spinner size="sm" color="light" />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    type="submit"
                    onClick={() => {
                      setSubmitData("SUBMIT"); // Set submitData to "SUBMITTED"
                      handleSubmit(onSubmit("SUBMIT"))(); // Trigger form submission
                    }}
                  >
                    Submit
                  </Button>
                )}
              </li>
            </ul>
          </div>
        </form>
      </div>
    </>
  );
};

export default ConsumptionTarget_Preview;
