import React, { useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Row, Col, Button, Modal, ModalBody, Badge } from "reactstrap";
import { Block, Icon } from "../../components/Component";
import FormSelect from "../form-components/SelectComponent";
import TaxConfigurationModal from "./TaxConfigurationModel";
import SignatureModal from "./SignatureModal";
import CropperComponent from "../components/CropperComponent";
import misc from "../../api/misc/dropDowns";
import { nanoid } from "nanoid";
import { Table } from "antd";
import type { TableColumnsType } from "antd";
import MaterialModal from "./MaterialModal";
import FormInput from "../form-components/InputComponent";
import { PaperClipOutlined } from "@ant-design/icons";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { formatCurrency } from "../../utils/Utils";

type Cess = {
  cessName: string;
  cessType?: string;
  cessPercentage?: string;
  cessAmount?: string;
};
interface TaxConfiguration {
  taxType: string;
  gstType?: string; //IGST or CGST+SGST
  cess?: Cess[];
}

interface CurrencyData {
  description: string;
  currency_code: string;
  currency_symbol: string;
  currency_format: string;
  example: string;
  id: any;
}

const PurchaseOrder_Materails: React.FC = (props: any) => {
  const [generalDetails, setGeneralDetails] = useState<any>();
  const [activeMaterial, setActiveMaterial] = useState<any>({
    action: null,
    index: null,
    item: null,
  });
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [showConfigureTaxModel, setShowConfigureTaxModel] =
    useState<boolean>(false);
  const [taxConfiguration, setTaxConfiguration] = useState<TaxConfiguration>();
  const [allCurrencyData, setAllCurrencyData] = useState<CurrencyData[]>([]);
  const [signature, setSignature] = useState({
    isActive: false,
    label: "Authorised Signatory",
    image: null,
  });
  const [currency, setCurrency] = useState<CurrencyData>({
    description: "",
    currency_code: "INR",
    currency_symbol: "",
    currency_format: "",
    example: "",
    id: "",
  });
  const [showSignatureModel, setShowSignatureModel] = useState<boolean>(false);
  const [showCropModel, setShowCropModel] = useState<boolean>(false);
  const [needByDateType, setNeedByDateType] = useState<string>("");
  const [deliveryAddressType, setDeliveryAddressType] = useState<string>("");
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const { control, reset, setValue, watch, handleSubmit } = useFormContext();

  const previousFormDate = watch();

  const fetchDeliveryAddress = async () => {
    try {
      const { data, status, message } =
        await deliveryAddressApis.fetchDeliveryAddress();

      if (status) {
        setDeliveryAddressList(data);
      } else {
        console.log(message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchDeliveryAddress();
    const {
      needByDateType,
      deliveryAddressType,
      taxConfigurationObj,
      signatureObj,
      currencyObj,
    } = previousFormDate;
    setGeneralDetails(previousFormDate);
    if (needByDateType) {
      setNeedByDateType(needByDateType);
    }
    if (deliveryAddressType) {
      setDeliveryAddressType(deliveryAddressType);
    }
    if (taxConfigurationObj) {
      onConfigureTaxSave(JSON.parse(taxConfigurationObj), false);
    }
    if (signatureObj) {
      setSignature(JSON.parse(signatureObj));
    }
    if (currencyObj) {
      // alert(currencyObj);
      setCurrency(JSON.parse(currencyObj));
    }
  }, []);

  const ShowAddressPopOver = ({ id }: any) => {
    if (!id) {
      return <></>;
    }
    let address = deliveryAddressList?.find((item: any) => item?.id == id);
    if (!address) {
      return <></>;
    }
    return (
      <ul>
         <li>
          <strong style={{ color: "#2263b3" }}>
            {address?.deliveryLocationName}
          </strong>
        </li>
        <li>{address?.addressLine1}</li>
        <li>{address?.addressLine2}</li>
        <li>
          {address?.city}, {address?.stateName}, {address?.countryName} -{" "}
          {address?.pincode}
        </li>
      </ul>
    );
  };

  useEffect(() => {
    setValue("signatureObj", JSON.stringify(signature));
  }, [signature]);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "items",
  });

  const [columns, setColumns] = useState<TableColumnsType<any>>([
    // {
    //   ...Table.EXPAND_COLUMN,
    //   fixed: "left",
    // },
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Material Name",
      dataIndex: "materialLabel",
      key: "materialLabel",
      width: 200,
      fixed: "left",
    },
    {
      title: "NEED BY DATE",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 150,
      hidden: generalDetails?.needByDateType === "Line Level" ? false : true,
      render: (text) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "HSN / SAC",
      dataIndex: "hsnCode",
      key: "hsnCode",
      width: 100,
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text, record) => `${text} ${record?.uomLabel}`,
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 150,
    },
    {
      title: "Basic Amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
    },
    {
      title: "CGST",
      children: [
        {
          title: "%",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text)}%`,
        },
        {
          title: "Amount",
          dataIndex: "cgstAmount",
          key: "cgstAmount",
          width: 150,
          render: (text: any, record: any) => {
            return (
              <p className="justify-end">
                {formatCurrency(Number(text), currency?.currency_code)}
              </p>
            );
          },
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "%",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text)}%`,
        },
        {
          title: "Amount",
          dataIndex: "sgstAmount",
          key: "sgstAmount",
          width: 150,
          render: (text: any, record: any) => {
            return (
              <p className="justify-end">
                {formatCurrency(Number(text), currency?.currency_code)}
              </p>
            );
          },
        },
      ],
    },
    {
      title: "Line Total",
      key: "lineTotal",
      width: 150,
      render: (text, record: any) => {
        return (
          <p className="justify-end">
            {formatCurrency(Number(record?.lineTotal) || 0, "")}
          </p>
        );
      },
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (text, record, index) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              setActiveMaterial({
                action: "edit",
                index: index,
                item: record,
              });
            }}
          >
            <Icon name="edit" />
          </Button>
          <Button size="sm" color="" onClick={() => remove(index)}>
            <Icon name="trash" />
          </Button>
          {!(
            generalDetails?.needByDateType == "Order Level" &&
            generalDetails?.deliveryAddressType == "Order Level"
          ) && (
            <Button
              size="sm"
              color=""
              onClick={() => {
                setActiveMaterial({
                  action: "edit",
                  index: index,
                  item: { ...record, id: nanoid(), needByDate: null },
                });
              }}
            >
              <Icon name="copy" />
            </Button>
          )}
        </>
      ),
    },
  ]);

  const onConfigureTaxSave = (data: any, updateField = true) => {
    setValue("taxConfigurationObj", JSON.stringify(data));
    setTaxConfiguration(data);
    setShowConfigureTaxModel(false);
    if (updateField) {
      fields.map((item: any, index: number, array: any) => {
        if (data?.gstType == "IGST") {
          let igst = item.cgst;
          let igstPercentage = item.cgstPercentage * 2;
          let igstAmount = item.cgstAmount * 2;
          let cgst = null;
          let sgst = null;
          let cgstPercentage = null;
          let cgstAmount = null;
          let sgstPercentage = null;
          let sgstAmount = null;
          update(index, {
            ...item,
            igst,
            igstPercentage,
            igstAmount,
            cgst,
            sgst,
            cgstPercentage,
            cgstAmount,
            sgstPercentage,
            sgstAmount,
          });
        } else if (data?.gstType == "CGST+SGST") {
          let igst = null;
          let igstPercentage = null;
          let igstAmount = null;
          let cgst = item.igst;
          let sgst = item.igst;
          let cgstPercentage = item.igstPercentage / 2;
          let cgstAmount = item.igstAmount / 2;
          let sgstPercentage = item.igstPercentage / 2;
          let sgstAmount = item.igstAmount / 2;

          update(index, {
            ...item,
            igst,
            igstPercentage,
            igstAmount,
            cgst,
            sgst,
            cgstPercentage,
            cgstAmount,
            sgstPercentage,
            sgstAmount,
          });
        }
      });
    }

    setColumns((pre) => {
      if (!data?.cess?.length) return pre;
      let newColArray = [...pre].slice(0, 7);
      let additionalCol: any = [];
      if (data?.gstType == "IGST") {
        additionalCol.push({
          title: "IGST",
          children: [
            {
              title: "%",
              dataIndex: "igstPercentage",
              key: "igstPercentage",
              width: 100,
              render: (text: any) => `${Number(text)}%`,
            },
            {
              title: "Amount",
              dataIndex: "igstAmount",
              key: "igstAmount",
              width: 150,
              render: (text: any, record: any) => {
                return (
                  <p className="justify-end">
                    {formatCurrency(Number(text), currency?.currency_code)}
                  </p>
                );
              },
            },
          ],
        });
      } else {
        additionalCol.push({
          title: "CGST",
          children: [
            {
              title: "%",
              dataIndex: "cgstPercentage",
              key: "cgstPercentage",
              width: 100,
              render: (text: any) => `${Number(text)}%`,
            },
            {
              title: "Amount",
              dataIndex: "cgstAmount",
              key: "cgstAmount",
              width: 150,
              render: (text: any, record: any) => {
                return (
                  <p className="justify-end">
                    {formatCurrency(Number(text), currency?.currency_code)}
                  </p>
                );
              },
            },
          ],
        });
        additionalCol.push({
          title: "SGST",
          children: [
            {
              title: "%",
              dataIndex: "sgstPercentage",
              key: "sgstPercentage",
              width: 100,
              render: (text: any) => `${Number(text)}%`,
            },
            {
              title: "Amount",
              dataIndex: "sgstAmount",
              key: "sgstAmount",
              width: 150,
              render: (text: any, record: any) => {
                return (
                  <p className="justify-end">
                    {formatCurrency(Number(text), currency?.currency_code)}
                  </p>
                );
              },
            },
          ],
        });
      }
      if (data?.cess.length) {
        data.cess.forEach((item: any) => {
          if (!item.cessName) return;
          additionalCol.push({
            title: item.cessName,
            children: [
              {
                title: "%",
                dataIndex: item.cessName,
                key: item.cessName,
                width: 100,
                render: (text: any) => `${text || 0}%`,
              },
              {
                title: "Amount",
                dataIndex: `${item.cessName}Amount`,
                key: `${item.cessName}Amount`,
                width: 150,
              },
            ],
          });
        });
      }
      additionalCol.push({
        title: "Line Total",
        key: "lineTotal",
        width: 150,
        render: (text: any, record: any) => {
          return (
            <p className="justify-end">
              {formatCurrency(
                Number(record?.lineTotal),
                currency?.currency_code
              )}
            </p>
          );
        },
      });
      additionalCol.push({
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 150,
        render: (text: any, record: any, index: any) => (
          <>
            <Button
              size="sm"
              color=""
              onClick={() => {
                setActiveMaterial({
                  action: "edit",
                  index: index,
                  item: record,
                });
              }}
            >
              <Icon name="edit" />
            </Button>
            <Button size="sm" color="" onClick={() => remove(index)}>
              <Icon name="trash" />
            </Button>
            {(generalDetails?.needByDateType == "Line Level" ||
              generalDetails?.deliveryAddressType == "Line Level") && (
              <Button
                size="sm"
                color=""
                onClick={() => {
                  setActiveMaterial({
                    action: "edit",
                    index: null,
                    item: {
                      ...record,
                      id: nanoid(),
                      needBYDate: null,
                    },
                  });
                }}
              >
                <Icon name="copy" />
              </Button>
            )}
          </>
        ),
      });

      return newColArray.concat(additionalCol);
    });
  };

  const updateColumnsWithCurrencySymbol = useCallback(() => {
    setColumns((prevColumns) => {
      return prevColumns.map((col: any) => {
        if (col.dataIndex == "unitPrice" || col.dataIndex == "amount") {
          return {
            ...col,
            render: (text: any) => (
              <div className="justify-end">
                {formatCurrency(text, currency?.currency_code)}
              </div>
            ),
          };
        }
        if (col.key == "lineTotal") {
          return {
            title: "Line Total",
            key: "lineTotal",
            width: 150,
            render: (text: any, record: any) => {
              return (
                <p className="justify-end">
                  {formatCurrency(
                    Number(record?.lineTotal),
                    currency?.currency_code
                  )}
                </p>
              );
            },
          };
        }
        if (col.children) {
          let children = col.children.map((child: any) => {
            if (child.title == "Amount") {
              return {
                ...child,
                render: (text: any) => (
                  <div className="justify-end">
                    {formatCurrency(text, currency?.currency_code)}
                  </div>
                ),
              };
            }
            return child;
          });
          return {
            ...col,
            children,
          };
        }
        return col;
      });
    });
  }, [currency]);

  useEffect(() => {
    updateColumnsWithCurrencySymbol();
  }, [currency, updateColumnsWithCurrencySymbol]);

  const updateColumnsWithNeedByDateType = useCallback(() => {
    setColumns((prevColumns) => {
      return prevColumns.map((col: any) => {
        if (
          col.dataIndex === "needByDate" &&
          generalDetails?.needByDateType != "Line Level"
        ) {
          return {
            ...col,
            hidden: true,
          };
        } else if (
          col.dataIndex === "needByDate" &&
          generalDetails?.needByDateType == "Line Level"
        ) {
          return {
            ...col,
            hidden: false,
          };
        } else if (col.key == "operation") {
          return {
            title: "Action",
            key: "operation",
            fixed: "right",
            width: 150,
            render: (text: any, record: any, index: any) => (
              <>
                <Button
                  size="sm"
                  color=""
                  onClick={() => {
                    setActiveMaterial({
                      action: "edit",
                      index: index,
                      item: record,
                    });
                  }}
                >
                  <Icon name="edit" />
                </Button>
                <Button size="sm" color="" onClick={() => remove(index)}>
                  <Icon name="trash" />
                </Button>
                {!(
                  generalDetails?.needByDateType == "Order Level" &&
                  generalDetails?.deliveryAddressType == "Order Level"
                ) && (
                  <Button
                    size="sm"
                    color=""
                    onClick={() => {
                      setActiveMaterial({
                        action: "edit",
                        index: null,
                        item: {
                          ...record,
                          id: nanoid(),
                          needByDate: null,
                          deliveryAddressId: null,
                          quoteShortListId: null,
                          supplierQuoteLineId: null,
                        },
                      });
                    }}
                  >
                    <Icon name="copy" />
                  </Button>
                )}
              </>
            ),
          };
        }
        return col;
      });
    });
  }, [generalDetails]);

  useEffect(() => {
    updateColumnsWithNeedByDateType();
  }, [generalDetails, setGeneralDetails]);

  const FormatCurrency = (
    number: any,
    currencyFormat: any,
    currencySymbol: any
  ) => {
    if (currencyFormat == "") {
      currencyFormat = "₹#,##0.00";
    }
    if (currencySymbol == "") {
      currencySymbol = "₹";
    }
    const symbolBeforePattern = /^([^#0,]+)?([#0.,]+)$/;
    const symbolAfterPattern = /^([#0.,]+)([^#0,]+)?$/;
    let locale = "en-US"; // Default locale
    let symbolPosition = "before"; // Default to symbol before the number
    let minimumFractionDigits = 2; // Default to 2 decimal places
    let matchBefore = currencyFormat?.match(symbolBeforePattern);
    let matchAfter = currencyFormat?.match(symbolAfterPattern);
    if (matchBefore) {
      symbolPosition = "before";
    } else if (matchAfter) {
      symbolPosition = "after";
    }
    let fractionMatch = currencyFormat?.match(/[.,]0*$/);
    if (fractionMatch) {
      minimumFractionDigits = fractionMatch[0].length - 1;
    }
    let formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    });
    let formattedNumber = formatter.format(number);
    if (formattedNumber == "NaN" || formattedNumber == "undefined") {
      return "";
    }
    if (symbolPosition === "before") {
      return currencySymbol + formattedNumber;
    } else {
      return formattedNumber + currencySymbol;
    }
  };

  const addNewRow = () => {
    setActiveMaterial({
      action: "add",
      index: null,
      item: null,
    });
  };

  const fetchCurrencyData = async () => {
    try {
      const res = await misc.getCurrencyTypes();
      const { data, status } = res;
      if (status) {
        let allCurrencyData = data.map((item: any) => {
          return {
            description: item.description,
            currency_code: item.currency_code,
            currency_symbol: item.currency_symbol,
            currency_format: item.currency_format,
            example: item.example,
            id: item.id,
          };
        });
        setAllCurrencyData(allCurrencyData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    fetchCurrencyData();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  return (
    <>
      <Block>
        <form className="form-validate" onSubmit={handleSubmit(() => {})}>
          <Row>
            <Col lg="2">
              <button
                className="btn-dim btn btn-outline-primary"
                onClick={() => setShowConfigureTaxModel(true)}
                style={{ marginTop: 8 }}
                type="button"
              >
                <Icon name="percent"></Icon> &nbsp; &nbsp; Configure TAX &nbsp;
              </button>
            </Col>
                    <span style={{ display: "none" }}>
            <Col lg="4" className="inline-select">
              <label className="form-label" style={{ margin: "0" }}>
                Currency
              </label>
              <FormSelect
                name="currency"
                label=""
                options={allCurrencyData.map((item: any) => ({
                  label: item.description,
                  value: item.currency_code,
                }))}
                required={false}
                placeholder="Indian Rupee"
                onChange={(value) => {
                  const currency = allCurrencyData.find(
                    (item) => item.currency_code === value
                  );
                  if (!currency) return;
                  setValue("currencyObj", JSON.stringify(currency));
                  // alert(JSON.stringify(currency));
                  setCurrency(currency);
                }}
              />
            </Col>
              <FormInput name="currencyObj" label="" type="text" />
              <FormInput name="taxConfigurationObj" label="" type="text" />
              <FormInput name="signatureObj" label="" type="text" />
            <Col lg="4" style={{ marginTop: "10px" }} className="inline-select">
              <label className="form-label" style={{ margin: "0" }}>
                Currency Format ( e.g ) :
              </label>
              <p
                className="text-primary"
                style={{ fontSize: "18px", marginLeft: "10px" }}
              >
                {currency?.example || "₹1,23,456.78"}
              </p>
            </Col>
            </span>
          </Row>
          {showConfigureTaxModel && (
            <TaxConfigurationModal
              data={taxConfiguration}
              isOpen={showConfigureTaxModel}
              setIsOpen={setShowConfigureTaxModel}
              onSave={onConfigureTaxSave}
            />
          )}

          <Row>
            <div className="table-responsive poTable">
              <div style={{ width: screenSize - 120 + "px", margin: "auto" }}>
                {columns && (
                  <Table
                    className="customTable"
                    columns={columns}
                    dataSource={fields}
                    scroll={{ x: "max-content" }}
                    expandable={{
                      expandedRowRender: (record: any) => (
                        <div className="expandableBody">
                          <div style={{ flex: 1, textAlign: "left" }}>
                            <p style={{ margin: 0 }}>{record.description}</p>
                            {record.file && record.file[0] && (
                              <div>
                                <PaperClipOutlined /> &nbsp;
                                <a
                                  href={URL.createObjectURL(record.file[0])}
                                  download={record.file[0]?.name}
                                >
                                  {record.file[0]?.name}
                                </a>
                              </div>
                            )}
                          </div>
                          {deliveryAddressType === "Line Level" &&
                            record.deliveryAddressId && (
                              <div className="lineAddress" style={{ flex: 1 }}>
                                <h6>Delivery Address</h6>
                                <ShowAddressPopOver
                                  id={record.deliveryAddressId}
                                />
                              </div>
                            )}
                        </div>
                      ),
                    }}
                    locale={{
                      emptyText: (
                        <span
                          style={{
                            height: "300px",
                            color: "#333",
                            fontSize: "16px",
                          }}
                        >
                          Please click on "Add New Item" to add purchase order
                          lines.
                        </span>
                      ),
                    }}
                    bordered
                    size="small"
                    pagination={false}
                  />
                )}
              </div>

              <Row
                style={{
                  margin: "20px",
                }}
              >
                <Col className="justify-between">
                  <a onClick={addNewRow} className="addNewItem">
                    <Icon name="plus-round" />
                    <span>Add New Item</span>
                  </a>
                  <div className="w-40">
                    <div className="d-flex justify-content-between">
                      <div className="w-50 justify-end">
                        <strong>Taxable Amount</strong>
                      </div>
                      <span className="r_align">
                        {formatCurrency(
                          fields.reduce((a, b: any) => a + Number(b.amount), 0),
                          currency?.currency_code
                        )}
                      </span>
                    </div>
                    {fields.reduce((a, b: any) => a + Number(b.cgstAmount), 0) +
                      fields.reduce(
                        (a, b: any) => a + Number(b.sgstAmount),
                        0
                      ) >
                    0 ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <div className="w-50 justify-end">
                            <strong>CGST</strong>
                          </div>
                          <span className="r_align">
                            {formatCurrency(
                              fields.reduce(
                                (a, b: any) => a + Number(b.cgstAmount),
                                0
                              ),
                              currency?.currency_code
                            )}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="w-50 justify-end">
                            <strong>SGST</strong>
                          </div>
                          <span className="r_align">
                            {formatCurrency(
                              fields.reduce(
                                (a, b: any) => a + Number(b.sgstAmount),
                                0
                              ),
                              currency?.currency_code
                            )}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-between">
                        <div className="w-50 justify-end">
                          <strong>IGST</strong>
                        </div>
                        <span className="r_align">
                          {formatCurrency(
                            fields.reduce(
                              (a, b: any) => a + Number(b.igstAmount),
                              0
                            ),
                            currency?.currency_code
                          )}
                        </span>
                      </div>
                    )}
                    <div
                      className="d-flex justify-content-between"
                      style={{ fontSize: "18px", marginTop: "10px" }}
                    >
                      <div className="w-50 justify-end">
                        <strong>Total</strong>
                      </div>
                      <strong className="r_align">
                        {formatCurrency(
                          fields.reduce(
                            (a, b: any) => a + Number(b.lineTotal),
                            0
                          ),
                          currency?.currency_code
                        )}
                      </strong>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="m-4 justify-content-end ">
                {signature?.isActive ? (
                  <div className="signatureBtnWrapper">
                    <div className="d-flex justify-content-between align-items-center">
                      <strong>Signature</strong>
                      <a
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSignature((prev) => ({
                            ...prev,
                            isActive: false,
                            image: null,
                            label: "Authorised Signatory",
                          }));
                          setValue("signatureObj", {
                            isActive: false,
                            image: null,
                            label: "Authorised Signatory",
                          });
                        }}
                      >
                        <Icon
                          name="cross"
                          className="pointer"
                          onClick={() => {
                            setSignature((prev) => ({
                              ...prev,
                              isActive: false,
                              image: null,
                              label: "Authorised Signatory",
                            }));
                            setValue("signatureObj", {
                              isActive: false,
                              image: null,
                              label: "Authorised Signatory",
                            });
                          }}
                        />
                      </a>
                    </div>
                    <div
                      style={{
                        minHeight: signature?.image ? "0px" : "120px",
                        display: signature?.image ? "block" : "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!signature?.image && (
                        <Button
                          color="btn-dim btn"
                          onClick={() => {
                            setShowCropModel(true);
                          }}
                        >
                          <Icon
                            name="upload-cloud"
                            className="pointer"
                            onClick={() => {
                              setShowCropModel(true);
                            }}
                          />
                          <span>Upload</span>
                        </Button>
                      )}
                      {signature?.image && (
                        <>
                          <div className="signatueImgWrapper border">
                            <img
                              src={signature?.image}
                              alt="Signed"
                              style={{ width: "160px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              color="btn-dim btn"
                              onClick={() => {
                                setSignature((pre) => {
                                  return { ...pre, image: null };
                                });
                              }}
                            >
                              <span>Reset</span>
                              <Icon
                                name="redo"
                                className="pointer"
                                onClick={() => {
                                  setSignature((pre) => {
                                    return { ...pre, image: null };
                                  });
                                }}
                              />
                            </Button>
                          </div>
                          <div>
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="signatureLabel"
                              >
                                Signature Label
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="signatureLabel"
                                onChange={(e) => {
                                  setSignature((pre) => {
                                    return {
                                      ...pre,
                                      label: e.target.value,
                                    };
                                  });
                                }}
                                defaultValue={
                                  signature?.label || "Authorised Signatory"
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {!signature?.image && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            color="btn-dim btn"
                            onClick={() => setShowSignatureModel(true)}
                          >
                            <Icon name="pen" />
                            <span>Use Signature Pad</span>
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      border: "1px solid #e5ecf7",
                      backgroundColor: "#f7faff",
                      padding: "10px",
                      marginLeft: "10px",
                      width: "22%",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      color="btn-dim btn"
                      onClick={() => {
                        setSignature((pre) => {
                          return { ...pre, isActive: true };
                        });
                      }}
                    >
                      <Icon
                        name="edit"
                        className="pointer"
                        onClick={() => {
                          setSignature((pre) => {
                            return { ...pre, isActive: true };
                          });
                        }}
                      />
                      <span>Add Signature</span>
                    </Button>
                  </div>
                )}
              </Row>
            </div>
          </Row>

          {showSignatureModel && (
            <SignatureModal
              modal={showSignatureModel}
              toggle={() => setShowSignatureModel(!showSignatureModel)}
              onSave={(data: string) => {
                setSignature((pre: any) => {
                  return { ...pre, image: data };
                });
                setShowSignatureModel(false);
              }}
            />
          )}
          <div className="actions clearfix">
            <ul>
              <li>
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => {
                    if (fields.length == 0) {
                      Swal.fire({
                        icon: "info",
                        title: "Material",
                        text: "Please Add Material",
                      });
                      return;
                    }
                    if (!signature?.image) {
                      Swal.fire({
                        icon: "info",
                        title: "Signature",
                        text: "Signature is required",
                      });
                      return;
                    }
                    props.next();
                  }}
                >
                  Next
                </Button>
              </li>
              <li>
                <Button
                  color="primary"
                  onClick={props.prev}
                  className="btn-dim btn btn-secondary"
                >
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </Block>
      {showCropModel && (
        <CropperComponent
          modal={showCropModel}
          toggle={() => setShowCropModel(!showCropModel)}
          onSave={(data: string) => {
            setSignature((pre: any) => {
              return { ...pre, image: data };
            });
            setShowCropModel(false);
          }}
        />
      )}
      {activeMaterial.action && (
        <MaterialModal
          modal={!!activeMaterial.action}
          closeModal={() => {
            setActiveMaterial({
              action: null,
              index: null,
              item: null,
            });
          }}
          taxConfiguration={taxConfiguration}
          append={(data: any) => {
            const _data = fields.find((item: any) => {
              if (
                item.materialName == data.materialName &&
                dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                  dayjs(data.needByDate).format("DD-MMM-YYYY") &&
                item.deliveryAddressId == data.deliveryAddressId
              )
                return true;
            });

            if (_data) {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Material Already Added",
              });
              return;
            }
            append(data);
          }}
          update={(index: any, data: any) => {
            const ItemsList = [...fields];
            const _data = ItemsList?.filter(
              (item: any, idx) => idx != index
            )?.find((item: any) => {
              if (
                item.materialName == data.materialName &&
                dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                  dayjs(data.needByDate).format("DD-MMM-YYYY") &&
                item.deliveryAddressId == data.deliveryAddressId
              )
                return true;
            });

            if (_data) {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Material Already Added",
              });
              return;
            }
            update(index, data);
          }}
          formData={activeMaterial}
          needByDateType={needByDateType}
          deliveryAddressType={deliveryAddressType}
        />
      )}
    </>
  );
};

export default PurchaseOrder_Materails;
